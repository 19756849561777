import { motion } from 'framer-motion';
import styled, { css } from 'styled-components';

interface ButtonCopyProps {
  hover: boolean;
}

export const ButtonCopy = styled(motion.button)<ButtonCopyProps>`
  ${({ hover, theme }) => css`
    margin-right: 7px;
    font-family: ${theme.fonts.nexaText};
    display: flex;
    align-items: center;
    justify-content: start;
    cursor: pointer;
    text-transform: uppercase;
    color: ${hover ? 'white' : theme.colors.purple[200]};
    font-weight: 800;
    font-size: 10px;
    padding: 6px 9px;
    border: 1px solid ${hover ? 'white' : theme.colors.purple[200]};
    background-color: ${hover ? theme.colors.purple[700] : 'transparent'};
    border-radius: 8px;
    transition: all 200ms ease-in-out 0s;
    span {
      line-height: 0;
    }
  `}
`;

export const TextCopy = styled(motion.p)`
  margin-left: 5px;
  line-height: 1.3;
`;
