import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { OnboardingInitialState, OnboardingStep } from './onboarding.types';

const initialState = {
  step: OnboardingStep.IDLE,

  acceptTerms: [false, false, false],
} as OnboardingInitialState;

const onboardingSlice = createSlice({
  name: 'onboarding',
  initialState,
  reducers: {
    startAgreements(state) {
      state.step = OnboardingStep.ACCEPT_TERMS;
    },
    toggleAcceptTerm(state, { payload: index }: PayloadAction<number>) {
      state.acceptTerms[index] = !state.acceptTerms[index];
    },
    confirmAgreements(state) {
      state.step = OnboardingStep.CONNECT_WALLET;
    },
    confirmConnect(state) {
      state.step = OnboardingStep.SUPPLY_ASSET;
    },
  },
});

export const { toggleAcceptTerm, startAgreements, confirmAgreements, confirmConnect } = onboardingSlice.actions;

export const onboardingReducer = onboardingSlice.reducer;
