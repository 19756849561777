import contentBR from 'locale/common.br';

export enum LocaleLanguage {
  PT_BR,
  EN_US,
}

export type LocaleContent = typeof contentBR;

export interface LocaleInitialState {
  lang: LocaleLanguage;

  userLocale: string | null;

  content: LocaleContent;
}
