import { setIsLoading, setVaults } from 'modules/vaults/vaults.slice';
import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store';

import response from 'mock/response.json';

const useVaults = () => {
  const { items, isLoading } = useSelector((state: RootState) => state.vaults);

  const { data } = response;

  const dispatch = useDispatch();

  const vaults = useMemo(() => {
    if (!items || items.length === 0) return [];

    return items?.filter((obj: any) => obj.disabled !== true).concat(items.filter((obj: any) => obj.disabled === true));
  }, [items]);

  const totalApr = items?.length && items.reduce(getTotalApr, 0);
  function getTotalApr(total: number, item: any) {
    return total + item.apr;
  }

  const totalApy = items?.length && items.reduce(getTotalApy, 0);
  function getTotalApy(total: number, item: any) {
    return total + item.apy * 100;
  }

  useEffect(() => {
    dispatch(setVaults(data.getMeals));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useEffect(() => {
    dispatch(setIsLoading(true));

    setTimeout(() => {
      dispatch(setIsLoading(false));
    }, 1500);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    isLoading,
    vaults,
    totalApr,
    error: null,
    totalApy,
  };
};

export default useVaults;
