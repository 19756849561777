import { Icon, InputField, MealCard, SnakeButton } from '@munchiesmoney/bubble-gum';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  convertFromOracle,
  convertToBrl,
  formatAsPercentage,
  inputTokenFormat,
  toFixed,
  toFixedMax,
  trim_decimal_overflow,
} from 'utils/deposit';
import {
  erc20ABI,
  useAccount,
  useContractRead,
  useContractWrite,
  usePrepareContractWrite,
  useProvider,
  useWaitForTransaction,
} from 'wagmi';
import { BigNumber, ethers, utils } from 'ethers';
import { motion, AnimatePresence, useAnimationControls } from 'framer-motion';
import vaultABI from 'abi/IVault.json';
import { useSelector } from 'react-redux';
import { RootState, useDispatch } from 'store';
import { setAmount, setStatus, setTransacting, setType, setVaultId } from 'modules/transaction/transaction.slice';
import { TransactionStatus, TransactionType } from 'modules/transaction/transaction.types';
import { useClickOutside } from 'hooks/useClickOutside';
import DepositDisplay from './Display';
import { useVaultBlock } from 'components/Vault';
import oracleABI from 'abi/IPriceOracle.json';
import { BigNumber as BN } from 'bignumber.js';
import { useApprove } from 'hooks/use-transactions';
import { findParentId } from 'utils/dom';

const slideTransitionConfig = {
  stiffness: 256,
  damping: 24,
  mass: 1,
  duration: 0.5,
  type: 'spring',
};

const Deposit = () => {
  const {
    id,
    symbol,
    allowance,
    token,
    balanceMax,
    maxDeposit,
    maxWithdraw,
    vaultBalance,
    contractAddress,
    additionalContractAddresses,
    inputIcon,
    assetAddress,
    minimumDeposit,
    supplyInputRef: inputRef,
    maximumDeposit,
    disabledVault,
    hasNoWithdrawBalance,
    underlyingAssetSymbol,
    supplySelected: selected,
    setSupplySelected: setSelected,
  } = useVaultBlock();

  const { address, isConnected } = useAccount();

  const provider = useProvider();

  const dispatch = useDispatch();

  const { amounts, vaultId, type, status, isTransacting } = useSelector((state: RootState) => state.transaction);
  const { deposit: content } = useSelector((state: RootState) => state.locale.content);

  const [trxHash, setTrxHash] = useState<`0x${string}`>();

  const [ltDisplay, setLtDisplay] = useState(false);
  const [, setGtDisplay] = useState(false);

  const actionsControls = useAnimationControls();

  const supplyValue = useMemo(() => {
    const amount = amounts.find((a) => a.id === vaultId);

    if (!amount) return '0.0000';

    return amount.value;
  }, [amounts, vaultId]);

  const tokenDecimalsData = useContractRead({
    address: assetAddress,
    abi: erc20ABI,
    enabled: isConnected,
    functionName: 'decimals',
  });

  const isLessThanMin = useMemo(() => {
    const minValue = BigNumber.from(minimumDeposit);
    const userValue = utils.parseUnits(
      trim_decimal_overflow(supplyValue, tokenDecimalsData.data as number),
      tokenDecimalsData?.data,
    );

    return userValue.lt(minValue);
  }, [minimumDeposit, tokenDecimalsData]);

  const isGreaterThanMax = useMemo(() => {
    const minValue = BigNumber.from(maximumDeposit);
    const userValue = utils.parseUnits(
      trim_decimal_overflow(supplyValue, tokenDecimalsData.data as number),
      tokenDecimalsData?.data,
    );

    return userValue.gt(minValue);
  }, [maximumDeposit, tokenDecimalsData]);

  const maxDepositComputed = useMemo(() => {
    if (!maxDeposit.data || !balanceMax.data) return '0.0000';

    const maxDepositValue = maxDeposit.data as BigNumber;
    const assetBalance = token.data?.value as BigNumber;

    const amount = maxDepositValue.lt(assetBalance) ? maxDepositValue : assetBalance;

    return utils.formatUnits(amount, tokenDecimalsData.data);
  }, [balanceMax, token, maxDeposit, tokenDecimalsData]);

  const {
    instance: approveInstance,
    request: requestApprove,
    isApproved,
  } = useApprove({
    value: supplyValue,
  });

  const [gasPrice, setGasPrice] = useState<any>(null);

  const [showReceipt, setShowReceipt] = useState<boolean>(false);

  const ref = useClickOutside(
    (e) => {
      if (!selected) {
        return;
      }

      if (
        (!isTransacting && type === TransactionType.DEPOSIT && vaultId === id) ||
        status === TransactionStatus.COMPLETE
      ) {
        dispatch(setType(null));

        if (!findParentId(e.target, 'vaults-section')) {
          setTimeout(() => {
            dispatch(setVaultId(null));
          }, 300);
        }

        dispatch(setStatus(TransactionStatus.IDLE));
        dispatch(
          setAmount({
            id: vaultId,
            value: '0.0000',
          }),
        );
        dispatch(setTransacting(false));
        setSelected(false);
      }
    },
    ['click'],
  );

  const [blockReason, setBlockReason] = useState<string | null>(null);

  const steps = useMemo(() => {
    if (status === TransactionStatus.ERROR) {
      return [
        {
          id: TransactionStatus.ERROR,
          title: content.steps.header.transactionCanceled,
          icon: 'close',
        },
      ];
    }

    return [
      {
        id: TransactionStatus.IDLE,
        title: `${content.steps.header.depositTitle} ${symbol}`,
        icon: 'arrow-down',
      },
      {
        id: TransactionStatus.WAIT_APPROVAL_ACCEPT,
        title: content.steps.header.authorize,
        icon: 'key',
      },
      {
        id: TransactionStatus.WAIT_APPROVAL,
        title: content.steps.header.authorizing,
        icon: 'spin',
      },
      {
        id: TransactionStatus.REVIEW_TRANSACTION,
        title: content.steps.header.reviewTransaction,
        icon: 'eye',
      },
      {
        id: TransactionStatus.WAIT_ACCEPT,
        title: content.steps.header.sign,
        icon: 'sign',
      },
      {
        id: TransactionStatus.WAIT_TRANSACTION,
        title: content.steps.header.transacting,
        icon: 'spin',
      },
      {
        id: TransactionStatus.COMPLETE,
        title: content.steps.header.transactionperformed,
        icon: 'check',
      },
    ];
  }, [status, symbol]);

  const actionLabel = useMemo(() => {
    if (ltDisplay) {
      return content.steps.back;
    }

    if (status === TransactionStatus.IDLE) {
      return content.steps.max;
    }
    if (status === TransactionStatus.WAIT_ACCEPT || status === TransactionStatus.REVIEW_TRANSACTION) {
      return content.steps.back;
    }
    if (status === TransactionStatus.WAIT_APPROVAL_ACCEPT) {
      return content.steps.changeValue;
    }
    return content.steps.close;
  }, [status, ltDisplay]);

  // const hasAmount = useMemo(() => {
  //   if ((vaultId === id && supplyValue !== '0.0000') || supplyValue !== '0') {
  //     const walletAmount = token?.data?.value !== undefined ? token?.data?.value : BigNumber.from(0);
  //     return utils.parseUnits(supplyValue, tokenDecimalsData?.data).lte(walletAmount || BigNumber.from(0));
  //   }

  //   return true;
  // }, [type, vaultId, token, supplyValue, id]);

  const noBalance = useMemo(() => {
    const walletAmount = token?.data?.formatted ? BN(token?.data?.formatted as string) : '0.0000';

    return BN(walletAmount || '0.0').isZero();
  }, [token]);

  //ANTIGO
  const hasAmount = useMemo(() => {
    const walletAmount = token?.data?.formatted ? BN(token?.data?.formatted as string) : '0.0000';

    if ((vaultId === id && supplyValue !== '0.0000') || supplyValue !== '0') {
      return BN(supplyValue || '0.0').isLessThanOrEqualTo(walletAmount);
    }

    return true;
  }, [type, vaultId, token, supplyValue, id]);

  const showConfirmButton =
    type === TransactionType.DEPOSIT &&
    !ltDisplay &&
    ((status === TransactionStatus.IDLE && supplyValue !== '0.0000' && hasAmount) ||
      (status === TransactionStatus.REVIEW_TRANSACTION && supplyValue !== '0.0000' && hasAmount));

  const vaultBalanceConvertedData = useContractRead({
    address: additionalContractAddresses?.priceFeed,
    abi: oracleABI,
    functionName: 'convertToDerivedFiat',
    args: [token.data?.value, assetAddress],
    enabled: isConnected,
    watch: false,
  });

  const inputConvertedData = useContractRead({
    address: additionalContractAddresses?.priceFeed,
    abi: oracleABI,
    functionName: 'convertToDerivedFiat',
    args: [
      utils.parseUnits(trim_decimal_overflow(supplyValue, tokenDecimalsData.data as number), tokenDecimalsData?.data),
      assetAddress,
    ],
    enabled: isConnected && supplyValue !== '0.0000' && id === vaultId,
    watch: false,
  });

  const parsedDepositAmount = useMemo(() => {
    let parsedAmount = supplyValue;

    if (id !== vaultId && type !== TransactionType.DEPOSIT) {
      parsedAmount = '0';
    }

    if (supplyValue === '') {
      parsedAmount = '0';
    }

    return utils.parseUnits(
      trim_decimal_overflow(parsedAmount, tokenDecimalsData.data as number),
      tokenDecimalsData?.data,
    );
  }, [supplyValue, vaultId, id, type, tokenDecimalsData?.data]);

  useEffect(() => {
    if (status === TransactionStatus.REVIEW_TRANSACTION) {
      (async () => {
        try {
          const gasPrice = await provider?.getGasPrice();
          const ABI = ['function deposit(uint256,address) returns (uint256)'];
          const iFace = new ethers.utils.Interface(ABI);
          const sigHash = iFace.encodeFunctionData('deposit', [parsedDepositAmount, address]);
          const estimateGas = await provider?.estimateGas({ to: contractAddress, data: sigHash, from: address });
          const value = gasPrice.mul(estimateGas);
          setGasPrice(value);
        } catch (e) {
          // console.log('error', e);
        }
      })();
    }
  }, [status, supplyValue, provider, parsedDepositAmount, contractAddress]);

  const fee = useContractRead({
    address: contractAddress,
    abi: vaultABI,
    functionName: 'estimateDepositAfterFees',
    args: [
      utils.parseUnits(trim_decimal_overflow(supplyValue, tokenDecimalsData.data as number), tokenDecimalsData?.data),
    ],
    enabled:
      isConnected && supplyValue !== '0.0000' && id === vaultId && status === TransactionStatus.REVIEW_TRANSACTION,
    watch: false,
  });

  const reviewScreenValue = useMemo(() => {
    if (fee?.data !== undefined) {
      const supply = utils.parseUnits(supplyValue, tokenDecimalsData?.data);
      const total = supply?.sub(fee?.data as BigNumber);
      return utils.formatUnits(total, tokenDecimalsData?.data);
    }
    return '0';
  }, [supplyValue, fee]);

  const reviewScreenConvertedData = useContractRead({
    address: additionalContractAddresses?.priceFeed,
    abi: oracleABI,
    functionName: 'convertToDerivedFiat',
    args: [utils.parseUnits(reviewScreenValue, tokenDecimalsData?.data), assetAddress],
    enabled:
      isConnected &&
      reviewScreenValue !== undefined &&
      fee?.data !== undefined &&
      id === vaultId &&
      status === TransactionStatus.REVIEW_TRANSACTION,
    watch: false,
  });

  const reviewScreenGasConvertedData = useContractRead({
    address: additionalContractAddresses?.priceFeed,
    abi: oracleABI,
    functionName: 'convertToDerivedFiat',
    args: [gasPrice && utils.parseEther(utils.formatEther(gasPrice)), process.env.WETH_ADDRESS],
    enabled: isConnected && gasPrice && id === vaultId && status === TransactionStatus.REVIEW_TRANSACTION,
    watch: false,
  });

  const prepare = usePrepareContractWrite({
    address: contractAddress,
    abi: vaultABI,
    functionName: 'deposit',
    enabled: isConnected && supplyValue !== '0.0000' && id === vaultId && type === TransactionType.DEPOSIT,
    args: [parsedDepositAmount, address as never],
    onError(err: any) {
      if (err.message.includes('deposit less than min')) {
        // dispatch(setStatus(TransactionStatus.ERROR));
        // console.log('Depósito não atingiu o mínimo');
      }

      if (err.message.includes('insufficient funds for gas')) {
        // dispatch(setStatus(TransactionStatus.ERROR));
        // console.log('Você precisa reservar um pouco de ETH para pagar o gas');
      }
    },
    onSuccess() {
      setBlockReason(null);
    },
  });

  const {
    data: dataDeposit,
    reset: resetDeposit,
    isError: isErrorDeposit,
    ...transactionDeposit
  } = useContractWrite(prepare.config as never);

  const {
    isLoading: isLoadingDeposit,
    isSuccess: isSuccessDeposit,
    isError: isWaitErrorDeposit,
  } = useWaitForTransaction({
    hash: dataDeposit?.hash,
    enabled: isConnected && id === vaultId && type === TransactionType.DEPOSIT,
  });

  const isContracted = useMemo(
    () =>
      !type && hasNoWithdrawBalance
        ? false
        : !type || type !== TransactionType.DEPOSIT
        ? true
        : !vaultId
        ? true
        : vaultId === id && !type,
    [type, hasNoWithdrawBalance, vaultId, id],
  );

  useEffect(() => {
    if (isErrorDeposit) {
      dispatch(setStatus(TransactionStatus.ERROR));
      resetDeposit();
    }
  }, [isErrorDeposit, dispatch]);

  useEffect(() => {
    if (isLoadingDeposit) {
      dispatch(setStatus(TransactionStatus.WAIT_TRANSACTION));
    }
  }, [isLoadingDeposit, dispatch]);

  useEffect(() => {
    if (!isLoadingDeposit && isSuccessDeposit) {
      dispatch(setStatus(TransactionStatus.COMPLETE));

      vaultBalance.refetch();
      vaultBalanceConvertedData.refetch();
      balanceMax.refetch();
      maxWithdraw.refetch();
      token.refetch();

      setTrxHash(dataDeposit?.hash);

      setTimeout(() => {
        setShowReceipt(true);
      }, 1500);
    }
  }, [isLoadingDeposit, isSuccessDeposit, dispatch]);

  useEffect(() => {
    if (!isLoadingDeposit && isWaitErrorDeposit) {
      dispatch(setStatus(TransactionStatus.ERROR));
      resetDeposit();
    }
  }, [isLoadingDeposit, isWaitErrorDeposit, dispatch]);

  useEffect(() => {
    approveInstance.addListener('user_approved', () => {
      prepare?.refetch();
    });

    return () => {
      approveInstance.removeAllListeners('user_approved');
    };
  }, [approveInstance, resetDeposit]);

  useEffect(() => {
    if (status !== TransactionStatus.COMPLETE) {
      setShowReceipt(false);
    }
  }, [vaultId, id, status]);

  const handleSubmit = useCallback(() => {
    if (blockReason) {
      alert(blockReason);

      return;
    }

    if (isLessThanMin) {
      setLtDisplay(true);
      dispatch(setTransacting(true));
      return;
    }

    if (isGreaterThanMax) {
      setGtDisplay(true);

      dispatch(setTransacting(true));
      return;
    }

    allowance.refetch();
    dispatch(setTransacting(true));

    if (status === TransactionStatus.REVIEW_TRANSACTION) {
      if (isApproved) {
        dispatch(setStatus(TransactionStatus.WAIT_ACCEPT));
        transactionDeposit.write?.();
      } else {
        requestApprove();
      }
      return;
    }

    if (isApproved) {
      dispatch(setStatus(TransactionStatus.REVIEW_TRANSACTION));
    } else {
      requestApprove();
    }
  }, [dispatch, blockReason, requestApprove, isApproved, status, transactionDeposit]);

  const handleSelectTab = useCallback(() => {
    if (noBalance) return;

    setSelected(true);

    setTimeout(() => {
      dispatch(setType(TransactionType.DEPOSIT));
    }, 50);

    setTimeout(() => {
      inputRef.current?.focus();
    }, 200);
  }, [inputRef, noBalance, dispatch]);

  const handleChange = useCallback(
    (value: string) => {
      if (!type) {
        handleSelectTab();
      }

      if (value === '' || value === ' ' || value === '0.0') {
        return dispatch(
          setAmount({
            id: vaultId,
            value: '0.0000',
          }),
        );
      }

      dispatch(
        setAmount({
          id: vaultId,
          value: inputTokenFormat(parseFloat(value.replace(/\D/g || 0.0, ''))),
        }),
      );
    },
    [dispatch, vaultId, type],
  );

  const handleMax = useCallback(() => {
    if (!type) {
      handleSelectTab();
    }

    if (ltDisplay) {
      inputRef?.current?.focus();
      setLtDisplay(false);
      dispatch(setTransacting(false));
      return;
    }

    const parse = token?.data?.formatted || '0.0';
    const manipulatedValue = toFixedMax(parse, tokenDecimalsData.data as number);

    if (
      manipulatedValue.length === 0 ||
      manipulatedValue === ' ' ||
      manipulatedValue === '0' ||
      manipulatedValue === '0.0'
    ) {
      dispatch(
        setAmount({
          id: vaultId,
          value: '0.0000',
        }),
      );
      return;
    }

    dispatch(
      setAmount({
        id: vaultId,
        value: trim_decimal_overflow(maxDepositComputed, tokenDecimalsData.data as number),
      }),
    );
  }, [dispatch, ltDisplay, inputRef, handleSelectTab, token, tokenDecimalsData, maxDepositComputed, vaultId, type]);

  const handleAction = useCallback(() => {
    if (status === TransactionStatus.IDLE) {
      isConnected && handleMax();
      return;
    }
    if (status === TransactionStatus.REVIEW_TRANSACTION) {
      setTimeout(() => {
        inputRef.current?.focus();
        dispatch(setStatus(TransactionStatus.IDLE));
        dispatch(setTransacting(false));
      }, 200);
      return;
    }

    if (status === TransactionStatus.WAIT_ACCEPT || status === TransactionStatus.WAIT_APPROVAL_ACCEPT) {
      dispatch(setTransacting(false));
      dispatch(setStatus(TransactionStatus.IDLE));

      setTimeout(() => {
        inputRef.current?.focus();
      }, 200);

      return;
    }

    if (status === TransactionStatus.COMPLETE) {
      dispatch(setType(null));
      dispatch(setStatus(TransactionStatus.IDLE));
      dispatch(
        setAmount({
          id: vaultId,
          value: '0.0000',
        }),
      );
      dispatch(setTransacting(false));
      dispatch(setVaultId(null));
      allowance.refetch();

      return;
    }

    if (status === TransactionStatus.ERROR) {
      dispatch(setStatus(TransactionStatus.IDLE));
      dispatch(
        setAmount({
          id: vaultId,
          value: '0.0000',
        }),
      );
      dispatch(setTransacting(false));
      return;
    }
  }, [status, dispatch, inputRef, vaultId, allowance, resetDeposit, isConnected]);

  const percentSupplyString = formatAsPercentage(supplyValue, token.data?.formatted as string).toString();
  const percentSupplyFormatted = percentSupplyString.substring(0, percentSupplyString.length - 1).replace('.', '');
  const negativeSupply = 100 - parseFloat(percentSupplyFormatted);

  const differencePercent = useMemo(() => {
    if (parseInt(percentSupplyFormatted) > 100) {
      return 100;
    } else if (parseInt(percentSupplyFormatted) < 0) {
      return 0;
    } else {
      return parseInt(percentSupplyFormatted);
    }
  }, [percentSupplyFormatted]);

  const totalPercent = useMemo(() => {
    if (negativeSupply > 100) {
      return 100;
    } else if (negativeSupply < 0) {
      return 0;
    } else {
      return negativeSupply;
    }
  }, [negativeSupply]);

  const feeValue = useMemo(() => {
    if (fee?.data !== undefined) {
      return utils.formatUnits(fee?.data as BigNumber, tokenDecimalsData?.data);
    }
    return '0.0000';
  }, [fee?.data]);

  function addZeroes(num: string) {
    const len = 4;
    return Number(num).toFixed(len);
  }

  useEffect(() => {
    actionsControls.start({
      height: showReceipt ? 216 : 138,
      transition: slideTransitionConfig,
    });
  }, [actionsControls, showReceipt]);

  return (
    <MealCard.Flow
      noVaultBalance={!isTransacting && noBalance}
      hasNoWithdrawValue={isConnected && hasNoWithdrawBalance}
      ref={ref}
      type="supply"
      convertedBalance={convertToBrl(convertFromOracle((vaultBalanceConvertedData.data as never) || 0.0))}
      tokenBalance={(maxDepositComputed as never) === '0.0' ? '0.0000' : toFixed(maxDepositComputed as never, 4)}
      differencePercent={vaultId === id ? differencePercent : 0}
      totalPercent={vaultId === id ? totalPercent : 100}
      isContracted={vaultId === id ? (hasNoWithdrawBalance && !type ? false : type !== TransactionType.DEPOSIT) : true}
      isClosed={vaultId === id && !!type && !!(type === TransactionType.WITHDRAW)}
      isPercentHighlighted={vaultId === id ? differencePercent > 0 : false}
      isPercentShowForced={hasNoWithdrawBalance}
      isExpanded={showReceipt}
      isAmountHighlighted={maxDepositComputed !== '0.0'}
      isTransacting={vaultId === id && !!(type === TransactionType.DEPOSIT)}
      isError={
        type === TransactionType.DEPOSIT &&
        vaultId === id &&
        (ltDisplay ||
          (!hasAmount
            ? true
            : vaultId === id && status === TransactionStatus.ERROR && type === TransactionType.DEPOSIT))
      }
      isSuccess={vaultId === id && status === TransactionStatus.COMPLETE && !!(type === TransactionType.DEPOSIT)}
    >
      <MealCard.InputHeader
        steps={steps as never}
        selected={status}
        shortedIcon={maxDepositComputed === '0.0' ? <div /> : <Icon name="arrow-down" />}
        shortedLabel={
          maxDepositComputed === '0.0'
            ? hasNoWithdrawBalance
              ? content.steps.header.noFee
              : content.steps.header.noFeeShorted
            : `${content.steps.header.depositTitle} ${symbol}`
        }
        isPulsing={!disabledVault}
        onClick={() => {
          if (!type) {
            dispatch(setVaultId(id));
            if (hasNoWithdrawBalance) {
              handleSelectTab();
            } else {
              setTimeout(() => {
                handleSelectTab();
              }, 500);
            }
          }
        }}
      />
      <motion.div animate={actionsControls} style={{ height: 138, overflow: 'hidden', paddingBottom: 6 }}>
        <AnimatePresence>
          {vaultId === id && (
            <motion.div
              initial={{
                opacity: 0,
                marginTop: -100,
              }}
              animate={{
                opacity: 1,
                marginTop: 0,
                transition: slideTransitionConfig,
              }}
              exit={{
                opacity: 0,
                marginTop: -138,
                transition: slideTransitionConfig,
              }}
            >
              <DepositDisplay
                minimumDeposit={
                  type === TransactionType.DEPOSIT && vaultId === id && minimumDeposit
                    ? addZeroes(utils.formatUnits(BigNumber.from(minimumDeposit), tokenDecimalsData?.data))
                    : '0.0000'
                }
                showReceipt={showReceipt}
                noBalance={type === TransactionType.DEPOSIT && vaultId === id && !hasAmount}
                zeroBalance={noBalance}
                symbol={underlyingAssetSymbol}
                value={supplyValue}
                ltMin={type === TransactionType.DEPOSIT && vaultId === id && ltDisplay}
                fee={feeValue}
                transactionHash={trxHash}
                gas={
                  reviewScreenGasConvertedData?.data
                    ? convertToBrl(convertFromOracle(reviewScreenGasConvertedData?.data as never))
                    : 'R$ 0,00'
                }
                enterInWallet={reviewScreenValue || '0.0000'}
                realValue={
                  reviewScreenConvertedData?.data
                    ? convertToBrl(convertFromOracle(reviewScreenConvertedData?.data as never))
                    : 'R$ 0,00'
                }
              />
              <InputField
                isContracted={isContracted}
                withOverlay={isContracted}
                onOverlayClick={handleSelectTab}
                isValid={
                  vaultId === id && status === TransactionStatus.COMPLETE && !!(type === TransactionType.DEPOSIT)
                }
                isInvalid={
                  type === TransactionType.DEPOSIT &&
                  vaultId === id &&
                  (ltDisplay ||
                    (!hasAmount
                      ? true
                      : vaultId === id && status === TransactionStatus.ERROR && type === TransactionType.DEPOSIT))
                }
                isDisabled={!isTransacting && noBalance}
                isReadOnly={
                  hasNoWithdrawBalance
                    ? false
                    : type !== TransactionType.DEPOSIT ||
                      (type === TransactionType.DEPOSIT &&
                        status !== TransactionStatus.IDLE &&
                        status !== TransactionStatus.REVIEW_TRANSACTION)
                }
              >
                {status !== TransactionStatus.REVIEW_TRANSACTION && (
                  <>
                    <InputField.Icon url={inputIcon} />
                    {noBalance && hasNoWithdrawBalance && (
                      <InputField.BlockMessage>{content.steps.header.noCoin(symbol)}</InputField.BlockMessage>
                    )}
                    {(noBalance ? (isContracted ? true : false) : true) && (
                      <InputField.Control>
                        <InputField.Input
                          // @ts-ignore
                          ref={inputRef}
                          symbol={symbol}
                          value={type === TransactionType.DEPOSIT && vaultId === id ? supplyValue : '0.0000'}
                          onChange={handleChange}
                        />
                        <InputField.Conversion
                          amount={
                            type === TransactionType.DEPOSIT
                              ? parseFloat(convertFromOracle(inputConvertedData.data as never))
                              : 0.0
                          }
                          onClick={() => {
                            if (!type) {
                              dispatch(setType(TransactionType.DEPOSIT));

                              if (hasNoWithdrawBalance) {
                                inputRef.current?.focus();
                              } else {
                                setTimeout(() => {
                                  inputRef.current?.focus();
                                }, 300);
                              }
                            } else {
                              inputRef.current?.focus();
                            }
                          }}
                        />
                      </InputField.Control>
                    )}
                  </>
                )}
                {!noBalance && status === TransactionStatus.REVIEW_TRANSACTION && (
                  <InputField.ActionButton onClick={handleAction}>{actionLabel}</InputField.ActionButton>
                )}
                {!noBalance && (
                  <InputField.RightContent mr={!showConfirmButton}>
                    {!(
                      status === TransactionStatus.WAIT_APPROVAL ||
                      status === TransactionStatus.WAIT_TRANSACTION ||
                      status === TransactionStatus.REVIEW_TRANSACTION
                    ) && <InputField.ActionButton onClick={handleAction}>{actionLabel}</InputField.ActionButton>}
                    {showConfirmButton && (
                      <SnakeButton
                        w={status === TransactionStatus.REVIEW_TRANSACTION ? '148px' : '88px'}
                        onClick={handleSubmit}
                      >
                        {status === TransactionStatus.REVIEW_TRANSACTION
                          ? content.steps.header.depositTitleReview
                          : content.steps.header.depositTitle}
                      </SnakeButton>
                    )}
                  </InputField.RightContent>
                )}
              </InputField>
            </motion.div>
          )}
        </AnimatePresence>
      </motion.div>
      {type !== TransactionType.DEPOSIT && (
        <div
          onClick={handleSelectTab}
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            borderRadius: 8,
            zIndex: 1,
            cursor: 'pointer',
          }}
        />
      )}
    </MealCard.Flow>
  );
};

export default Deposit;
