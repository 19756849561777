import styled, { css } from 'styled-components';

type WrapperProps = {
  columns: number;
};

export const Wrapper = styled.div<WrapperProps>`
  ${({ columns = 2 }) => css`
    display: grid;

    grid-template-columns: repeat(${columns}, 1fr);
    grid-gap: 26px;
  `}
`;

export const Column = styled.div`
  flex: 1;
`;

export const Label = styled.h5`
  ${({ theme }) => css`
    font-family: ${theme.fonts.nexaText};
    font-size: ${theme.sizes.text.s};
    font-weight: 700;

    line-height: ${theme.sizes.lineHeight.l1};

    color: ${theme.colors.purple[100]};
  `}
`;

export const Value = styled.p`
  ${({ theme }) => css`
    display: flex;
    justify-content: start;
    align-items: center;
    font-family: ${theme.fonts.nexa};
    font-size: ${theme.sizes.display.xl};
    font-weight: 900;
    line-height: ${theme.sizes.lineHeight.l3};
    color: ${theme.colors.purple[100]};
    margin: 4px 0 0 0;
    > span {
      line-height: 0px;
      margin-right: 4px;
    }
  `}
`;

export const ApyValue = styled.p`
  ${({ theme }) => css`
    display: flex;

    align-items: flex-end;

    font-family: ${theme.fonts.nexaText};
    font-size: ${theme.sizes.text.l};
    font-weight: 700;

    line-height: ${theme.sizes.lineHeight.l1};

    color: ${theme.colors.purple[100]};

    margin: 7px 0 0 0;

    > span {
      display: block;

      font-family: ${theme.fonts.nexaText};
      font-size: ${theme.sizes.text.s};
      font-weight: 400;

      margin: 0 0 -1px 6px;
    }
  `}
`;
