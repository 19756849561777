import { combineReducers } from '@reduxjs/toolkit';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist-indexeddb-storage';

import { onboardingReducer } from 'modules/onboarding/onboarding.slice';
import { navigationReducer } from 'modules/navigation/navigation.slice';
import { localeReducer } from 'modules/locale/locale.slice';
import { vaultsReducer } from 'modules/vaults/vaults.slice';
import { transactionReducer } from 'modules/transaction/transaction.slice';
import { focusReducer } from 'modules/focus/focus.slice';
import { walletReducer } from 'modules/wallet/wallet.slice';

import { api } from './api';

const dbName = 'munchies';

const persistConfig = (key: string, others = {}) => ({
  key,
  storage: storage(dbName),
  ...others,
});

export const reducer = combineReducers({
  [api.reducerPath]: api.reducer,
  onboarding: persistReducer(persistConfig('onboard'), onboardingReducer),
  navigation: persistReducer(persistConfig('navigation'), navigationReducer),
  locale: persistReducer(persistConfig('locale', { blacklist: ['content'] }), localeReducer),
  vaults: persistReducer(persistConfig('vaults'), vaultsReducer),
  wallet: persistReducer(persistConfig('wallet'), walletReducer),
  transaction: transactionReducer,
  focus: focusReducer,
});
