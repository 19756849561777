export const slideVariants = {
  open: {
    opacity: 1,
    width: '100%',
    transition: { duration: 0.3 },
  },
  closed: {
    opacity: 0,
    width: 0,
    transition: { duration: 0.3 },
  },
};

export const opacityVariants = {
  open: {
    opacity: 1,
    transition: {
      duration: 0.4,
    },
    display: 'block',
  },
  closed: {
    opacity: 0,
    transition: {
      duration: 0.4,
    },
    transitionEnd: {
      display: 'none',
    },
  },
};

export const principalLoaderVariant = {
  open: {
    opacity: 1,
    transition: {
      duration: 0.5,
    },
    transitionEnd: {
      display: 'flex',
    },
  },
  closed: {
    opacity: 0,
    transition: {
      duration: 0.5,
    },
    transitionEnd: {
      display: 'none',
    },
  },
};

export const topVariants = {
  open: {
    opacity: 1,
    height: '320px',
    width: '100%',
    marginBottom: '50px',
    transition: {
      //delayChildren: 0.8,
      delay: 0.3,
      duration: 0.3,
    },
  },
  closed: {
    opacity: 0,
    width: '100%',
    height: 0,
    marginBottom: '0px',
    transition: {
      delay: 0.3,
      duration: 0.3,
      //delayChildren: 0.8,
    },
  },
};
