import { BigNumber, Contract } from 'ethers';
import { formatEther, formatUnits } from 'ethers/lib/utils';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { convertFromOracle, convertToBrl, toFixed } from 'utils/deposit';
import { erc20ABI, useContractRead, useProvider } from 'wagmi';
import oracleABI from 'abi/IPriceOracle.json';
import vaultABI from 'abi/IVault.json';

type UseTransactionGasProps = {
  hash?: `0x${string}`;
  tokenAddress: `0x${string}`;
  contractAddress: `0x${string}`;
  address: `0x${string}`;
  enabled?: boolean;
};

export const useTransactionGas = ({
  hash,
  tokenAddress,
  contractAddress,
  address,
  enabled = false,
}: UseTransactionGasProps) => {
  const provider = useProvider();

  const [gasUsed, setGasUsed] = useState<BigNumber>();
  const [shares, setShares] = useState<BigNumber>();

  const tokenDecimals = useContractRead({
    address: tokenAddress,
    abi: erc20ABI,
    functionName: 'decimals',
  });

  const gasConversion = useContractRead({
    address,
    abi: oracleABI,
    functionName: 'convertToDerivedFiat',
    args: [gasUsed, process.env.WETH_ADDRESS],
    enabled: !!gasUsed && enabled,
  });

  const sharesData = useContractRead({
    address: contractAddress,
    abi: vaultABI,
    functionName: 'convertToAssets',
    args: [shares],
    enabled: !!shares && enabled,
  });

  const sharesConversion = useContractRead({
    address,
    abi: oracleABI,
    functionName: 'convertToDerivedFiat',
    args: [sharesData.data, tokenAddress],
    enabled: !!sharesData.data && enabled,
  });

  const gasFormatted = useMemo(() => {
    if (!gasUsed) return '0.000000';

    return toFixed(formatEther(gasUsed) as unknown as number, 6);
  }, [gasUsed]);

  const sharesFormatted = useMemo(() => {
    if (!enabled || !sharesData.data) return '0.0000';

    return toFixed(formatUnits(sharesData.data as BigNumber, tokenDecimals.data) as unknown as number, 4);
  }, [sharesData, enabled, tokenDecimals]);

  const getReceipt = useCallback(
    async (transactionHash: `0x${string}`) => {
      if (!transactionHash) return;

      const transaction = await provider.getTransaction(transactionHash);

      if (!transaction.gasPrice || !transaction.to) return;

      const receipt = await provider.getTransactionReceipt(transactionHash);
      const totalCost = transaction.gasPrice.mul(receipt.gasUsed);

      const contract = new Contract(
        transaction.to,
        ['event Transfer(address indexed from, address indexed to, uint256 value)'],
        provider,
      );

      const events = await contract.queryFilter('Transfer', receipt.blockNumber);

      const [transfer] = events.map((event) => ({
        from: (event.args as any).from,
        to: (event.args as any).to,
        amount: (event.args as any).value,
      }));

      setShares(transfer.amount);
      setGasUsed(totalCost);
    },
    [provider],
  );

  useEffect(() => {
    if (hash && enabled) {
      getReceipt(hash);
    }
  }, [getReceipt, enabled, hash]);

  return {
    cost: {
      amount: gasUsed,
      formatted: gasFormatted,
      converted: convertToBrl(convertFromOracle((gasConversion.data as never) || 0.0)),
    },
    deposited: {
      amount: sharesData.data,
      formatted: sharesFormatted,
      converted: convertToBrl(convertFromOracle((sharesConversion.data as never) || 0.0)),
    },
  };
};
