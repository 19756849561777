import { createSlice } from '@reduxjs/toolkit';

import { WalletInitialState } from './wallet.types';
import { BigNumber } from 'ethers';

const initialState = {
  isConnected: false,
  hasDeposits: false,
  isConfirm: false,

  pendingTransactions: [],

  total: {
    amount: BigNumber.from(0),
    formatted: '0,00',
  },

  vaults: [],
  tokens: {},
} as WalletInitialState;

const slice = createSlice({
  name: 'wallet',
  initialState,
  reducers: {
    setIsConnect(state, { payload }) {
      state.isConnected = payload;
    },
    seHasDeposits(state, { payload }) {
      state.hasDeposits = payload;
    },
    confirmConnection(state, { payload }) {
      state.isConfirm = payload;
    },
    setVaults(state, action) {
      const { vaultId } = action.payload;
      const doesItemExist = state.vaults.find((item) => item.vaultId === vaultId);
      if (!doesItemExist) state.vaults = [...state.vaults, action.payload];
    },
    setTokens(state, { payload }) {
      state.tokens = { ...state.tokens, [payload.symbol]: { amount: payload.amount, formatted: payload.formatted } };
    },
  },
});

export const { setIsConnect, seHasDeposits, setVaults, setTokens, confirmConnection } = slice.actions;

export const walletReducer = slice.reducer;
