import React, { useCallback, useMemo } from 'react';

import { DefaultButton, Header, Icon, OutlineButton, SectionWrapper, theme } from '@munchiesmoney/bubble-gum';

import * as S from './Terms.styles';
import InitialTerms from 'components/InitialTerms';
import { useSelector } from 'react-redux';
import { RootState, useDispatch } from 'store';
import { setLanguage } from 'modules/locale/locale.slice';
import { LocaleLanguage } from 'modules/locale/locale.types';
import { useAccount } from 'wagmi';
import { closeAllSectionsAndConfig, setSectionState } from 'modules/navigation/navigation.slice';

const Terms = () => {
  const { terms } = useSelector((state: RootState) => state.navigation);
  const { vaultId } = useSelector((state: RootState) => state.transaction);

  const isAgreementStep = useMemo(() => {
    return !terms.closed;
  }, [terms]);

  const sectionHeight = useMemo(() => (isAgreementStep ? 492 : 72), [isAgreementStep]);

  return (
    <SectionWrapper
      height={sectionHeight}
      headerComponent={
        <Header focusAnimation={terms.pulse} bgColor={['#56459A', theme.colors.purple[600]]}>
          {isAgreementStep ? <AgreementContent /> : <NavigationContent />}
        </Header>
      }
      withBlinkEffect={isAgreementStep}
      isDisabled={!!vaultId}
      withBody
    >
      <InitialTerms />
    </SectionWrapper>
  );
};

const AgreementContent = () => {
  const dispatch = useDispatch();

  const { lang, content } = useSelector((state: RootState) => state.locale);

  const handleChangeLanguage = useCallback(
    (lang: LocaleLanguage) => () => {
      dispatch(setLanguage(lang));
    },
    [dispatch],
  );

  return (
    <S.NoticeAgreement>
      <span>{content.navbar.termsTitle}</span>
      <S.LangSwitch>
        <S.SwitchButton aria-disabled={lang === LocaleLanguage.PT_BR}>
          <DefaultButton
            bgColor={theme.colors.purple[400]}
            shadowColor={theme.colors.purple[600]}
            padding="7px 11px"
            onClick={handleChangeLanguage(LocaleLanguage.PT_BR)}
          >
            {content.navbar.ptButtonText}
          </DefaultButton>
        </S.SwitchButton>
        <S.SwitchButton aria-disabled={lang === LocaleLanguage.EN_US}>
          <DefaultButton
            bgColor={theme.colors.purple[400]}
            shadowColor={theme.colors.purple[600]}
            padding="7px 11px"
            onClick={handleChangeLanguage(LocaleLanguage.EN_US)}
          >
            {content.navbar.enButtonText}
          </DefaultButton>
        </S.SwitchButton>
      </S.LangSwitch>
    </S.NoticeAgreement>
  );
};

const NavigationContent = () => {
  const {
    content: { navbar, name, version },
  } = useSelector((state: RootState) => state.locale);

  const { isConnected } = useAccount();

  const dispatch = useDispatch();

  const handleClick = useCallback(
    (i: number) => () => {
      if (i === 0) {
        dispatch(
          closeAllSectionsAndConfig({
            section: 'terms',
            config: {
              closed: false,
              pulse: true,
            },
          }),
        );

        dispatch(
          setSectionState({
            section: 'meals',
            config: {
              collapsed: true,
            },
          }),
        );

        return;
      }

      window.open('https://discord.com/invite/UpwJEPWVzM', '_blank');
    },
    [dispatch],
  );

  return (
    <S.Content>
      <S.HeaderWrapper>
        <S.HeaderBody>
          <p>{name}</p>
          <span>{version}</span>
        </S.HeaderBody>
        {navbar.links.map((link: { id: string; label: string }, i) => (
          <OutlineButton key={link.id} disabled={i === 0 && isConnected} onClick={handleClick(i)}>
            {link.label}
          </OutlineButton>
        ))}
      </S.HeaderWrapper>
      <S.Custom>
        <S.CustomButton
          initial={{ backgroundColor: '#5a49a9' }}
          whileHover={{ backgroundColor: '#8a6dff' }}
          transition={{ duration: 0.5 }}
          onClick={() => {
            window.open('https://docs.munchies.money', '_blank');
          }}
          padding="7px 12px"
        >
          <Icon name="interrogation" color="white" />
        </S.CustomButton>
      </S.Custom>
    </S.Content>
  );
};

export default Terms;
