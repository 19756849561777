import React from 'react';
import { NotFoundWrapper } from './NotFound.styles';
import { CustomButton } from '@munchiesmoney/bubble-gum';
import { useNavigate } from 'react-router-dom';

const NotFound: React.FC = () => {
  const navigate = useNavigate();
  return (
    <NotFoundWrapper>
      <p>
        <b>404</b>
        <br />
        Página não encontrada.
        <br />
        <br />
      </p>
      <CustomButton
        snakeSize="small"
        colorScheme={'lightPurple'}
        onClick={() => {
          navigate('/');
        }}
      >
        Ir para o app
      </CustomButton>
    </NotFoundWrapper>
  );
};

export default NotFound;
