export enum OnboardingStep {
  IDLE = 'IDLE',
  ACCEPT_TERMS = 'ACCEPT_TERMS',
  CONNECT_WALLET = 'CONNECT_WALLET',
  SUPPLY_ASSET = 'SUPPLY_ASSET',
  COMPLETED = 'COMPLETED',
}

export interface OnboardingInitialState {
  step: OnboardingStep;

  acceptTerms: [boolean, boolean, boolean];
}
