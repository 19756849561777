import styled from 'styled-components';

export const NotFoundWrapper = styled.div`
  display: flex;
  height: 100vh;
  width: 100%;
  background: #41326e;
  justify-content: center;
  align-items: center;
  p {
    text-align: center;
    color: white;
    font-family: 'Nexa Text', sans-serif;
    b {
      font-size: 42px;
      font-weight: 900;
    }
  }
`;
