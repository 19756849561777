import { DefaultButton } from '@munchiesmoney/bubble-gum';
import { motion } from 'framer-motion';
import styled, { css } from 'styled-components';

export const Content = styled.nav`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
`;

export const NotConnectedLabel = styled.span`
  ${({ theme }) => css`
    font-family: ${theme.fonts.nexa};
    font-weight: 400;
    font-size: ${theme.sizes.display.m};
    line-height: ${theme.sizes.lineHeight.l3};

    color: ${theme.colors.purple[100]};
  `}
`;

interface ConnectedTitleProps {
  hover: boolean;
}

export const ConnectedLabel = styled.span<ConnectedTitleProps>`
  ${({ theme, hover }) => css`
    font-family: ${theme.fonts.nexa};
    font-weight: 400;
    transition: color 0.3s;
    font-size: ${theme.sizes.display.m};
    line-height: ${theme.sizes.lineHeight.l3};
    color: ${hover ? 'white' : theme.colors.purple[100]};
  `}
`;

export const ConnectedTitle = styled.span<ConnectedTitleProps>`
  ${({ theme, hover }) => css`
    transition: color 0.3s;
    font-family: ${theme.fonts.nexaText};
    font-weight: 600;
    font-size: ${theme.sizes.display.s};
    line-height: ${theme.sizes.lineHeight.l1};
    display: block;
    color: ${hover ? 'white' : theme.colors.purple[100]}62;
    margin-bottom: 4px;
    width: 311px;
    overflow: hidden;
  `}
`;

export const ButtonsWrapper = styled.div`
  ${() => css`
    display: flex;
  `};
`;

export const Custom = styled.div`
  button {
    background-color: #5a49a9;
    opacity: 1;
    &:after {
      transition: all 0.5s ease 0s;
      background-color: #403474;
    }
    p {
      width: 10px;
      height: 14px;
      line-height: 0;
    }
    svg {
      path {
        transition: fill 0.5s ease;
        fill: #927aff;
      }
    }
    &:hover {
      &:after {
        background-color: #5a49a9;
      }
      svg {
        path {
          fill: #fff;
        }
      }
    }
  }
`;

const AnimatedButton = motion(DefaultButton);

export const CustomButton = styled(AnimatedButton)``;
