import React from 'react';
import * as S from './styles';
import { Icon } from '@munchiesmoney/bubble-gum';
import { opacityVariants, slideVariants } from './variants';
import { ButtonCopyProps } from './types';
import { useSelector } from 'react-redux';
import { RootState } from 'store';

const ButtonCopy = ({ value, hover, setHover }: ButtonCopyProps) => {
  const [copy, setCopy] = React.useState(false);
  const { wallet } = useSelector((state: RootState) => state.locale.content);
  const handleCopy = () => {
    navigator.clipboard.writeText(value || '');
    setCopy(true);
    setTimeout(() => {
      setCopy(false);
    }, 3000);
  };

  return (
    <S.ButtonCopy
      onClick={() => handleCopy()}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      initial={{ width: 34 }}
      animate={hover ? 'open' : 'closed'}
      variants={slideVariants}
      hover={hover}
    >
      <Icon name="attach" width={14} height={14} />
      {hover && (
        <S.TextCopy initial={{ opacity: 0, width: 0 }} animate={hover ? 'open' : 'closed'} variants={opacityVariants}>
          {copy ? wallet.buttonCopy.copied : wallet.buttonCopy.copy}
        </S.TextCopy>
      )}
    </S.ButtonCopy>
  );
};

export default ButtonCopy;
