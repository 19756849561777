import React from 'react';
import { NotFoundWrapper } from './NotFound.styles';

const NotAvailable: React.FC = () => {
  return (
    <NotFoundWrapper>
      <p>
        <b>500</b>
        <br />
        Página indisponível.
      </p>
    </NotFoundWrapper>
  );
};

export default NotAvailable;
