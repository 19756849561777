import React, { useCallback } from 'react';

import { AnimatePresence, motion } from 'framer-motion';
import { ControllerField, CustomButton, Icon, OutlineButton, theme } from '@munchiesmoney/bubble-gum';
import { useSelector } from 'react-redux';

import { WalletTypes } from 'components/connectors/connectors.types';

import { RootState } from 'store';

import useMetamask from 'hooks/useMetamask';

import { handlePurify } from 'utils/dom';
import { opacityVariants } from 'utils/variants';

import * as S from '../connectors.styles';

import connectImg from 'assets/imgs/connect.png';
import confirmImg from 'assets/imgs/metamask_confirm.png';
import errorImg from 'assets/imgs/metamask_error.png';
import connectedImg from 'assets/imgs/metamask_connected.png';

import { useWallet } from 'handlers/Wallet';
import { useClickOutside } from 'hooks/useClickOutside';
import { useNetwork } from 'wagmi';

const MetamaskConnector = () => {
  const { type, setType } = useWallet();

  const { isConfirm: sendOk } = useSelector((state: RootState) => state.wallet);
  const { chain } = useNetwork();

  const { wallet } = useSelector((state: RootState) => state.locale.content);
  const { isConnected } = useSelector((state: RootState) => state.wallet);

  const {
    reloadScreen,
    setReloadScreen,
    connector,
    connect,
    firstScreen,
    loadingScreen,
    errorScreen,
    connectedScreen,
    setSendOk,
  } = useMetamask({ type, setType });

  const okWrapperRef = useClickOutside<HTMLDivElement>(() => {
    if (!sendOk && isConnected && !chain?.unsupported) {
      setSendOk(true);
    }
  }, ['click']);

  const onConnectClick = useCallback(() => {
    connect({ connector });
    setType(WalletTypes.METAMASK);
  }, [setType, connect, connector]);

  return (
    <AnimatePresence>
      {!isConnected && connector.ready && (
        <S.ScreensWrapper>
          <motion.div
            initial={{ display: 'none' }}
            animate={loadingScreen ? 'open' : 'closed'}
            variants={opacityVariants}
            key={'metamask_loading'}
          >
            <S.Confirm type="metamask" style={{ overflow: 'hidden', width: '485px' }}>
              <div>
                <S.Icon>
                  <Icon name="link" />
                </S.Icon>
                <p dangerouslySetInnerHTML={{ __html: handlePurify(wallet.metaMask.confirm.message) }} />
                <img src={confirmImg} alt="" />
              </div>
              <ControllerField bgColor={'rgba(0,0,0,0.07)'} type="quaternary">
                {wallet.metaMask.confirm.alert}
              </ControllerField>
            </S.Confirm>
          </motion.div>
          <motion.div
            initial={{ display: 'none' }}
            animate={errorScreen ? 'open' : 'closed'}
            variants={opacityVariants}
            key={'metamask_error'}
          >
            <S.Error type="metamask">
              <div>
                <S.Icon>
                  <Icon name="close" color={theme.colors.red[500]} />
                </S.Icon>
                <p dangerouslySetInnerHTML={{ __html: handlePurify(wallet.metaMask.error.message) }} />
                <img src={errorImg} alt="" />
              </div>
              <ControllerField
                bgColor={'rgba(0,0,0,0.07)'}
                buttons={[
                  {
                    label: wallet.metaMask.error.alertButtonBack,
                    onClick: () => {
                      setType(WalletTypes.INITIAL);
                    },
                  },
                  {
                    label: wallet.metaMask.error.alertButtonRestart,
                    onClick: () => connect({ connector }),
                  },
                ]}
                type="tertiary"
              >
                {wallet.metaMask.error.alert}
              </ControllerField>
            </S.Error>
          </motion.div>
          <motion.div
            initial={{ display: 'none' }}
            animate={firstScreen ? 'open' : 'closed'}
            variants={opacityVariants}
            key={'metamask_initial'}
          >
            <S.Connect style={{ overflow: 'hidden', width: '220px' }}>
              <div>
                <img src={connectImg} alt="" />
                <div>
                  <Icon name="anchor-left" />
                  <p>{wallet.metaMask.connect.info}</p>
                </div>
              </div>
              <CustomButton colorScheme={'metamask'} onClick={onConnectClick}>
                {wallet.metaMask.connect.button}
              </CustomButton>
            </S.Connect>
          </motion.div>
        </S.ScreensWrapper>
      )}
      {!connector.ready && (
        <>
          <motion.div
            initial={{ display: 'none' }}
            animate={!reloadScreen ? 'open' : 'closed'}
            variants={opacityVariants}
            key={'metamask_noreload'}
          >
            <S.NoConnector>
              <div>
                <p dangerouslySetInnerHTML={{ __html: handlePurify(wallet.metaMask.noExtension.label) }} />
                <p
                  dangerouslySetInnerHTML={{
                    __html: handlePurify(wallet.metaMask.noExtension.descritpion),
                  }}
                />
              </div>
              <S.NoWallet
                onClick={() => {
                  window.open('https://metamask.io/download/');
                  setReloadScreen(true);
                }}
              >
                <div>
                  <Icon name="attention" />
                  <p>{wallet.metaMask.noExtension.noWalletLabel}</p>
                </div>
              </S.NoWallet>
              <CustomButton
                colorScheme={'metamask'}
                onClick={() => {
                  window.open('https://metamask.io/download/');
                  setReloadScreen(true);
                }}
              >
                {wallet.metaMask.noExtension.installLabel}
              </CustomButton>
            </S.NoConnector>
          </motion.div>
          <motion.div
            initial={{ display: 'none' }}
            animate={reloadScreen ? 'open' : 'closed'}
            variants={opacityVariants}
            key={'metamask_reload'}
          >
            <S.Reload>
              <div>
                <p
                  dangerouslySetInnerHTML={{
                    __html: handlePurify(wallet.metaMask.noExtension.newTabLabel),
                  }}
                />
                <p
                  dangerouslySetInnerHTML={{
                    __html: handlePurify(wallet.metaMask.noExtension.newTabDescription),
                  }}
                />
              </div>
              <S.ButtonBack>
                <OutlineButton
                  borderColor={theme.colors.metamask[700]}
                  hoverBorderColor={theme.colors.metamask[700]}
                  hoverTextColor={theme.colors.metamask[700]}
                  onClick={() => setReloadScreen(false)}
                  opacity="0.84"
                  textColor={theme.colors.metamask[700]}
                >
                  {wallet.metaMask.noExtension.backButton}
                </OutlineButton>
              </S.ButtonBack>
              <CustomButton
                colorScheme={'metamask'}
                onClick={() => {
                  window.location.reload();
                }}
              >
                {wallet.metaMask.noExtension.restartButton}
              </CustomButton>
            </S.Reload>
          </motion.div>
        </>
      )}
      <motion.div
        ref={okWrapperRef as never}
        initial={{ display: 'none' }}
        animate={connectedScreen ? 'open' : 'closed'}
        variants={opacityVariants}
        key={'metamask_connected'}
      >
        <S.Connected type="metamask" style={{ overflow: 'hidden', width: '485px' }}>
          <div>
            <S.Icon>
              <Icon name="check" color={theme.colors.purple[100]} />
            </S.Icon>
            <p
              dangerouslySetInnerHTML={{
                __html: handlePurify(wallet.metaMask.connected.message),
              }}
            />
            <img src={connectedImg} alt="" />
          </div>
          <ControllerField
            bgColor={'rgba(0,0,0,0.07)'}
            buttons={[
              {
                label: wallet.metaMask.connected.alertButton,
                onClick: () => {
                  setSendOk(true);
                },
              },
            ]}
            type="secondary"
          >
            {wallet.metaMask.connected.alert}
          </ControllerField>
        </S.Connected>
      </motion.div>
    </AnimatePresence>
  );
};

export default MetamaskConnector;
