import styled, { css } from 'styled-components';

import { WalletConnectorsProps } from 'components/WalletConnectors/WalletConnectors.types';
import { WalletTypes } from './connectors.types';

export interface QrcodeProps {
  qrCode: boolean;
}

export const ScreensWrapper = styled.div`
  min-height: 220px;
`;

export const Confirm = styled.div<WalletConnectorsProps>`
  ${({ theme, type }) => css`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 220px;
    background: ${type !== WalletTypes.WALLET_CONNECT
      ? `${theme.colors.metamask[700]}40`
      : `${theme.colors.purpleMeal[300]}35`};
    border-radius: 8px;
    position: absolute;
    top: 10px;
    left: 10px;
    width: calc(100% - 20px);
    > div:first-child {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: ${type !== WalletTypes.WALLET_CONNECT ? '0 65px 0 24px' : '0 75px 0 24px'};
      min-height: 173px;
      p {
        color: ${type !== WalletTypes.WALLET_CONNECT ? theme.colors.metamask[700] : theme.colors.purpleMeal[500]};
        font-family: ${theme.fonts.nexa};
        font-weight: 400;
        font-size: 17px;
        line-height: 24px;
      }
    }
    > div:last-child {
      color: ${type !== WalletTypes.WALLET_CONNECT ? theme.colors.metamask[700] : theme.colors.purpleMeal[500]};
    }
    > button {
      display: block;
      height: 40px;
    }
  `}
`;

export const Error = styled.div<WalletConnectorsProps>`
  ${({ theme, type }) => css`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background: ${theme.colors.red[500]}26;
    border-radius: 8px;
    min-height: 220px;
    position: absolute;
    top: 10px;
    left: 10px;
    width: calc(100% - 20px);
    > div:first-child {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: ${type !== WalletTypes.WALLET_CONNECT ? '0 65px 0 24px' : '0 75px 0 24px'};
      min-height: 173px;
      p {
        color: ${theme.colors.red[500]};
        font-family: ${theme.fonts.nexa};
        font-weight: 400;
        font-size: 17px;
        line-height: 24px;
      }
    }
    img {
      opacity: 1;
      filter: ${type !== WalletTypes.METAMASK ? 'drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))' : 'none'};
    }
    > button {
      display: block;
      height: 40px;
    }
  `}
`;

export const Icon = styled.div<WalletConnectorsProps>`
  ${({ theme, type }) => css`
    position: absolute;
    top: 51px;
    right: 34px;
    svg {
      width: 36px;
      height: 36px;
    }
    color: ${type !== WalletTypes.WALLET_CONNECT ? theme.colors.metamask[700] : theme.colors.purpleMeal[500]}!important;
  `}
`;

export const Qrcode = styled.div<QrcodeProps>`
  ${({ qrCode }) => css`
    > img,
    > svg {
      max-width: 218px;
      border-radius: 8px;
      opacity: ${qrCode ? 0.5 : 1};
      height: 100%;
      display: block;
    }
  `}
`;

export const UpdateQrcode = styled.div`
  ${({ theme }) => css`
    position: absolute;
    bottom: 10px;
    left: 10px;
    width: calc(100% - 20px);
    height: calc(100% - 20px);
    background: rgba(68, 52, 114, 0.85);
    border-radius: 8px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: end;
    p {
      margin-bottom: 17px;
      font-family: ${theme.fonts.nexaText};
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
      color: ${theme.colors.walletConnect[300]};
      display: flex;
      align-items: center;
      justify-content: center;
      svg {
        margin-right: 8px;
      }
    }
    button {
      width: 100%;
      height: 47px;
      > span:first-child {
        font-weight: 900;
      }
    }
  `}
`;

export const Connector = styled.div`
  ${({ theme }) => css`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    min-height: 220px;
    background: ${theme.colors.purple[100]};
    transition: 0.2s;
    &:first-child {
      background: rgba(46, 32, 66, 0.22);
    }
  `}
`;

export const Connected = styled.div<WalletConnectorsProps>`
  ${({ theme, type }) => css`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 220px;
    background: ${theme.colors.purple[200]}40;
    border-radius: 8px;
    position: absolute;
    top: 10px;
    left: 10px;
    width: calc(100% - 20px);
    > div:first-child {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: ${type !== WalletTypes.WALLET_CONNECT ? '0 65px 0 24px' : '0 75px 0 24px'};
      min-height: 173px;
      p {
        color: ${theme.colors.purple[100]};
        font-family: ${theme.fonts.nexa};
        font-weight: 400;
        font-size: 17px;
        line-height: 24px;
      }
    }
    > button {
      display: block;
      height: 40px;
    }
  `}
`;

export const Connect = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 220px;
    background: ${theme.colors.metamask[700]}26;
    border-radius: 8px;
    position: absolute;
    top: 10px;
    left: 10px;
    width: calc(100% - 20px);
    > div:first-child {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 0 10px;
      min-height: 173px;
      img {
        max-width: 100%;
        margin-bottom: 7px;
      }
      > div {
        color: ${theme.colors.metamask[700]};
        font-family: ${theme.fonts.nexaText};
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
        display: flex;
        svg {
          margin-right: 5px;
        }
        > div {
          display: inline-flex;
        }
      }
    }
    > button {
      display: block;
      height: 40px;
      padding-left: 0;
      padding-right: 0;
      span {
        font-weight: 900;
      }
    }
  `}
`;

export const NoConnector = styled.div`
  ${({ theme }) => css`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    border-radius: 8px;
    min-height: 220px;
    background: transparent;
    transition: 0.2s;
    > button {
      padding: 11px 0;
      > span:first-child {
        font-weight: 900;
      }
    }
    position: absolute;
    top: 10px;
    left: 10px;
    width: calc(100% - 20px);
    > div {
      &:first-child {
        padding: 0px 10px;
        > p {
          &:first-child {
            font-family: ${theme.fonts.nexa};
            font-weight: 950;
            font-size: 16px;
            line-height: 24px;
            color: ${theme.colors.metamask[500]};
            margin-bottom: 14px;
            span {
              color: ${theme.colors.metamask[700]};
            }
          }
          &:last-child {
            font-family: ${theme.fonts.nexaText};
            font-weight: 600;
            font-size: 12px;
            line-height: 16px;
            color: ${`${theme.colors.metamask[500]}`};
            opacity: 0.7;
            margin-bottom: 19px;
          }
        }
      }
    }
  `}
`;

export const NoWallet = styled.div`
  ${({ theme }) => css`
    cursor: pointer;
    padding: 14px;
    margin-bottom: 10px;
    background: ${`${theme.colors.metamask[700]}26`};
    border-radius: 8px;
    div {
      display: flex;
      align-items: center;
      font-family: ${({ theme }) => theme.fonts.nexa};
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
      color: ${({ theme }) => theme.colors.metamask[700]};
      svg {
        margin-right: 5px;
      }
    }
  `}
`;

export const Reload = styled.div`
  ${({ theme }) => css`
    position: relative;
    height: 100%;
    display: flex;
    flex-direction: column;
    border-radius: 8px;
    min-height: 220px;
    background: transparent;
    transition: 0.2s;
    position: absolute;
    top: 10px;
    left: 10px;
    width: calc(100% - 20px);
    > button {
      padding: 11px 0;
      > span:first-child {
        font-weight: 900;
      }
    }
    > div {
      &:first-child {
        padding: 4px 10px;
        > p {
          &:first-child {
            font-family: ${theme.fonts.nexa};
            font-weight: 950;
            font-size: 16px;
            line-height: 24px;
            color: ${theme.colors.metamask[500]};
            margin-bottom: 15px;
            max-width: 146px;
            span {
              color: ${theme.colors.metamask[700]};
            }
          }
          &:last-child {
            font-family: ${theme.fonts.nexaText};
            font-weight: 600;
            font-size: 12px;
            line-height: 16px;
            color: ${`${theme.colors.metamask[500]}`};
            opacity: 0.7;
            margin-bottom: 24px;
          }
        }
      }
    }
  `}
`;

export const ButtonBack = styled.div`
  position: absolute;
  top: 9px;
  right: 3px;
  button {
    padding: 3px 8px 4px 8px;
  }
`;
