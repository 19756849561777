import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import contentBR from 'locale/common.br';
import contentEN from 'locale/common.en';

import { LocaleInitialState, LocaleLanguage } from './locale.types';

const initialState = {
  lang: LocaleLanguage.PT_BR,
  content: contentBR,
  userLocale: null,
} as LocaleInitialState;

const localeSlice = createSlice({
  name: 'locale',
  initialState,
  reducers: {
    setLanguage(state, { payload }: PayloadAction<LocaleLanguage>) {
      state.lang = payload;

      switch (payload) {
        case LocaleLanguage.PT_BR:
          state.content = contentBR as never;
          break;
        case LocaleLanguage.EN_US:
          state.content = contentEN as never;
          break;
      }
    },
  },
});

export const { setLanguage } = localeSlice.actions;
export const localeReducer = localeSlice.reducer;
