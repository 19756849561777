import { DefaultButton } from '@munchiesmoney/bubble-gum';
import { motion } from 'framer-motion';
import styled, { css } from 'styled-components';

const AnimatedButton = motion(DefaultButton);

export const HeaderWrapper = styled.div`
  display: flex;
`;

export const Custom = styled.div`
  button {
    background-color: #5a49a9;
    opacity: 1;
    &:after {
      transition: all 0.5s ease 0s;
      background-color: #403474;
    }
    p {
      width: 10px;
      height: 14px;
      line-height: 0;
    }
    svg {
      path {
        transition: fill 0.5s ease;
        fill: #927aff;
      }
    }
    &:hover {
      &:after {
        background-color: #5a49a9;
      }
      svg {
        path {
          fill: #fff;
        }
      }
    }
  }
`;

export const CustomButton = styled(AnimatedButton)``;

export const HeaderBody = styled.div`
  ${({ theme }) => css`
    display: flex;
    justify-content: end;
    align-items: center;
    p {
      font-family: ${theme.fonts.nexaText};
      font-weight: 950;
      font-size: 18px;
      line-height: 24px;
      color: ${theme.colors.purple[200]};
      margin-right: 11px;
    }
    span {
      font-family: ${theme.fonts.nexaText};
      font-style: normal;
      font-weight: 600;
      font-size: 10px;
      line-height: 30px;
      color: ${theme.colors.purple[200]};
      margin-right: 46px;
      height: 24px;
    }
  `}
`;

export const Content = styled.nav`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;

  button {
    & + button {
      margin-left: 4px;
    }
    &:nth-child(5) {
      width: 34px;
      height: 28px;
      padding: 0;
      margin: -3px 0 0 22px;
    }
  }
`;

export const NoticeAgreement = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;

    > span {
      font-family: ${theme.fonts.nexa};
      font-weight: 400;
      font-size: ${theme.sizes.display.m};
      line-height: ${theme.sizes.lineHeight.l3};
      color: ${theme.colors.purple[100]};
    }
  `}
`;

export const LangSwitch = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: -3px 0 0 26px;
    > div button {
      word-break: keep-all;
      width: 56px;
      > p {
        font-family: ${theme.fonts.nexaText};
        font-weight: 700;
        font-size: 10px;
        color: ${theme.colors.purple[200]};
      }
    }
    > div {
      &:nth-child(1) {
        button {
          border-radius: 8px 0 0 8px;
          &:after {
            border-radius: 8px 0 0 8px;
          }
        }
      }
      &:nth-child(2) {
        button {
          margin-left: 2px;

          border-radius: 0 8px 8px 0;
          &:after {
            border-radius: 0 8px 8px 0;
          }
        }
      }
    }
  `}
`;

export const SwitchButton = styled.div`
  &[aria-disabled='true'] {
    opacity: 0.5;
  }
`;
