import { confirmConnection, setIsConnect } from 'modules/wallet/wallet.slice';
import { useEffect, useState } from 'react';
import { useDispatch } from 'store';
import { useAccount } from 'wagmi';

export const useWatchAccount = () => {
  const dispatch = useDispatch();

  const { isConnected } = useAccount();

  const [started, setStarted] = useState(false);

  useEffect(() => {
    dispatch(setIsConnect(isConnected));

    if (!isConnected) {
      dispatch(confirmConnection(false));
    } else if (!started) {
      setStarted(true);
      dispatch(confirmConnection(true));
    }
  }, [isConnected]);
};
