import styled, { css } from 'styled-components';
import { motion } from 'framer-motion';

export const Wrapper = styled.div`
  display: grid;
  align-items: stretch;
`;

export const Overflow = styled(motion.div)`
  ${({ theme }) => css`
    position: relative;
    background-color: ${`${theme.colors.purple[300]}42`};
    border-radius: 40px 8px 8px 24px;
    height: 356px;
    overflow-x: hidden;
    overflow-y: auto;
    ::-webkit-scrollbar {
      width: 10px;
      border-radius: 8px;
      background-color: ${`${theme.colors.purple[500]}99`};
      position: relative;
      right: -50px;
    }
    ::-webkit-scrollbar-thumb {
      border-radius: 8px;
      background-color: ${`${theme.colors.purple[400]}80`};
      width: 10px;
    }
  `}
`;

export const Scrollbar = styled.div`
  ${({ theme }) => css`
    position: relative;
    width: 10px;
    border-radius: 8px;
    background-color: ${`${theme.colors.purple[500]}99`};
    overflow: hidden;
    height: 100%;
  `}
`;

export const Handler = styled(motion.div)`
  ${({ theme }) => css`
    position: relative;
    width: 10px;
    height: 144px;
    border-radius: 8px;
    background-color: ${`${theme.colors.purple[400]}80`};
  `}
`;
