import { motion } from 'framer-motion';
import styled, { css, keyframes } from 'styled-components';

export const Wrapper = styled(motion.div).attrs({
  layout: true,
})``;

export const Intro = styled.div`
  ${({ theme }) => css`
    position: relative;
    padding: 32px 24px;
    > img {
      position: absolute;
      top: 29px;
      right: 66px;
      opacity: 0.5;
      width: 119px;
      height: 130px;
      animation-name: ${ImagePulseKeyframe};
      animation-duration: 800ms;
      animation-timing-function: ease-in-out;
      animation-iteration-count: infinite;
    }
    ul {
      padding: 0;
      margin: 0;
      li {
        display: flex;
        flex-direction: row;
        height: 46px;
        align-items: center;
        h5 {
          font-family: ${theme.fonts.mindset};
          font-size: ${theme.sizes.title.xl};
          font-weight: 400;
          line-height: ${theme.sizes.lineHeight.l3};
          color: rgba(255, 255, 255, 0.62);
        }
        button {
          margin-left: 20px;
          padding: 4px 8px;
          border-radius: 8px;
          &:hover {
            background-color: rgba(0, 0, 0, 0.3);
          }
          > p {
            font-family: ${theme.fonts.nexa};
            font-weight: 800;
            line-height: ${theme.sizes.lineHeight.l1};
          }
        }
      }
    }
    > p {
      font-family: ${theme.fonts.nexa};
      font-size: ${theme.sizes.text.xl};
      font-weight: 300;
      line-height: ${theme.sizes.lineHeight.l3};
      padding-right: 64px;
      margin: 16px 0 24px 0;
      color: rgba(255, 255, 255, 0.6);
      a {
        color: ${theme.colors.purple[100]};
        text-decoration: underline;
      }
    }
    > h6 {
      display: flex;
      flex-direction: row;
      font-family: ${theme.fonts.nexaText};
      font-size: ${theme.sizes.text.s};
      line-height: ${theme.sizes.lineHeight.l1};
      color: rgba(255, 255, 255, 0.48);
      svg {
        margin-right: 8px;
        animation-name: ${ArrowPulseKeyframe};
        animation-duration: 600ms;
        animation-timing-function: ease-in-out;
        animation-iteration-count: infinite;
        path {
          fill: currentColor;
          opacity: 1;
        }
      }
    }
  `}
`;

export const Agreements = styled.div`
  ${({ theme }) => css`
    ul {
      li {
        display: grid;
        padding: 32px;
        grid-template-columns: 24px 1fr;
        grid-gap: 16px;
        align-items: flex-start;
        cursor: pointer;
        background-color: ${`${theme.colors.purple[200]}2E`};
        transition: all ease-in-out 300ms;
        > p {
          font-family: ${theme.fonts.nexaText};
          font-size: ${theme.sizes.text.l};
          font-weight: 400;
          opacity: 0.82;
          line-height: ${theme.sizes.lineHeight.l3};
          color: rgba(255, 255, 255, 0.82);
          margin: -6px 0 0 0;
        }
        &[aria-checked='true'] {
          background-color: ${`${theme.colors.purple[200]}0F`};
          > p {
            color: ${`${theme.colors.green[500]}82`};
            opacity: 1;
          }
        }
        & + li {
          margin-top: 4px;
        }
        &:first-child {
          border-radius: 40px 40px 0 0;
          &[aria-checked='true'] {
            border-radius: 0;
          }
        }
        &:last-child {
          border-radius: 0 0 40px 40px;
        }
      }
    }
    footer {
      position: relative;
      padding: 28px 22px;
      &[aria-disabled='true'] {
        opacity: 0.5;
        cursor: not-allowed;
        > button {
          z-index: -1;
        }
        &:after {
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          z-index: 3;
        }
      }
      button {
        width: 100%;
      }
    }
  `}
`;

const ArrowPulseKeyframe = keyframes`
  0% {
    color: rgba(255, 255, 255, 0.48);
    transform: translateY(-1px);
  }
  50% {
    color: white;
    transform: translateY(1px);
  } 
  100% { 
    color: rgba(255, 255, 255, 0.48);
    transform: translateY(-1);
  } 
`;

const ImagePulseKeyframe = keyframes`
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(0.96);
  } 
  100% { 
    transform: scale(1);
  } 
`;
