import React, { createContext, useContext, useEffect, useMemo, useState } from 'react';

import { useSelector } from 'react-redux';
import { useAccount, useBalance, useNetwork } from 'wagmi';
import { Icon, SectionWrapper, SwapCard, useLocalStorage } from '@munchiesmoney/bubble-gum';

import { RootState, useDispatch } from 'store';

import { setSectionState } from 'modules/navigation/navigation.slice';

import * as S from './Wallet.styles';

import PixLogo from 'components/PixLogo';
import WalletHeader from 'components/headers/WalletHeader';

import WalletConnectors from 'components/WalletConnectors';

import { IWalletContext } from './Wallet.types';
import { WalletTypes } from 'components/connectors/connectors.types';
import { toFixed } from 'utils/deposit';

const WalletContext = createContext({} as IWalletContext);

const Wallet = () => {
  const [, setAddressStorage] = useLocalStorage('address', '');
  const dispatch = useDispatch();

  const { isConnected, connector, address } = useAccount();

  const { data: ethBalance } = useBalance({
    address: address,
  });

  const { chain } = useNetwork();

  const { wallet } = useSelector((state: RootState) => state.locale.content);

  const { disabled, collapsed, closed, pulse } = useSelector((state: RootState) => state.navigation.wallet);
  const { vaults } = useSelector((state: RootState) => state.vaults);

  const { vaultId } = useSelector((state: RootState) => state.transaction);

  const [type, setType] = useState(WalletTypes.INITIAL);

  const sectionHeight = useMemo(() => (closed ? 72 : collapsed ? 456 : 574), [closed, collapsed]);

  const isMetamask = type === WalletTypes.METAMASK;
  const isWalletconnect = type === WalletTypes.WALLET_CONNECT;
  const isChangeNetwork = type === WalletTypes.CHANGE_NETWORK;
  const isInitial = type === WalletTypes.INITIAL;

  useEffect(() => {
    if (isConnected) {
      setAddressStorage(address);
    } else {
      setAddressStorage('');
    }
  }, [address, isConnected]);

  useEffect(() => {
    if (chain?.unsupported) {
      setType(WalletTypes.CHANGE_NETWORK);
      dispatch(
        setSectionState({
          section: 'meals',
          config: {
            collapsed: false,
            disabled: true,
            pulse: false,
            closed: false,
          },
        }),
      );
      dispatch(
        setSectionState({
          section: 'wallet',
          config: {
            pulse: true,
            collapsed: true,
          },
        }),
      );
    }
  }, [chain?.unsupported]);

  useEffect(() => {
    if (!chain?.unsupported && connector?.id === 'metaMask') {
      setType(WalletTypes.METAMASK);
    } else if (!chain?.unsupported && connector?.id === 'walletConnect') {
      setType(WalletTypes.WALLET_CONNECT);
    }
  }, [connector?.id, chain?.unsupported]);

  useEffect(() => {
    if (!isConnected) {
      setType(WalletTypes.INITIAL);
      dispatch(
        setSectionState({
          section: 'wallet',
          config: {
            pulse: true,
            collapsed: true,
          },
        }),
      );
    }
  }, [isConnected]);

  const wethTotalValue = useMemo(() => {
    if (!vaults.length) {
      return '0.0';
    }

    return String(vaults.find((vault) => vault.symbol !== 'USDC')?.value);
  }, [vaults]);

  const usdcTotalValue = useMemo(() => {
    if (!vaults.length) {
      return '0.0';
    }

    return String(vaults.find((vault) => vault.symbol === 'USDC')?.value);
  }, [vaults]);

  const ethTotalValue = useMemo(() => {
    if (!vaults.length) {
      return '0.0';
    }

    return ethBalance?.formatted;
  }, [vaults]);

  return (
    <SectionWrapper
      height={sectionHeight}
      headerComponent={<WalletHeader />}
      isDisabled={!!vaultId || disabled}
      withBlinkEffect={pulse}
      withBody
    >
      {chain?.unsupported || collapsed ? (
        <WalletContext.Provider
          value={{
            type,
            setType,
            isMetamask,
            isWalletconnect,
            isChangeNetwork,
            isInitial,
          }}
        >
          <WalletConnectors />
        </WalletContext.Provider>
      ) : (
        <div>
          <S.PixContent style={{ opacity: 0.5, cursor: 'not-allowed' }}>
            <header>
              <PixLogo />
              <button style={{ cursor: 'not-allowed' }}>
                <Icon name="lock" /> {wallet.pix.validateIdentity} <Icon name="chevron-down" />
              </button>
            </header>
            {/* <nav>
          <button disabled>
            <Icon name="usdc" width={14.28} height={13.64} />
            {wallet.pix.depositUsdc}
          </button>
          <button disabled>
            <Icon name="usdc" width={14.28} height={13.64} />
            {wallet.pix.withdrawUsdc}
          </button>
        </nav> */}
            <span style={{ cursor: 'not-allowed' }}>{wallet.pix.comingSoon}</span>
          </S.PixContent>
          <S.SwapContent>
            <header>
              <h3>{wallet.pix.balances}</h3>
            </header>
            <main>
              <SwapCard
                color="pinkMeal"
                token="eth"
                walletAmount={
                  ethTotalValue !== undefined && ethTotalValue !== '0.0'
                    ? toFixed(ethTotalValue as never, 4)
                    : '0.0000' || '0.0000'
                }
                isAnimationDisabled={!!vaultId || disabled}
                isDisabled={false}
                content={wallet.swapCard}
              />
              <SwapCard
                color="purpleMeal"
                token="weth"
                walletAmount={
                  wethTotalValue !== undefined && wethTotalValue !== '0.0'
                    ? toFixed(wethTotalValue as never, 4)
                    : '0.0000' || '0.0000'
                }
                isAnimationDisabled={!!vaultId || disabled}
                isDisabled={wethTotalValue === '0.0'}
                content={wallet.swapCard}
              />
              <SwapCard
                color="violetMeal"
                token="usdc"
                walletAmount={
                  usdcTotalValue !== undefined && usdcTotalValue !== '0.0'
                    ? toFixed(usdcTotalValue as never, 4)
                    : '0.0000' || '0.0000'
                }
                isDisabled={usdcTotalValue === '0.0'}
                isAnimationDisabled={!!vaultId || disabled}
                content={wallet.swapCard}
              />
            </main>
          </S.SwapContent>
        </div>
      )}
    </SectionWrapper>
  );
};

export const useWallet = () => useContext(WalletContext);

export default Wallet;
