import React from 'react';

import purify from 'dompurify';
import { useSelector } from 'react-redux';
import { Logo, SectionWrapper, Header, CustomButton, theme } from '@munchiesmoney/bubble-gum';

import { RootState } from 'store';

import * as S from './MobileMessage.styles';

import walletMobile from 'assets/imgs/vectors/walletmobile.png';

const MobileMessage = () => {
  const { mobile, name, version } = useSelector((state: RootState) => state.locale.content);

  return (
    <S.Wrapper>
      <SectionWrapper
        headerComponent={
          <Header icon={<Logo name="munchies" />} bgColor={[theme.colors.purple[500], theme.colors.purple[600]]}>
            <S.HeaderBody>
              <p>{name}</p>
              <span>{version}</span>
            </S.HeaderBody>
          </Header>
        }
        //@ts-ignore
        height={'100%'}
        transitionDelay={0.8}
        withBody
      >
        <div>
          <S.Image>
            <img src={walletMobile} alt="wallet" />
          </S.Image>
          <div></div>
          <S.SectionBody>
            <div dangerouslySetInnerHTML={{ __html: purify.sanitize(mobile.content) }} />
            <S.Buttons>
              <CustomButton
                colorScheme={'lightPurple'}
                onClick={() => {
                  window.open('https://munchies.money', '_blank');
                }}
              >
                {mobile.initialPage}
              </CustomButton>
              <CustomButton
                colorScheme={'lightPurple'}
                onClick={() => {
                  window.open('https://discord.com/invite/UpwJEPWVzM', '_blank');
                }}
              >
                {mobile.waitingList}
              </CustomButton>
            </S.Buttons>
          </S.SectionBody>
        </div>
      </SectionWrapper>
    </S.Wrapper>
  );
};

export default MobileMessage;
