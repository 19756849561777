import { createSlice } from '@reduxjs/toolkit';

import { TransactionInitialState, TransactionStatus } from './transaction.types';

const initialState = {
  isTransacting: false,

  vaultId: null,
  hash: null,

  type: null,

  amounts: [],

  status: TransactionStatus.IDLE,

  result: null,
} as TransactionInitialState;

const slice = createSlice({
  name: 'transaction',
  initialState,
  reducers: {
    setVaultId: (state, { payload: id }) => {
      state.vaultId = id;
    },
    setType: (state, { payload }) => {
      state.type = payload;
    },
    setStatus: (state, { payload }) => {
      state.status = payload;
    },
    setAmount: (state, { payload: { id, value } }) => {
      if (!state.amounts.find((a) => a.id === id)) {
        state.amounts.push({ id, value });
      } else {
        const i = state.amounts.findIndex((a) => a.id === id);

        state.amounts[i].value = value;
      }
    },
    setTransacting: (state, { payload }) => {
      state.isTransacting = payload;
    },
  },
});

export const { setVaultId, setType, setStatus, setAmount, setTransacting } = slice.actions;

export const transactionReducer = slice.reducer;
