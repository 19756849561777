import styled, { css } from 'styled-components';

type ContainerProps = {
  isLoading?: boolean;
};

export const Container = styled.main<ContainerProps>`
  ${({ theme, isLoading }) => css`
    opacity: ${isLoading ? 0 : 1};
    overflow: auto;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 16px;
    align-items: flex-start;
    width: 100%;
    max-width: ${theme.sizes.container};
    margin: 0 auto;
    padding: 20px 44px;
  `}
`;

export const Column = styled.div`
  flex: 1;
  > section + section {
    margin-top: 22px;
  }
`;
