import React, { useEffect } from 'react';

import type { InitialTermsStepProps } from 'components/InitialTerms/InitialTerms.types';

import * as S from './Contract.styles';
import { OutlineButton, theme } from '@munchiesmoney/bubble-gum';
import { motion, useAnimationControls } from 'framer-motion';
import { handlePurify } from 'utils/deposit';
import { useSelector } from 'react-redux';
import { RootState } from 'store';

const ContractStep = ({ step, setStep, overflowControls, setTerms, terms }: InitialTermsStepProps) => {
  const controls = useAnimationControls();
  const { navbar } = useSelector((state: RootState) => state.locale.content);

  const onBack = () => {
    setStep(1);
    setTerms(undefined);

    overflowControls.start({
      background: `${theme.colors.purple[300]}42`,
      transition: {
        duration: 0.6,
      },
    });
  };

  useEffect(() => {
    switch (step) {
      case 1:
        controls.start({
          opacity: 0,
          x: 521,
          transitionEnd: {
            zIndex: -1,
          },
          transition: {
            type: 'spring',
            stiffness: 100,
            mass: 0.2,
            duration: 0.15,
          },
        });

        break;
      case 2:
        controls.start({
          opacity: 1,
          x: 0,
          zIndex: 3,
          transition: {
            type: 'spring',
            stiffness: 100,
            mass: 0.2,
            duration: 0.15,
            delay: 0.05,
          },
        });

        break;
    }
  }, [step, controls]);

  const opacityVariants = {
    open: {
      opacity: 1,
      transition: {
        duration: 0.1,
      },
      transitionEnd: {
        display: 'block',
      },
    },
    closed: {
      opacity: 0,
      transition: {
        duration: 0.1,
      },
      transitionEnd: {
        display: 'none',
      },
    },
  };

  if (step === 0) return null;

  return (
    <S.Wrapper layout animate={controls} style={{ x: 521 }}>
      <motion.div variants={opacityVariants} animate={terms === 0 ? 'open' : 'closed'}>
        <S.Header>
          <h5 dangerouslySetInnerHTML={{ __html: handlePurify(navbar.terms.privacy.title) }} />
          <time dangerouslySetInnerHTML={{ __html: handlePurify(navbar.terms.privacy.update) }} />
          <OutlineButton onClick={onBack}>Voltar</OutlineButton>
        </S.Header>
        <div dangerouslySetInnerHTML={{ __html: handlePurify(navbar.terms.privacy.content) }} />
      </motion.div>
      <motion.div variants={opacityVariants} initial={{ display: 'none' }} animate={terms === 1 ? 'open' : 'closed'}>
        <S.Header>
          <h5 dangerouslySetInnerHTML={{ __html: handlePurify(navbar.terms.declaration.title) }} />
          <time dangerouslySetInnerHTML={{ __html: handlePurify(navbar.terms.declaration.update) }} />
          <OutlineButton onClick={onBack}>Voltar</OutlineButton>
        </S.Header>
        <div dangerouslySetInnerHTML={{ __html: handlePurify(navbar.terms.declaration.content) }} />
      </motion.div>
      <motion.div variants={opacityVariants} initial={{ display: 'none' }} animate={terms === 2 ? 'open' : 'closed'}>
        <S.Header>
          <h5 dangerouslySetInnerHTML={{ __html: handlePurify(navbar.terms.terms.title) }} />
          <time dangerouslySetInnerHTML={{ __html: handlePurify(navbar.terms.terms.update) }} />
          <OutlineButton onClick={onBack}>Voltar</OutlineButton>
        </S.Header>
        <div dangerouslySetInnerHTML={{ __html: handlePurify(navbar.terms.terms.content) }} />
      </motion.div>
    </S.Wrapper>
  );
};

export default ContractStep;
