import React, { useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { SectionWrapper } from '@munchiesmoney/bubble-gum';
import * as S from './styles';
import { RootState, useDispatch } from 'store';
import { LayoutGroup } from 'framer-motion';
import { useClickOutside } from 'hooks/useClickOutside';
import useVaults from 'hooks/useVaults';
import VaultHeader from 'components/headers/VaultHeader';
import Vault from 'components/Vault';
import { setType, setVaultId } from 'modules/transaction/transaction.slice';
import { convertFromOracle, convertToBrl, sumBigNumbers } from 'utils/deposit';
import { useAccount } from 'wagmi';
import { setSectionState } from 'modules/navigation/navigation.slice';
import { confirmConnection } from 'modules/wallet/wallet.slice';
import { findParentId } from 'utils/dom';

const Vaults = () => {
  const dispatch = useDispatch();

  const { meals } = useSelector((state: RootState) => state.navigation);
  const { isConnected } = useAccount();

  const { vaultId, isTransacting, type } = useSelector((state: RootState) => state.transaction);

  const { safes } = useSelector((state: RootState) => state.vaults);

  const { vaults, isLoading } = useVaults();

  const totalValue = convertToBrl(convertFromOracle(sumBigNumbers(safes.map((safe) => safe.value))));

  const ref = useClickOutside(
    (e) => {
      if (!isTransacting && !findParentId(e.target, 'vaults-section')) {
        if (e.type === 'mouseover' && !!type) {
          return;
        }

        if (type && vaultId) {
          dispatch(setType(null));

          if (totalValue === 'R$ 0,00') {
            dispatch(setVaultId(null));
          } else {
            setTimeout(() => {
              dispatch(setVaultId(null));
            }, 300);
          }
        } else {
          dispatch(setType(null));
          dispatch(setVaultId(null));
        }

        // for (const vault of vaults as fetchMealsList_getMeals[]) {
        //   dispatch(
        //     setAmount({
        //       id: vault?.id,
        //       value: '0.0000',
        //     }),
        //   );
        // }
      }
    },
    ['click', 'mouseover'],
  );

  const sectionHeight = useMemo(() => {
    if (meals.collapsed) {
      return 448;
    }

    return 868;
  }, [meals]);

  useEffect(() => {
    if (!isConnected) {
      dispatch(confirmConnection(false));
      dispatch(
        setSectionState({
          section: 'meals',
          config: {
            pulse: false,
            disabled: true,
          },
        }),
      );
    }
  }, [isConnected, dispatch]);

  return (
    <S.Wrapper ref={ref} id="vaults-section">
      <SectionWrapper
        height={sectionHeight}
        isDisabled={meals.disabled || isLoading}
        isDisabledAround={vaultId ? !!type : false}
        headerComponent={<VaultHeader totalVaultsValue={isConnected ? totalValue : 'R$ 0,00'} />}
        withBlinkEffect={meals.pulse}
        withBody
      >
        <S.List>
          <LayoutGroup>
            {vaults?.map((vault) => (
              <Vault key={vault?.id} vault={vault} />
            ))}
          </LayoutGroup>
        </S.List>
      </SectionWrapper>
    </S.Wrapper>
  );
};

export default Vaults;
