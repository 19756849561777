import React, { createContext, useContext, useState } from 'react';

import { Overlay } from './FocusProvider.styles';

type FocusProviderProps = {
  children: React.ReactNode;
};

type TFocusProviderContext = {
  nodeId?: string;

  setNodeId(nodeId: string | undefined): void;
  setLocked(locked: boolean): void;
};

const FocusProviderContext = createContext({} as TFocusProviderContext);

const FocusProvider = ({ children }: FocusProviderProps) => {
  const [nodeId, setNodeId] = useState<string>();
  const [locked, setLocked] = useState(false);

  return (
    <FocusProviderContext.Provider
      value={{
        nodeId,

        setNodeId,
        setLocked,
      }}
    >
      {children}
      {nodeId && <Overlay {...{ 'data-ignore-outside-clicks': !locked ? undefined : 'true' }} />}
    </FocusProviderContext.Provider>
  );
};

export const useFocusControl = () => {
  return useContext(FocusProviderContext);
};

export default FocusProvider;
