import styled, { css } from 'styled-components';
import { motion } from 'framer-motion';
import { addTransparencyToHex } from '@munchiesmoney/bubble-gum';

export const PixContent = styled(motion.div)`
  ${({ theme }) => css`
    position: relative;

    display: flex;

    flex-direction: column;
    align-items: stretch;
    justify-content: space-between;

    height: 136px;

    padding: 16px;

    margin-bottom: 9px;

    background-color: ${addTransparencyToHex(theme.colors.purple[200], 12)};

    border-radius: 40px 40px 24px 24px;

    > header {
      display: flex;

      flex-direction: row;
      align-items: center;

      padding-left: 8px;

      > svg {
        margin-right: 20px;
      }

      > button {
        flex: 1;

        display: flex;

        align-items: center;

        padding: 0 14px;

        background-color: transparent;

        height: 36px;

        color: ${addTransparencyToHex(theme.colors.purple[200], 74)};

        font-family: ${theme.fonts.nexa};
        font-size: ${theme.sizes.text.s};
        font-weight: 300;

        line-height: ${theme.sizes.lineHeight.l1};

        border: solid 1px ${addTransparencyToHex(theme.colors.purple[200], 40)};
        border-radius: 8px 28px 8px 8px;

        > span {
          &:nth-child(1) {
            svg {
              width: 13px;
              height: 13px;

              margin-right: 8px;
            }
          }
          &:nth-child(2) {
            margin: 0 8px 0 auto;

            svg {
              width: 10px;

              opacity: 0.5;

              margin-left: 10px;

              transform: translateY(1.8px);
            }
          }
        }
      }
    }

    > nav {
      display: flex;

      width: 100%;
      flex-direction: row;

      > button {
        flex: 1;

        display: flex;

        align-items: center;
        justify-content: flex-start;

        padding: 0 10px;

        height: 44px;

        background-color: ${addTransparencyToHex(theme.colors.purple[200], 12)};

        border: none;
        border-radius: 8px;

        font-family: ${theme.fonts.nexa};
        font-size: ${theme.sizes.text.s};
        font-weight: 400;

        line-height: ${theme.sizes.lineHeight.l1};

        color: ${theme.colors.purple[200]};

        & + button {
          margin-left: 8px;
        }

        > span {
          margin-right: 8px;
        }

        &:disabled {
          opacity: 0.4;
          cursor: not-allowed;
        }
      }
    }

    > span {
      display: block;

      height: 44px;

      padding: 10px 26px;

      background-color: rgba(0, 0, 0, 0.1);

      color: ${addTransparencyToHex(theme.colors.purple[200], 70)};

      font-family: ${theme.fonts.nexa};
      font-size: ${theme.sizes.text.l};
      font-weight: 400;

      border-radius: 8px;

      cursor: default;

      user-select: none;
    }
  `}
`;

export const SwapContent = styled(motion.div)`
  ${({ theme }) => css`
    background-color: ${addTransparencyToHex(theme.colors.purple[200], 12)};

    border-radius: 40px 40px 24px 24px;

    overflow: hidden;

    header {
      padding: 19px 0 20px 23px;

      h3 {
        font-family: ${theme.fonts.nexaText};
        font-weight: 700;
        font-size: ${theme.sizes.text.s};

        line-height: ${theme.sizes.lineHeight.l1};

        color: ${addTransparencyToHex(theme.colors.purple[100], 62)};
      }
    }

    main {
      > * + * {
        margin-top: 5px;
      }
    }
  `}
`;
