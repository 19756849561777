import { createSlice } from '@reduxjs/toolkit';
import { VaultsInitialState } from './vaults.types';

const initialState = {
  isLoading: false,
  items: [],
  safes: [],
  vaults: [],
} as VaultsInitialState;

const slice = createSlice({
  name: 'vaults',
  initialState,
  reducers: {
    resetVaults: () => initialState,
    setIsLoading: (state, { payload: isLoading }) => {
      state.isLoading = isLoading;
    },
    setVaults: (state, { payload: items }) => {
      state.items = items;
    },
    updateSafes: (state, { payload }) => {
      const safeIndex = state.safes.findIndex((safe) => safe.id === payload.id);

      if (safeIndex !== -1) {
        state.safes[safeIndex] = payload;
      } else {
        state.safes.push(payload);
      }
    },
    updateVaults: (state, { payload }) => {
      const vaultIndex = state.vaults.findIndex((vault) => vault.id === payload.id);

      if (vaultIndex !== -1) {
        state.vaults[vaultIndex] = payload;
      } else {
        state.vaults.push(payload);
      }
    },
    updateApy: (state, { payload }) => {
      const { index, apr } = payload as any;

      // @ts-ignore
      state.items[index as number].apr = apr;
    },
  },
});

export const { resetVaults, setIsLoading, setVaults, updateApy, updateSafes, updateVaults } = slice.actions;

export const vaultsReducer = slice.reducer;
