import { motion } from 'framer-motion';
import styled from 'styled-components';
import { DefaultButton } from '@munchiesmoney/bubble-gum';

export const NotFoundWrapper = styled.div`
  display: flex;
  height: 100vh;
  width: 100%;
  background: #41326e;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  p {
    text-align: center;
    color: white;
    font-family: 'Nexa Text', sans-serif;
    b {
      font-size: 42px;
      font-weight: 900;
    }
  }
`;

const AnimatedButton = motion(DefaultButton);

export const CustomButton = styled(AnimatedButton)``;
