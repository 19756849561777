import styled from 'styled-components';
import { motion } from 'framer-motion';

export const Wrapper = styled(motion.section)`
  position: relative;
`;

export const List = styled(motion.div)`
  display: flex;

  flex-direction: column;

  align-items: stretch;

  gap: 9px;
`;
