import styled, { css } from 'styled-components';
import { motion } from 'framer-motion';

export const Wrapper = styled(motion.div)`
  ${({ theme }) => css`
    > div {
      position: absolute;
      opacity: 0;
      width: 100%;
      top: 0;
      left: 0;
      > div {
        > p {
          font-family: ${theme.fonts.nexaText};
          font-weight: 400;
          font-size: ${theme.sizes.text.l};
          line-height: ${theme.sizes.lineHeight.l3};
          padding: 0 26px;
        }
        > h6 {
          font-family: ${theme.fonts.nexa};
          font-weight: 900;
          font-size: ${theme.sizes.text.xl};
          line-height: ${theme.sizes.lineHeight.l3};
          color: ${theme.colors.purple[100]};
          text-transform: uppercase;
          margin: 24px 0 10px 26px;
        }
        > ul {
          margin: 18px 0 10px 26px;
          padding: 0 16px;
          list-style: disc;
          li {
            position: relative;
            font-family: ${theme.fonts.nexaText};
            font-weight: 400;
            font-size: ${theme.sizes.text.l};
            line-height: ${theme.sizes.lineHeight.l3};
            padding: 0 0 0 8px;
            & + li {
              margin-top: 24px;
            }
          }
        }
      }
    }
  `}
`;

export const Header = styled.header`
  ${({ theme }) => css`
    padding: 36px 26px 18px 26px;
    > h5 {
      font-family: ${theme.fonts.mindset};
      font-size: 32px;
      line-height: 34px;
      color: ${theme.colors.purple[100]};
      margin-bottom: 12px;
    }
    > time {
      font-family: ${theme.fonts.nexaText};
      font-weight: 600;
      font-size: ${theme.sizes.text.s};
      line-height: ${theme.sizes.lineHeight.l1};
      color: ${theme.colors.purple[300]};
    }
    > button {
      position: absolute;
      top: 20px;
      right: 19px;
      padding: 3px 8px;
      border-radius: 8px;
      &:hover {
        background-color: rgba(0, 0, 0, 0.3);
        margin-top: -1px;
        padding: 4px 8px;
      }
      > p {
        font-family: ${theme.fonts.nexaText};
        font-weight: 900;
        font-size: 10px;
        line-height: ${theme.sizes.lineHeight.l1};
      }
    }
  `}
`;
