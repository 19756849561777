import styled from 'styled-components';
import { motion } from 'framer-motion';
import { DefaultButton } from '@munchiesmoney/bubble-gum';

export const Wrapper = styled.div`
  button {
    opacity: 1;
    &:after {
      transition: all 0.5s ease 0s;
      background-color: #403474;
    }
    p {
      width: 10px;
      height: 14px;
      line-height: 0;
    }
    svg {
      path {
        transition: fill 0.5s ease;
        fill: #927aff;
      }
    }
    &:hover {
      &:after {
        background-color: #5a49a9;
      }
      svg {
        path {
          fill: #fff;
        }
      }
    }
  }
`;

const AnimatedButton = motion(DefaultButton);

export const CustomButton = styled(AnimatedButton)``;
