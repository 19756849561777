import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  display: flex;

  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  padding: 0 12px;

  height: 91px;

  overflow: hidden;

  max-width: 100%;

  svg {
    opacity: 0;
    animation: 0.2s opacity ease-in-out forwards;

    @keyframes opacity {
      0% {
        opacity: 0;
      }
      100% {
        opacity: 1;
      }
    }
  }
`;

type ColorProps = {
  primary: string;
};

export const IdleDescription = styled.div<ColorProps>`
  ${({ theme, primary }) => css`
    flex-shrink: 0;

    width: 159px;

    font-family: ${theme.fonts.nexaText};
    font-size: 12px;

    line-height: 16px;

    color: ${primary};

    opacity: 0.9;
  `}
`;

export const NoAmountDescription = styled.div<ColorProps>`
  ${({ theme, primary }) => css`
    flex-shrink: 0;

    width: 159px;

    font-family: ${theme.fonts.nexaText};
    font-size: 12px;

    line-height: 16px;
    padding-left: 12px;

    color: ${primary};

    &[aria-disabled='true'] {
      color: rgba(255, 255, 255, 0.4);
      padding-left: 0;
    }
  `}
`;

type BigTextProps = ColorProps & {
  isGreen?: boolean;
  isRed?: boolean;
};

export const BigText = styled.div<BigTextProps>`
  ${({ theme, primary, isGreen = false, isRed = false }) => css`
    flex-shrink: 0;

    width: 187px;

    font-family: ${theme.fonts.nexa};
    font-size: 17px;

    line-height: 24px;

    color: ${primary};

    ${isGreen &&
    css`
      color: #adff00;
    `}

    ${isRed &&
    css`
      color: #ff7272;
    `}

    ${isGreen &&
    css`
      color: #adff00;
    `}
  `}
`;

export const ConfirmWithdraw = styled.div<ColorProps>`
  ${({ theme, primary }) => css`
    flex-shrink: 0;

    width: 214px;

    color: ${primary};

    h3 {
      font-family: ${theme.fonts.nexa};
      font-size: 17px;

      line-height: 24px;
    }

    p {
      font-family: ${theme.fonts.nexaText};
      font-size: 12px;

      line-height: 16px;

      opacity: 0.9;
    }
  `}
`;

interface ReviwProps {
  primary: string;
}

export const ReviewDeposit = styled.div<ReviwProps>`
  ${({ theme, primary }) => css`
    padding: 0 5px;
    width: 100%;
    color: ${primary};

    p {
      font-family: ${theme.fonts.nexaText};
      font-size: 12px;
      display: flex;
      justify-content: start;
      align-items: center;
      font-weight: 600;
      line-height: 16px;

      svg {
        margin-left: 12px;
        margin-right: 5px;
        height: 8px;
        path {
          fill: ${primary};
        }
      }
      strong {
        margin-left: 12px;
        display: flex;
        justify-content: start;
        align-items: center;
        > span {
          svg {
            margin-left: 0px;
          }
        }
      }

      span {
        opacity: 0.61;
        display: flex;
        justify-content: start;
        align-items: center;
      }

      &:not(:last-child) {
        margin-bottom: 5px;
      }
      &:last-child {
        opacity: 1;
      }
    }
  `}
`;
