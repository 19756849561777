import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  position: relative;
`;

interface OverlayProps {
  disabled?: boolean;
}

export const Overlay = styled.div<OverlayProps>`
  ${({ disabled }) => css`
    position: absolute;

    top: 0;
    left: 0;

    width: 100%;
    height: 100%;

    border-radius: 40px 40px 24px 24px;

    z-index: 10;

    cursor: pointer;

    &[data-disabled='true'] {
      cursor: not-allowed;
    }

    ${disabled &&
    css`
      cursor: not-allowed;
    `}
  `}
`;
