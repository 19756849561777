import { motion } from 'framer-motion';
import styled, { css } from 'styled-components';

export const Container = styled.div``;
export const Svg = styled(motion.svg)``;

export const QrcodeLoader = styled.div`
  ${() => css`
    position: absolute;
    top: calc(50% - 37px);
    left: calc(50% - 37px);
    width: 75px;
    height: 75px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    transition: 0.2s;
    > img {
      width: 37px;
      height: 41px;
      margin-bottom: 2px;
    }
    > div {
      max-width: 90%;
    }
  `}
`;
