import React, { useRef, useState } from 'react';

import { LayoutGroup, useAnimationControls } from 'framer-motion';

import * as S from './InitialTerms.styles';

import AgreementsStep from './steps/Agreements';
import ContractStep from './steps/Contract';

const InitialTerms = () => {
  const overflowRef = useRef<HTMLDivElement>(null);

  const [terms, setTerms] = useState<number | undefined>(undefined);

  const overflowControls = useAnimationControls();

  // const { scrollY } = useScroll({ container: overflowRef });
  // const moveY = useTransform(scrollY, [0, 538], [0, 218]);

  const [step, setStep] = useState(0);

  return (
    <S.Wrapper>
      <S.Overflow ref={overflowRef} animate={overflowControls}>
        <LayoutGroup>
          <AgreementsStep
            step={step}
            setStep={setStep}
            overflowControls={overflowControls}
            terms={terms}
            setTerms={setTerms}
          />
          <ContractStep
            step={step}
            setStep={setStep}
            overflowControls={overflowControls}
            terms={terms}
            setTerms={setTerms}
          />
        </LayoutGroup>
      </S.Overflow>
      {/* <S.Scrollbar>
        <S.Handler style={{ y: moveY }} />
      </S.Scrollbar> */}
    </S.Wrapper>
  );
};

export default InitialTerms;
