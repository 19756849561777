import { Icon } from '@munchiesmoney/bubble-gum';
import React from 'react';

import * as S from './LimitValues.styles';

import type { LimitValuesProps } from './LimitValues.types';
import { useSelector } from 'react-redux';
import { RootState } from 'store';

const LimitValues = ({ labels, values }: LimitValuesProps) => {
  const { deposit } = useSelector((state: RootState) => state.locale.content);
  return (
    <S.Wrapper columns={labels.length}>
      <S.Column>
        <S.Label>{labels[0]}</S.Label>
        <S.Value>
          <Icon name="value" /> {values[0]}
        </S.Value>
      </S.Column>
      {!!labels[1] && (
        <S.Column>
          <S.Label>{labels[1]}</S.Label>
          <S.ApyValue>
            {values[1]}% {deposit.perYear}
          </S.ApyValue>
        </S.Column>
      )}
    </S.Wrapper>
  );
};

export default LimitValues;
