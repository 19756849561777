const LIDO_API = 'https://eth-api.lido.fi/v1/protocol/steth/apr/sma';
const OVERNIGHT_API = 'https://arbitrum.overnight.fi/api/widget/avg-apy-info/week';
const GMX_API = 'https://gmx-api-r5dc6.ondigitalocean.app';

export const getLidoAPY = async () => {
  try {
    const res = await fetch(LIDO_API);
    const json = await res.json();

    return json.data.smaApr;
  } catch {
    return 0;
  }
};

export const getOvernightAPY = async () => {
  try {
    const res = await fetch(OVERNIGHT_API);
    const json = await res.json();

    return json.value;
  } catch {
    return 0;
  }
};

export const getGmxAPY = async () => {
  try {
    const res = await fetch(GMX_API);
    const json = await res.json();

    return json.apr;
  } catch {
    return 0;
  }
};
