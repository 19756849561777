import styled, { css, keyframes } from 'styled-components';
import { motion } from 'framer-motion';

export const Custom = styled.div`
  opacity: 0.5;
  &:hover {
    opacity: 1;
    transition: 0.3s;
  }
  button {
    opacity: 1;
    p {
      width: 10px;
      height: 14px;
      line-height: 0;
    }
  }
`;

export const Content = styled(motion.div)`
  ${({ theme }) => css`
    position: relative;

    background-color: ${`${theme.colors.purple[200]}29`};

    border-radius: 40px 40px 24px 24px;

    overflow: hidden;
  `}
`;

export const LimitCard = styled(motion.div)`
  ${({ theme }) => css`
    flex: 1;

    display: flex;

    flex-direction: column;

    overflow: hidden;

    background-color: ${`${theme.colors.yellow[500]}1A`};
  `}
`;

export const LimitValue = styled.div`
  ${({ theme }) => css`
    font-family: ${theme.fonts.nexa};
    font-size: ${theme.sizes.text.xl};
    font-weight: 900;

    line-height: ${theme.sizes.lineHeight.l3};

    color: ${`${theme.colors.yellow[600]}E6`};

    margin: 4px 0 0 0;
  `}
`;

type LimitHeaderProps = {
  resizeState: number;
};

export const LimitHeader = styled(motion.header)<LimitHeaderProps>`
  ${({ resizeState }) => css`
    position: relative;

    display: grid;

    grid-template-columns: repeat(2, 1fr);
    grid-gap: 4px;

    overflow: hidden;

    ${LimitCard} {
      &:nth-child(1) {
        padding: 20px 10px 21px 34px;
        border-radius: 40px 8px 8px 24px;
      }
      &:nth-child(3) {
        padding: 20px 34px 21px 10px;
        border-radius: 8px 40px 24px 8px;

        align-items: flex-end;

        ${LimitValue} {
          text-align: right;
        }
      }
    }

    ${resizeState === 2 &&
    css`
      ${LimitCard} {
        &:nth-child(3) {
          ${LimitLabel} {
            justify-content: flex-end;
          }
        }

        ${LimitLabel} {
          > span[data-percentage='true'] {
            display: none;
          }
        }

        ${LimitValue} {
          display: none;
        }
      }

      ${LimitProgress} {
        top: 16.5px;
      }
    `}
  `}
`;

export const LimitLabel = styled.div`
  ${({ theme }) => css`
    display: flex;

    width: 100%;

    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    span {
      color: ${`${theme.colors.yellow[600]}B3`};

      font-size: ${theme.sizes.text.s};
      font-weight: 700;
      font-family: ${theme.fonts.nexaText};

      &[data-percentage] {
        color: ${theme.colors.yellow[600]}!important;
      }
    }
  `}
`;

export const LimitProgress = styled.div`
  position: absolute;

  top: 40px;
  left: 50%;
  transform: translateX(-50%);

  [data-testid='bar-available-limit'] {
    border-color: #3f314e;

    > div {
      background-color: transparent;
    }
  }
`;

export const LimitButton = styled.button`
  ${({ theme }) => css`
    display: flex;

    flex-direction: row;
    align-items: center;

    border: none;

    padding: 10px;

    width: 144px;
    height: 44px;

    font-family: ${theme.fonts.nexaText};
    font-size: ${theme.sizes.text.s};

    border-radius: 8px;

    margin-top: 51px;

    cursor: pointer;

    background-color: ${`${theme.colors.yellow[500]}38`};

    color: ${theme.colors.yellow[500]};

    svg {
      width: 19px;
      height: 19px;

      margin-right: 6px;

      transform: translateY(-1px);

      path {
        fill: ${`${theme.colors.yellow[500]}A8`};
      }
    }
  `}
`;

export const HealthContainer = styled(motion.div)`
  ${({ theme }) => css`
    position: relative;

    height: 352px;

    overflow: hidden;

    padding: 93px 22px 0 22px;

    [data-testid='bar-health-big'] {
      > div {
        animation: none;
        background-color: ${`${theme.colors.purple[100]}4D`};
      }
    }

    &:after {
      position: absolute;

      content: '';

      bottom: -192px;
      left: 0;

      width: 100%;
      height: 255px;

      background-color: ${`${theme.colors.purple[200]}29`};

      border-radius: 40px 40px 0 0;
    }
  `}
`;

export const HealthValue = styled.div`
  ${({ theme }) => css`
    font-family: ${theme.fonts.nexa};
    font-size: ${theme.sizes.display.m};
    font-weight: 800;

    text-transform: uppercase;

    padding-left: 12px;

    line-height: ${theme.sizes.lineHeight.l3};
  `}
`;

export const HealthLabel = styled.div`
  ${({ theme }) => css`
    font-size: ${theme.sizes.text.s};
    font-weight: 700;
    font-family: ${theme.fonts.nexaText};
    line-height: ${theme.sizes.lineHeight.l1};

    padding-left: 12px;

    margin: 8px 0 21px 0;
  `}
`;

export const HealthDescription = styled.div`
  ${({ theme }) => css`
    font-size: ${theme.sizes.text.s};
    line-height: ${theme.sizes.lineHeight.l2};

    color: ${theme.colors.purple[100]};

    max-width: 244px;

    padding-left: 12px;

    margin-top: 18px;
  `}
`;

export const HealthFooter = styled.footer`
  ${({ theme }) => css`
    position: relative;

    display: flex;

    flex-direction: column;
    align-items: stretch;
    justify-content: flex-end;

    background-color: #614fa3;

    height: 195px;

    border-radius: 8px 8px 24px 24px;

    margin-top: -4px;

    padding: 29px 24px 21px 24px;

    z-index: 2;

    > [data-testid='bar-balance'] {
      margin-top: 26px;

      > div {
        &:nth-child(1) {
          background-color: ${theme.colors.pinkMeal[500]};
        }

        &:nth-child(2) {
          background-color: ${theme.colors.purpleMeal[500]};
          margin: 0 6px;
        }

        &:nth-child(3) {
          background-color: ${theme.colors.violetMeal[500]};
        }
      }
    }
  `}
`;

export const HealthFooterDistribution = styled.div`
  ${({ theme }) => css`
    display: flex;

    align-items: center;

    padding: 0 8px;

    > div {
      flex: 1;

      display: flex;
      align-items: center;

      > span {
        &:nth-child(1) {
          margin-right: 12px;

          transform: translateY(2px);
        }

        &:nth-child(3) {
          margin: 1px 5px 0 6px;
        }

        &:nth-child(2),
        &:nth-child(4) {
          font-family: ${theme.fonts.nexa};
          font-weight: 900;

          line-height: 24px;
        }

        &:nth-child(2) {
          font-size: 16px;
        }

        &:nth-child(4) {
          font-size: 13px;

          margin: 0 7px 0 0;
        }
      }

      &:nth-child(1) {
        justify-content: flex-start;
        > span {
          &:nth-child(2) {
            color: ${theme.colors.pinkMeal[300]};
          }
          &:nth-child(3) {
            color: ${theme.colors.pinkMeal[500]};
          }
          &:nth-child(4) {
            color: ${theme.colors.pinkMeal[500]};
          }
        }
      }

      &:nth-child(2) {
        justify-content: center;
        > span {
          &:nth-child(2) {
            color: ${theme.colors.purpleMeal[300]};
          }
          &:nth-child(3) {
            color: ${theme.colors.purpleMeal[500]};
          }
          &:nth-child(4) {
            color: ${theme.colors.purpleMeal[500]};
          }
        }
      }

      &:nth-child(3) {
        justify-content: flex-end;
        > span {
          &:nth-child(2) {
            color: ${theme.colors.violetMeal[300]};
          }
          &:nth-child(3) {
            color: ${theme.colors.violetMeal[500]};
          }
          &:nth-child(4) {
            color: ${theme.colors.violetMeal[500]};
          }
        }
      }
    }
  `}
`;

const MouthEffect = keyframes`
  0% {
    transform: translateY(0px)
  }
  50% {
    transform: translateY(11px)
  }
  100% {
    transform: translateY(0px)
  }
`;

const CardEffect = keyframes`
  0% {
    transform: translateY(0px)
  }
  50% {
    transform: translateY(25px)
  }
  100% {
    transform: translateY(0px)
  }
`;

const ShadowEffect = keyframes`
  0% {
    transform: scale(1);
    opacity: 0.1;
  }
  50% {
    transform: scale(0.7182);
    opacity: 0.18;
  }
  100% {
    transform: scale(1);
    opacity: 0.1;
  }
`;

export const MouthAnimation = styled(motion.div)`
  position: absolute;

  top: 106px;
  right: 14px;

  width: 180px;
  height: 227px;

  img {
    position: absolute;

    &:nth-child(1) {
      width: 162px;
      height: 144px;

      top: 0px;
      left: 6px;

      animation-name: ${MouthEffect};
      animation-duration: 2s;
      animation-timing-function: ease-in-out;
      animation-iteration-count: infinite;
    }

    &:nth-child(2) {
      width: 73px;
      height: 56px;

      top: 110px;
      right: 3px;

      animation-name: ${CardEffect};
      animation-duration: 2s;
      animation-timing-function: ease-in-out;
      animation-iteration-count: infinite;
    }

    &:nth-child(3) {
      width: 110.5px;
      height: 17.5px;

      bottom: 0;
      left: 41.36px;

      opacity: 0.1;

      transform-origin: top center;

      animation-name: ${ShadowEffect};
      animation-duration: 2s;
      animation-timing-function: ease-in-out;
      animation-iteration-count: infinite;
    }
  }
`;
