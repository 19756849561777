import purify from 'dompurify';

export const handlePurify = (text: string) => {
  return purify.sanitize(text);
};

export const findParentId = (target: any, id: string) => {
  let currentTarget = target.parentNode;

  while (currentTarget !== document.body) {
    if (!currentTarget) {
      return true;
    }

    if (currentTarget.id === id) {
      return true;
    }

    currentTarget = currentTarget.parentNode;
  }

  if (currentTarget.id === id) {
    return true;
  }

  return false;
};
