import { motion } from 'framer-motion';
import styled from 'styled-components';

export const Loader = styled(motion.div)`
  display: flex;
  height: 100vh;
  overflow: hidden;
  width: 100%;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  background-color: rgb(48, 35, 71);
  z-index: 9999;
  left: 0;
  img {
    max-width: 168px;
    margin-bottom: 49px;
    animation: fadeIn 3s;
  }
  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
`;
