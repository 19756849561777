import { WalletTypes } from 'components/connectors/connectors.types';
import { WalletDataProps } from 'handlers/Wallet/Wallet.types';
import { setSectionState } from 'modules/navigation/navigation.slice';
import { confirmConnection, setIsConnect } from 'modules/wallet/wallet.slice';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store';
import { useAccount, useConnect, useNetwork } from 'wagmi';

let numbTime: string | number | NodeJS.Timeout | null | undefined = null;

const useWalletconnect = ({ type, setType }: WalletDataProps) => {
  const { isConnected } = useAccount();
  const { connectors, connect, isError } = useConnect();
  const [reloadScreen, setReloadScreen] = useState(false);
  const { chain } = useNetwork();
  const [uri, setUri] = useState<string | null>(null);
  const [qrCode, setQrCode] = useState(false);
  const [health, setHealth] = useState(100);
  const dispatch = useDispatch();

  const [firstScreen, setFirstScreen] = useState(true);
  const [errorScreen, setErrorScreen] = useState(false);
  const [connectedScreen, setConnectedScreen] = useState(false);
  const { isConfirm: sendOk } = useSelector((state: RootState) => state.wallet);

  const setSendOk = useCallback(
    (value: boolean) => {
      dispatch(confirmConnection(value));
    },
    [dispatch],
  );

  function qrcodeTimeout(duration = 30) {
    let timer = duration;
    if (numbTime !== null) {
      setQrCode(false);
      setHealth(100);
      clearInterval(numbTime);
      numbTime = null;
    }
    numbTime = setInterval(function () {
      if (timer === 20) {
        setHealth(66);
      }
      if (timer === 10) {
        setHealth(33);
      }
      if (--timer <= 0) {
        timer = duration;
        setQrCode(true);
        setHealth(0);
      }
    }, 1000);
  }

  const fetchQrcode = async () => {
    const cc = connectors[1].id === 'walletConnect' && connectors[1];
    if (!cc) {
      return console.error('No connector found.');
    }
    const provider = await cc.getProvider();
    provider.on('display_uri', (uri: string) => {
      setUri(uri);
      setHealth(100);
      setQrCode(false);
      qrcodeTimeout();
    });
    if (!isConnected) {
      connect({ connector: cc });
    } else return;
  };

  useEffect(() => {
    fetchQrcode();
  }, [connectors[1], connect, chain?.unsupported, isConnected]);

  useEffect(() => {
    dispatch(setIsConnect(isConnected));
  }, [isConnected]);

  useEffect(() => {
    if (type === WalletTypes.INITIAL) {
      setFirstScreen(true);
      setErrorScreen(false);
      setConnectedScreen(false);
    }
    if (connectors[1]?.id === 'walletConnect' && isError) {
      if (errorScreen === false) {
        setType(WalletTypes.WALLET_CONNECT);
      }
      setErrorScreen(true);
      setFirstScreen(false);
      setConnectedScreen(false);
    }
    if (connectors[1]?.id === 'walletConnect' && isConnected && !isError) {
      setConnectedScreen(true);
      setFirstScreen(false);
      setErrorScreen(false);
    }
  }, [type, isError, isConnected]);

  const onClose = useCallback(() => {
    dispatch(
      setSectionState({
        section: 'wallet',
        config: {
          pulse: false,
          collapsed: false,
        },
      }),
    );

    dispatch(
      setSectionState({
        section: 'meals',
        config: {
          pulse: false,
          disabled: false,
        },
      }),
    );
  }, [dispatch]);

  useEffect(() => {
    if (isConnected || sendOk) {
      onClose();
    }
  }, [sendOk]);

  return {
    isError,
    reloadScreen,
    setReloadScreen,
    uri,
    qrCode,
    fetchQrcode,
    health,
    onClose,
    firstScreen,
    errorScreen,
    connectedScreen,
    setSendOk,
  };
};

export default useWalletconnect;
