import React, { useEffect, useState } from 'react';

import { isMobile, isTablet, useMobileOrientation } from 'react-device-detect';
import { useSelector } from 'react-redux';
import { LayoutGroup } from 'framer-motion';
import * as S from './styles';

import { RootState, useDispatch } from 'store';

import FocusProvider from 'providers/FocusProvider';

import Credit from 'handlers/Credit';
import Vaults from 'handlers/Vaults';
import Terms from 'handlers/Terms';
import Wallet from 'handlers/Wallet';

import MobileMessage from 'components/MobileMessage';
import { Column, Container } from 'components/Container';

import { setLanguage } from 'modules/locale/locale.slice';
import { useWatchAccount } from 'hooks/useWatchAccount';
import useVaults from 'hooks/useVaults';
import handGift from 'assets/gifs/handgif.gif';
import { principalLoaderVariant } from 'utils/variants';

export const App: React.FC = () => {
  const dispatch = useDispatch();

  const { lang } = useSelector((state: RootState) => state.locale);

  const [hasLoaded, setLoaded] = useState(false);
  const { isLoading } = useVaults();
  const { isLandscape } = useMobileOrientation();

  useWatchAccount();

  useEffect(() => {
    if (!hasLoaded) {
      dispatch(setLanguage(lang));
      setLoaded(true);
    }
  }, [hasLoaded, dispatch, lang]);

  if (isMobile && (!isTablet || !isLandscape)) {
    return <MobileMessage />;
  }

  return (
    <FocusProvider>
      <S.Loader animate={isLoading || !hasLoaded ? 'open' : 'closed'} variants={principalLoaderVariant}>
        <img src={handGift} alt="loading" />
      </S.Loader>
      <Container isLoading={isLoading || !hasLoaded}>
        <Column>
          <LayoutGroup>
            <Terms />
            <Vaults />
          </LayoutGroup>
        </Column>
        <Column>
          <LayoutGroup>
            <Wallet />
            <Credit />
          </LayoutGroup>
        </Column>
      </Container>
    </FocusProvider>
  );
};
