import React, { useEffect } from 'react';

import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Root from 'components/Root';

import { App } from './pages/App';
import NotFound from './pages/NotFound';
import NotAvailable from 'pages/500';
import ServiceUnavailable from 'pages/503';

export const Router = () => {
  useEffect(() => {
    const disableTabKey = function (e: any) {
      if (e.keyCode === 9) {
        e.preventDefault();
      }
    };

    document.addEventListener('keydown', disableTabKey);

    return () => {
      document.removeEventListener('keydown', disableTabKey);
    };
  }, []);

  return (
    <BrowserRouter basename="/">
      <Routes>
        <Route path="/*" element={<NotFound />} />
        {/* <Route path="/404" element={<NotFound />} /> */}
        <Route path="/500" element={<NotAvailable />} />
        <Route path="/503" element={<ServiceUnavailable />} />
        <Route path="/" element={<Root />} errorElement={<NotFound />}>
          <Route path="/" element={<App />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
};
