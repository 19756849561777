import { CustomButton, Icon, InputField, MealCard } from '@munchiesmoney/bubble-gum';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import {
  erc20ABI,
  erc4626ABI,
  useAccount,
  useContractRead,
  useContractWrite,
  usePrepareContractWrite,
  useProvider,
  useWaitForTransaction,
} from 'wagmi';
import oracleABI from 'abi/IPriceOracle.json';
import vaultABI from 'abi/IVault.json';
import { motion, AnimatePresence } from 'framer-motion';
import { BigNumber, ethers, utils } from 'ethers';
import {
  convertFromOracle,
  convertToBrl,
  formatAsPercentage,
  inputTokenFormat,
  toFixed,
  toFixedMax,
  trim_decimal_overflow,
} from 'utils/deposit';
import { TransactionStatus, TransactionType } from 'modules/transaction/transaction.types';
import { useSelector } from 'react-redux';
import { RootState, useDispatch } from 'store';
import { setAmount, setStatus, setTransacting, setType, setVaultId } from 'modules/transaction/transaction.slice';
import { useClickOutside } from 'hooks/useClickOutside';
import { updateSafes } from 'modules/vaults/vaults.slice';
import { useVaultBlock } from 'components/Vault';
import WithdrawDisplay from './Display';
import { BigNumber as BN } from 'bignumber.js';
import { findParentId } from 'utils/dom';

type InputHandle = {
  focus(): void;
  blur(): void;
};

const Withdraw = ({
  hasNoWithdrawBalance,
  disabled,
}: {
  hasNoWithdrawBalance: boolean | undefined;
  disabled: boolean;
}) => {
  const dispatch = useDispatch();

  const {
    id,
    symbol,
    token,
    maxWithdraw,
    vaultBalance,
    balanceMax,
    additionalColors,
    additionalContractAddresses,
    contractAddress,
    assetAddress,
    colors,
    underlyingAssetSymbol,
    inputIcon,
  } = useVaultBlock();

  const inputRef = useRef<InputHandle>(null);

  const { vaultId, type, amounts, status, isTransacting } = useSelector((state: RootState) => state.transaction);
  const { withdraw: content } = useSelector((state: RootState) => state.locale.content);

  const { address, isConnected } = useAccount();
  const provider = useProvider();
  const [gasPrice, setGasPrice] = useState<any>(null);
  const [selected, setSelected] = useState(false);

  const withdrawValue = useMemo(() => {
    const amount = amounts.find((a) => a.id === vaultId);

    if (!amount) return '0.0000';

    return amount.value;
  }, [amounts, vaultId]);

  const ref = useClickOutside(
    (e) => {
      if (!selected) {
        return;
      }

      if (
        (!isTransacting && type === TransactionType.WITHDRAW && vaultId === id) ||
        status === TransactionStatus.COMPLETE
      ) {
        dispatch(setType(null));

        if (!findParentId(e.target, 'vaults-section')) {
          setTimeout(() => {
            dispatch(setVaultId(null));
          }, 300);
        }

        dispatch(setStatus(TransactionStatus.IDLE));
        dispatch(
          setAmount({
            id: vaultId,
            value: '0.0000',
          }),
        );
        dispatch(setTransacting(false));
        setSelected(false);
      }
    },
    ['click'],
  );

  const steps = useMemo(() => {
    if (status === TransactionStatus.ERROR) {
      return [
        {
          id: TransactionStatus.ERROR,
          title: content.steps.header.transactionCanceled,
          icon: 'close',
        },
      ];
    }

    return [
      {
        id: TransactionStatus.IDLE,
        title: `${content.stepsHeader.idle(symbol)}`,
        icon: 'arrow-up',
      },
      {
        id: TransactionStatus.REVIEW_TRANSACTION,
        title: content.steps.header.reviewTransaction,
        icon: 'eye',
      },
      {
        id: TransactionStatus.WAIT_ACCEPT,
        title: content.stepsHeader.sign,
        icon: 'key',
      },
      {
        id: TransactionStatus.WAIT_TRANSACTION,
        title: content.stepsHeader.waiting,
        icon: 'spin',
      },
      {
        id: TransactionStatus.COMPLETE,
        title: content.stepsHeader.done,
        icon: 'check',
      },
    ];
  }, [symbol, status]);

  const actionLabel = useMemo(() => {
    if (status === TransactionStatus.IDLE) {
      return content.steps.max;
    }

    if (status === TransactionStatus.WAIT_ACCEPT || status === TransactionStatus.REVIEW_TRANSACTION) {
      return content.steps.back;
    }

    return content.steps.close;
  }, [status]);

  const tokenDecimals = useContractRead({
    address: assetAddress,
    abi: erc20ABI,
    functionName: 'decimals',
    enabled: isConnected && withdrawValue !== '0.0000' && id === vaultId && type === TransactionType.WITHDRAW,
  });

  const maxWithdrawComputed = useMemo(() => {
    if (!maxWithdraw.data || !balanceMax.data) return '0.0000';

    const maxWithdrawValue = maxWithdraw.data as BigNumber;
    const assetBalance = balanceMax.data as BigNumber;

    const amount = maxWithdrawValue.lt(assetBalance) ? maxWithdrawValue : assetBalance;

    return utils.formatUnits(amount, tokenDecimals.data);
  }, [balanceMax, maxWithdraw, tokenDecimals]);

  const prepare = usePrepareContractWrite({
    address: contractAddress,
    abi: erc4626ABI,
    functionName: 'withdraw',
    enabled: id === vaultId && type === TransactionType.WITHDRAW && isConnected && withdrawValue !== '0.0000',
    args: [
      utils.parseUnits(trim_decimal_overflow(withdrawValue, tokenDecimals?.data as number), tokenDecimals?.data),
      address as never,
      address as never,
    ],
    onError(err) {
      if (err.message.includes('withdraw less than min')) {
        // dispatch(setStatus(TransactionStatus.ERROR));
        // console.log('Saque não atingiu o mínimo');
      }

      if (err.message.includes('insufficient funds for gas')) {
        // dispatch(setStatus(TransactionStatus.ERROR));
        // console.log('Você precisa reservar um pouco de ETH para pagar o gas');
      }
    },
  });

  const vaultBalanceConvertedData = useContractRead({
    address: additionalContractAddresses?.priceFeed,
    abi: oracleABI,
    functionName: 'convertToDerivedFiat',
    args: [balanceMax?.data, assetAddress as never],
    watch: false,
    enabled: isConnected,
    onSuccess(data) {
      dispatch(
        updateSafes({
          id,
          symbol,
          value: data,
        }),
      );
    },
  });

  const inputConvertedData = useContractRead({
    address: additionalContractAddresses?.priceFeed,
    abi: oracleABI,
    functionName: 'convertToDerivedFiat',
    args: [
      utils.parseUnits(trim_decimal_overflow(withdrawValue, tokenDecimals.data as number), tokenDecimals?.data),
      assetAddress,
    ],
    enabled: id === vaultId && type === TransactionType.WITHDRAW && isConnected && withdrawValue !== '0.0000',
    watch: false,
  });

  useEffect(() => {
    if (status === TransactionStatus.REVIEW_TRANSACTION) {
      (async () => {
        try {
          const gasPrice = await provider?.getGasPrice();
          const ABI = ['function withdraw(uint256,address,address) returns (uint256)'];
          const iFace = new ethers.utils.Interface(ABI);
          const sigHash = iFace.encodeFunctionData('withdraw', [
            utils.parseUnits(withdrawValue, tokenDecimals?.data),
            address,
            address,
          ]);
          const estimateGas = await provider?.estimateGas({ to: contractAddress, data: sigHash, from: address });
          const value = gasPrice.mul(estimateGas);
          setGasPrice(value);
        } catch (e) {
          // console.log('error', e);
        }
      })();
    }
  }, [status, withdrawValue, provider]);

  const fee = useContractRead({
    address: contractAddress,
    abi: vaultABI,
    functionName: 'estimateWithdrawAfterFees',
    args: [utils.parseUnits(trim_decimal_overflow(withdrawValue, tokenDecimals.data as number), tokenDecimals?.data)],
    enabled:
      isConnected && withdrawValue !== '0.0000' && id === vaultId && status === TransactionStatus.REVIEW_TRANSACTION,
    watch: false,
  });

  const reviewScreenValue = useMemo(() => {
    if (fee?.data !== undefined) {
      const supply = utils.parseUnits(
        trim_decimal_overflow(withdrawValue, tokenDecimals.data as number),
        tokenDecimals?.data,
      );
      const total = supply?.sub(fee?.data as BigNumber);
      return utils.formatUnits(total, tokenDecimals?.data);
    }
    return '0';
  }, [withdrawValue, fee]);

  const reviewScreenConvertedData = useContractRead({
    address: additionalContractAddresses?.priceFeed,
    abi: oracleABI,
    functionName: 'convertToDerivedFiat',
    args: [utils.parseUnits(reviewScreenValue, tokenDecimals?.data), assetAddress],
    enabled:
      isConnected &&
      reviewScreenValue !== undefined &&
      fee?.data !== undefined &&
      id === vaultId &&
      status === TransactionStatus.REVIEW_TRANSACTION,
    watch: false,
  });

  const reviewScreenGasConvertedData = useContractRead({
    address: additionalContractAddresses?.priceFeed,
    abi: oracleABI,
    functionName: 'convertToDerivedFiat',
    args: [gasPrice && utils.parseEther(utils.formatEther(gasPrice)), process.env.WETH_ADDRESS],
    enabled: isConnected && gasPrice && id === vaultId && status === TransactionStatus.REVIEW_TRANSACTION,
    watch: false,
  });

  const { data, reset, isError, ...transaction } = useContractWrite(prepare.config as never);

  const {
    isLoading,
    isSuccess,
    isError: isWaitError,
  } = useWaitForTransaction({
    hash: data?.hash,
  });

  useEffect(() => {
    if (isError) {
      dispatch(setStatus(TransactionStatus.ERROR));
      reset();
    }
  }, [isError, dispatch]);

  useEffect(() => {
    if (isLoading) {
      dispatch(setStatus(TransactionStatus.WAIT_TRANSACTION));
    }
  }, [isLoading, dispatch]);

  useEffect(() => {
    if (!isLoading && isSuccess) {
      dispatch(setStatus(TransactionStatus.COMPLETE));
      vaultBalance.refetch();
      vaultBalanceConvertedData.refetch();
      balanceMax.refetch();
      token.refetch();
    }
  }, [isLoading, isSuccess, dispatch]);

  useEffect(() => {
    if (!isLoading && isWaitError) {
      dispatch(setStatus(TransactionStatus.ERROR));
      reset();
    }
  }, [isLoading, isWaitError, dispatch]);

  useEffect(() => {
    if (vaultId === id) {
      dispatch(setStatus(TransactionStatus.IDLE));
      dispatch(setType(null));
      dispatch(
        setAmount({
          id: vaultId,
          value: '0.0000',
        }),
      );
      dispatch(setTransacting(false));
      setSelected(false);
    }
  }, [vaultId, id, vaultId, dispatch]);

  useEffect(() => {
    if (status === TransactionStatus.COMPLETE) {
      vaultBalance.refetch();
      token.refetch();
      balanceMax.refetch();
    }
  }, [vaultId, isConnected, status]);

  const hasAmount = useMemo(() => {
    if (vaultId === id && withdrawValue !== '0.0000' && withdrawValue !== '0' && type === TransactionType.WITHDRAW) {
      const walletAmount = balanceMax?.data
        ? utils.formatUnits(balanceMax?.data as never, tokenDecimals?.data)
        : '0.0000';
      return BN(withdrawValue || '0.0').isLessThanOrEqualTo(walletAmount);
    }

    return true;
  }, [type, vaultId, balanceMax, withdrawValue, id]);

  const requestWithdraw = useCallback(() => {
    reset();
    dispatch(setTransacting(true));
    transaction?.write?.();
  }, [transaction, dispatch]);

  const handleSubmit = useCallback(() => {
    if (status === TransactionStatus.REVIEW_TRANSACTION) {
      dispatch(setStatus(TransactionStatus.WAIT_ACCEPT));
      requestWithdraw();
      return;
    }
    dispatch(setStatus(TransactionStatus.REVIEW_TRANSACTION));
  }, [requestWithdraw, status]);

  const handleChange = useCallback(
    (value: string) => {
      if (!type) {
        handleSelectTab();
      }

      if (value === '' || value === ' ' || value === '0.0') {
        return dispatch(
          setAmount({
            id: vaultId,
            value: '0.0000',
          }),
        );
      }
      dispatch(
        setAmount({
          id: vaultId,
          value: inputTokenFormat(parseFloat(value.replace(/\D/g || 0.0, ''))),
        }),
      );
    },
    [dispatch, vaultId, type],
  );

  const noVaultBalance = useMemo(() => {
    if (isTransacting && vaultId === id) {
      return false;
    }
    if (
      vaultBalanceConvertedData?.data &&
      convertToBrl(convertFromOracle((vaultBalanceConvertedData?.data as never) || 0.0)) === 'R$ 0,00'
    ) {
      return true;
    }
    return false;
  }, [balanceMax, tokenDecimals, isTransacting, vaultId, id]);

  const handleMax = useCallback(() => {
    if (!type) {
      handleSelectTab();
    }

    const manipulatedValue = toFixedMax(maxWithdrawComputed || '0.0', tokenDecimals.data as number);

    if (
      manipulatedValue.length === 0 ||
      manipulatedValue === ' ' ||
      manipulatedValue === '0' ||
      manipulatedValue === '0.0'
    ) {
      dispatch(
        setAmount({
          id: vaultId,
          value: '0.0000',
        }),
      );
      return;
    }

    dispatch(
      setAmount({
        id: vaultId,
        value: maxWithdrawComputed,
      }),
    );
  }, [maxWithdrawComputed, vaultId, symbol, dispatch, token, tokenDecimals]);

  const handleSelectTab = useCallback(() => {
    if (noVaultBalance) {
      return;
    }

    setSelected(true);

    setTimeout(() => {
      dispatch(setType(TransactionType.WITHDRAW));
    }, 50);

    setTimeout(() => {
      inputRef.current?.focus();
    }, 200);
  }, [noVaultBalance, inputRef, dispatch]);

  const handleAction = useCallback(() => {
    if (status === TransactionStatus.IDLE) {
      isConnected && handleMax();
      return;
    }

    if (status === TransactionStatus.WAIT_ACCEPT || status === TransactionStatus.REVIEW_TRANSACTION) {
      setTimeout(() => {
        inputRef.current?.focus();
        dispatch(setStatus(TransactionStatus.IDLE));
        dispatch(setTransacting(false));
      }, 200);

      return;
    }

    if (status === TransactionStatus.COMPLETE) {
      dispatch(setStatus(TransactionStatus.IDLE));
      dispatch(
        setAmount({
          id: vaultId,
          value: '0.0000',
        }),
      );
      dispatch(setTransacting(false));
      dispatch(setVaultId(null));
      dispatch(setType(null));
      return;
    }

    if (status === TransactionStatus.ERROR) {
      dispatch(setStatus(TransactionStatus.IDLE));
      dispatch(
        setAmount({
          id: vaultId,
          value: '0.0000',
        }),
      );
      dispatch(setTransacting(false));
      return;
    }
  }, [status, dispatch, inputRef, vaultId, handleMax, isConnected]);

  const balance = useMemo(() => {
    if (balanceMax?.data) {
      return utils.formatUnits(balanceMax?.data as never, tokenDecimals.data);
    }
    return '0.0000';
  }, [balanceMax?.data, tokenDecimals?.data]);

  const showConfirmButton =
    type === TransactionType.WITHDRAW &&
    ((status === TransactionStatus.IDLE && withdrawValue !== '0.0000' && hasAmount) ||
      (status === TransactionStatus.REVIEW_TRANSACTION && withdrawValue !== '0.0000' && hasAmount));

  const percentWithdrawString = formatAsPercentage(withdrawValue, balance).toString();
  const percentWithdrawFormatted = percentWithdrawString
    .substring(0, percentWithdrawString.length - 1)
    .replace('.', '');
  const negativeWithdraw = 100 - parseFloat(percentWithdrawFormatted);

  const differencePercent = useMemo(() => {
    if (parseInt(percentWithdrawFormatted) > 100) {
      return 100;
    } else if (parseInt(percentWithdrawFormatted) < 0) {
      return 0;
    } else {
      return parseInt(percentWithdrawFormatted);
    }
  }, [percentWithdrawFormatted]);

  const totalPercent = useMemo(() => {
    if (negativeWithdraw > 100) {
      return 100;
    } else if (negativeWithdraw < 0) {
      return 0;
    } else {
      return negativeWithdraw;
    }
  }, [negativeWithdraw]);

  const feeValue = useMemo(() => {
    if (fee?.data !== undefined) {
      return utils.formatUnits(fee?.data as BigNumber, tokenDecimals?.data);
    }
    return '0.0000';
  }, [fee?.data]);

  return (
    <MealCard.Flow
      hasNoWithdrawValue={false}
      noVaultBalance={!isTransacting && !disabled && noVaultBalance}
      ref={ref}
      type="withdraw"
      isAmountHighlighted={maxWithdrawComputed !== '0.0'}
      convertedBalance={convertToBrl(convertFromOracle((vaultBalanceConvertedData?.data as never) || 0.0))}
      tokenBalance={(maxWithdrawComputed as never) === '0.0' ? '0.0000' : toFixed(maxWithdrawComputed as never, 4)}
      differencePercent={vaultId === id ? differencePercent : 0}
      totalPercent={vaultId === id ? totalPercent : 100}
      isYellow={false}
      isContracted={vaultId === id ? type !== TransactionType.WITHDRAW : true}
      isClosed={
        hasNoWithdrawBalance && isTransacting && type === TransactionType.WITHDRAW && vaultId === id
          ? false
          : hasNoWithdrawBalance
          ? true
          : vaultId === id && !!type && !!(type === TransactionType.DEPOSIT)
      }
      isTransacting={!!(type === TransactionType.WITHDRAW)}
      isError={
        !hasAmount ? true : vaultId === id && status === TransactionStatus.ERROR && type === TransactionType.WITHDRAW
      }
      isSuccess={vaultId === id && status === TransactionStatus.COMPLETE && !!(type === TransactionType.WITHDRAW)}
    >
      <MealCard.InputHeader
        steps={steps as never}
        selected={status}
        shortedIcon={!disabled && !isTransacting && noVaultBalance ? <div /> : <Icon name="arrow-up" />}
        shortedLabel={
          !disabled && noVaultBalance
            ? `${content.steps.header.noFeeShorted}`
            : `${content.steps.header.withdrawTitle} ${symbol}`
        }
        isPulsing={false}
        onClick={() => {
          if (!type) {
            dispatch(setVaultId(id));

            setTimeout(() => {
              handleSelectTab();
            }, 500);
          }
        }}
      />
      <div style={{ height: 138, overflow: 'hidden' }}>
        <AnimatePresence>
          {vaultId === id && (
            <motion.div
              initial={{
                opacity: 0,
                y: -100,
              }}
              animate={{
                opacity: 1,
                y: 0,
                transition: {
                  stiffness: 256,
                  damping: 24,
                  mass: 1,
                  type: 'spring',
                  duration: 0.5,
                },
              }}
              exit={{
                opacity: 0,
                y: -138,
                transition: {
                  stiffness: 256,
                  damping: 24,
                  mass: 1,
                  type: 'spring',
                  duration: 0.5,
                },
              }}
            >
              <WithdrawDisplay
                noBalance={!hasAmount}
                noVaultBalance={!disabled && noVaultBalance}
                value={withdrawValue}
                symbol={underlyingAssetSymbol}
                gas={
                  reviewScreenGasConvertedData?.data
                    ? convertToBrl(convertFromOracle(reviewScreenGasConvertedData?.data as never))
                    : 'R$ 0,00'
                }
                fee={feeValue}
                enterInWallet={reviewScreenValue || '0.0000'}
                realValue={
                  reviewScreenConvertedData?.data
                    ? convertToBrl(convertFromOracle(reviewScreenConvertedData?.data as never))
                    : 'R$ 0,00'
                }
              />
              <InputField
                isContracted={type !== TransactionType.WITHDRAW || !vaultId ? true : vaultId === id ? !type : true}
                withOverlay={type !== TransactionType.WITHDRAW || !vaultId ? true : vaultId === id ? !type : true}
                onOverlayClick={handleSelectTab}
                colors={[colors.primary, '']}
                btnColors={[additionalColors.color200, additionalColors.color100]}
                isInvalid={
                  !hasAmount
                    ? true
                    : vaultId === id && status === TransactionStatus.ERROR && type === TransactionType.WITHDRAW
                }
                isValid={
                  vaultId === id && status === TransactionStatus.COMPLETE && !!(type === TransactionType.WITHDRAW)
                }
                isDisabled={!disabled && noVaultBalance}
                isReadOnly={
                  type !== TransactionType.WITHDRAW ||
                  (type === TransactionType.WITHDRAW &&
                    status !== TransactionStatus.IDLE &&
                    status !== TransactionStatus.REVIEW_TRANSACTION)
                }
              >
                {status !== TransactionStatus.REVIEW_TRANSACTION && (
                  <>
                    <InputField.Icon url={inputIcon} />
                    <InputField.Control>
                      <InputField.Input
                        // @ts-ignore
                        ref={inputRef}
                        symbol={symbol}
                        value={type === TransactionType.WITHDRAW && vaultId === id ? withdrawValue : '0.0000'}
                        onChange={handleChange}
                      />
                      <InputField.Conversion
                        amount={
                          type === TransactionType.WITHDRAW
                            ? parseFloat(convertFromOracle(inputConvertedData.data as never))
                            : 0.0
                        }
                        onClick={() => {
                          if (!type) {
                            dispatch(setType(TransactionType.WITHDRAW));
                            setTimeout(() => {
                              inputRef.current?.focus();
                            }, 300);
                          } else {
                            inputRef.current?.focus();
                          }
                        }}
                      />
                    </InputField.Control>
                  </>
                )}
                {status === TransactionStatus.REVIEW_TRANSACTION && (
                  <InputField.ActionButton onClick={handleAction}>{actionLabel}</InputField.ActionButton>
                )}
                <InputField.RightContent mr={!showConfirmButton}>
                  {!(
                    status === TransactionStatus.WAIT_APPROVAL ||
                    status === TransactionStatus.WAIT_TRANSACTION ||
                    status === TransactionStatus.REVIEW_TRANSACTION ||
                    noVaultBalance
                  ) && <InputField.ActionButton onClick={handleAction}>{actionLabel}</InputField.ActionButton>}
                  {showConfirmButton && (
                    <CustomButton snakeSize="small" onClick={handleSubmit}>
                      {status === TransactionStatus.REVIEW_TRANSACTION
                        ? content.steps.header.withdrawTitleReview
                        : content.steps.header.withdrawTitle}
                    </CustomButton>
                  )}
                </InputField.RightContent>
              </InputField>
            </motion.div>
          )}
        </AnimatePresence>
      </div>
      {type !== TransactionType.WITHDRAW && !selected && (
        <div
          onClick={handleSelectTab}
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            borderRadius: 8,
            zIndex: 1,
            cursor: 'pointer',
          }}
        />
      )}
    </MealCard.Flow>
  );
};

export default Withdraw;
