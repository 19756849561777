import React from 'react';

import { createRoot } from 'react-dom/client';
import { Router } from './router';
import { WalletConnectConnector } from 'wagmi/connectors/walletConnect';
import { MetaMaskConnector } from 'wagmi/connectors/metaMask';
import { ApolloProvider } from '@apollo/client';
import { WagmiConfig, createClient, configureChains } from 'wagmi';
import { arbitrum } from 'wagmi/chains';
import { publicProvider } from 'wagmi/providers/public';
import { alchemyProvider } from 'wagmi/providers/alchemy';
import { apolloClient } from 'graphqlSource';
import ReactGA from 'react-ga4';
import { GlobalStyles } from '@munchiesmoney/bubble-gum';

ReactGA.initialize(process.env.GTM as string);

const metadata = {
  name: 'Munchies',
  description: 'Delicious Crypto Experiences',
  url: 'https://munchies.money',
  icons: ['icons/android-icon-48x48.png'],
};

const { provider, webSocketProvider } = configureChains(
  [arbitrum],
  [alchemyProvider({ apiKey: process.env.ALCHEMY_API_KEY || '' }), publicProvider()],
);

const client = createClient({
  connectors: [
    new MetaMaskConnector({
      chains: [arbitrum],
    }),
    new WalletConnectConnector({
      chains: [arbitrum],
      options: {
        showQrModal: false,
        projectId: process.env.PROJECT_ID || '',
        metadata,
      },
    }),
  ],
  autoConnect: true,
  provider,
  webSocketProvider,
});

const rootElement = document.getElementById('app');
const root = createRoot(rootElement || document.body);

root.render(
  <>
    <ApolloProvider client={apolloClient}>
      <WagmiConfig client={client}>
        <Router />
      </WagmiConfig>
    </ApolloProvider>
    <GlobalStyles />
  </>,
);
