import React from 'react';

import { useSelector } from 'react-redux';
import { Icon, Logo, SmallSections } from '@munchiesmoney/bubble-gum';

import { slideVariants, topVariants } from 'utils/variants';

import { RootState } from 'store';

import * as S from './WalletConnectors.styles';

import ChangeNetwork from 'components/ChangeNetwork';
import WalletConnectConnector from 'components/connectors/WalletConnect';
import MetamaskConnector from 'components/connectors/Metamask';
import { useWallet } from 'handlers/Wallet';

const WalletConnectors = () => {
  const { isChangeNetwork, isInitial, isMetamask, isWalletconnect } = useWallet();

  const { wallet } = useSelector((state: RootState) => state.locale.content);

  return (
    <S.Wrapper>
      <ChangeNetwork />
      <S.Content initial={{ height: 0 }} animate={!isChangeNetwork ? 'open' : 'closed'} variants={topVariants}>
        <S.Card
          animate={isInitial || isWalletconnect ? 'open' : 'closed'}
          variants={slideVariants}
          initial={{ width: 267, display: 'block' }}
        >
          <SmallSections
            left
            title={wallet.mobileLabel}
            titleIcon={<Icon name="mobile" height={16} width={10} />}
            walletIcon={<Logo name="wallet-connect" />}
          >
            <WalletConnectConnector />
          </SmallSections>
        </S.Card>
        <S.Card
          initial={{ width: 267, display: 'block' }}
          animate={isInitial || isMetamask ? 'open' : 'closed'}
          variants={slideVariants}
        >
          <SmallSections
            right
            title={wallet.desktopLabel}
            titleIcon={<Icon name="desktop" height={16} width={16} />}
            walletIcon={<Logo name="metamask" />}
          >
            <MetamaskConnector />
          </SmallSections>
        </S.Card>
      </S.Content>
    </S.Wrapper>
  );
};

export default WalletConnectors;
