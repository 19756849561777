import { useCallback, useEffect, useMemo, useState } from 'react';
import { useAccount, useConnect } from 'wagmi';
import { WalletDataProps } from 'handlers/Wallet/Wallet.types';
import { confirmConnection, setIsConnect } from 'modules/wallet/wallet.slice';
import { setSectionState } from 'modules/navigation/navigation.slice';
import { RootState, useDispatch } from 'store';
import { WalletTypes } from 'components/connectors/connectors.types';
import { useSelector } from 'react-redux';

const useMetamask = ({ type }: WalletDataProps) => {
  const { isConnected } = useAccount();
  const { error, isLoading, connect, connectors } = useConnect();
  const [reloadScreen, setReloadScreen] = useState(false);

  const dispatch = useDispatch();

  const isError = useMemo(() => error, [error]);
  const isLoadingMemo = useMemo(() => isLoading, [isLoading]);

  const [firstScreen, setFirstScreen] = useState(true);
  const [loadingScreen, setLoadingScreen] = useState(false);
  const [errorScreen, setErrorScreen] = useState(false);
  const [connectedScreen, setConnectedScreen] = useState(false);
  const { isConfirm: sendOk } = useSelector((state: RootState) => state.wallet);

  const setSendOk = useCallback(
    (value: boolean) => {
      dispatch(confirmConnection(value));
    },
    [dispatch],
  );

  useEffect(() => {
    dispatch(setIsConnect(isConnected));
  }, [isConnected]);

  useEffect(() => {
    if (type === WalletTypes.INITIAL) {
      setFirstScreen(true);
      setLoadingScreen(false);
      setErrorScreen(false);
      setConnectedScreen(false);
    }
    if (connectors[1]?.id === 'walletConnect' && isLoading) {
      setLoadingScreen(true);
      setFirstScreen(false);
      setErrorScreen(false);
      setConnectedScreen(false);
    }
  }, [type, isLoading]);

  useEffect(() => {
    if (connectors[1]?.id === 'walletConnect' && isError) {
      setErrorScreen(true);
      setLoadingScreen(false);
      setFirstScreen(false);
      setConnectedScreen(false);
    }
    if (connectors[1]?.id === 'walletConnect' && isConnected) {
      setConnectedScreen(true);
      setLoadingScreen(false);
      setErrorScreen(false);
      setFirstScreen(false);
    }
  }, [connectors[1], isConnected, dispatch, isError]);

  const onClose = useCallback(() => {
    dispatch(
      setSectionState({
        section: 'wallet',
        config: {
          pulse: false,
          collapsed: false,
        },
      }),
    );

    dispatch(
      setSectionState({
        section: 'meals',
        config: {
          pulse: false,
          disabled: false,
        },
      }),
    );
  }, [dispatch]);

  useEffect(() => {
    if (isConnected || sendOk) {
      onClose();
    }
  }, [sendOk]);

  return {
    isError,
    isLoading: isLoadingMemo,
    reloadScreen,
    setReloadScreen,
    connector: connectors[0],
    connect,
    onClose,
    firstScreen,
    loadingScreen,
    errorScreen,
    connectedScreen,
    setSendOk,
  };
};

export default useMetamask;
