import { createApi } from '@reduxjs/toolkit/query/react';
import { graphqlRequestBaseQuery } from '@rtk-query/graphql-request-base-query';

export const baseApiUrl = process.env.API_URL;

export const api = createApi({
  reducerPath: 'api',
  baseQuery: graphqlRequestBaseQuery({
    url: process.env.API_URL as string,
  }),
  endpoints: () => ({}),
});
