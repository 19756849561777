import React from 'react';

const PixLogo = () => {
  return (
    <svg width="92" height="29" viewBox="0 0 92 29" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M18.2176 20.7506C17.3012 20.7506 16.4393 20.3941 15.7914 19.747L12.2879 16.2472C12.042 16.0008 11.6132 16.0015 11.3674 16.2472L7.85114 19.7599C7.20307 20.4069 6.34121 20.7634 5.42474 20.7634H4.73438L9.17145 25.1961C10.5572 26.5805 12.804 26.5805 14.1898 25.1961L18.6397 20.7506H18.2176Z"
        fill="#937BFF"
      />
      <path
        d="M5.42463 8.39058C6.3411 8.39058 7.20296 8.74717 7.85092 9.39427L11.3671 12.9076C11.6204 13.1605 12.0339 13.1616 12.2878 12.9072L15.7911 9.407C16.4392 8.7599 17.3011 8.40342 18.2175 8.40342H18.6395L14.1897 3.95801C12.8039 2.57359 10.5571 2.57359 9.17134 3.95801L4.73438 8.39058H5.42463Z"
        fill="#937BFF"
      />
      <path
        d="M22.3185 12.0592L19.6294 9.37279C19.5702 9.3965 19.5061 9.41132 19.4385 9.41132H18.2159C17.5838 9.41132 16.9651 9.66737 16.5185 10.1138L13.015 13.6138C12.6873 13.9413 12.2565 14.1053 11.8261 14.1053C11.3953 14.1053 10.9649 13.9413 10.637 13.6142L7.12049 10.1011C6.6738 9.65464 6.05517 9.39859 5.42303 9.39859H3.91968C3.85564 9.39859 3.79565 9.38344 3.73907 9.36215L1.03934 12.0592C-0.346448 13.4435 -0.346448 15.6881 1.03934 17.0726L3.73896 19.7695C3.79554 19.7482 3.85564 19.7331 3.91968 19.7331H5.42303C6.05517 19.7331 6.6738 19.477 7.12049 19.0306L10.6367 15.5178C11.2723 14.8835 12.3801 14.8831 13.015 15.5182L16.5185 19.0177C16.9651 19.4643 17.5838 19.7204 18.2159 19.7204H19.4385C19.5063 19.7204 19.5702 19.7351 19.6295 19.7588L22.3185 17.0726C23.7043 15.6881 23.7043 13.4435 22.3185 12.0592Z"
        fill="#937BFF"
      />
      <path
        d="M42.4023 27.7539V10.8983C42.4023 7.79699 44.919 5.28294 48.0235 5.28294L53.0049 5.29028C56.1004 5.29646 58.6065 7.8051 58.6065 10.8975V14.4854C58.6065 17.5867 56.0898 20.101 52.9855 20.101H45.9461"
        stroke="#937BFF"
        strokeWidth="1.98964"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M60.0039 5.28384H62.1648C63.4389 5.28384 64.4715 6.31564 64.4715 7.58844V20.1797"
        stroke="#937BFF"
        strokeWidth="1.98964"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M63.9983 3.30607L63.0183 2.32696C62.7751 2.08415 62.7751 1.69024 63.0183 1.4473L63.9978 0.468962C64.2412 0.225633 64.636 0.225633 64.8794 0.468962L65.8589 1.4473C66.102 1.69024 66.102 2.08415 65.8589 2.32696L64.8789 3.30607C64.6357 3.54902 64.2414 3.54902 63.9983 3.30607Z"
        fill="#937BFF"
      />
      <path
        d="M68.6094 5.25057H70.7524C71.8548 5.25057 72.9121 5.68802 73.6915 6.46682L78.7041 11.4743C79.3534 12.1231 80.4061 12.1231 81.0554 11.4743L86.0497 6.48512C86.8291 5.70644 87.8864 5.26899 88.9888 5.26899H90.7311"
        stroke="#937BFF"
        strokeWidth="1.98964"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M68.6094 20.0508H70.7524C71.8548 20.0508 72.9121 19.6133 73.6915 18.8345L78.7041 13.8269C79.3534 13.1782 80.4061 13.1782 81.0554 13.8269L86.0497 18.8161C86.8291 19.5949 87.8864 20.0324 88.9888 20.0324H90.7311"
        stroke="#937BFF"
        strokeWidth="1.98964"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M46.6882 26.3035C46.4554 26.3035 46.1842 26.3594 45.9161 26.4211V27.4484C46.1019 27.5161 46.3139 27.5486 46.5174 27.5486C47.0329 27.5486 47.2775 27.3749 47.2775 26.9214C47.2775 26.4946 47.0771 26.3035 46.6882 26.3035ZM45.7422 28.2109V26.1797H45.8836L45.8985 26.2682C46.1371 26.2121 46.4671 26.1386 46.7028 26.1386C46.8945 26.1386 47.0771 26.1677 47.2303 26.2915C47.4072 26.4359 47.4631 26.6683 47.4631 26.9214C47.4631 27.1865 47.3747 27.4368 47.1332 27.575C46.9651 27.6691 46.7382 27.7074 46.5349 27.7074C46.3256 27.7074 46.1254 27.6748 45.9161 27.6132V28.2109H45.7422Z"
        fill="#937BFF"
      />
      <path
        d="M48.7515 26.3477C48.2358 26.3477 48.006 26.5097 48.006 26.963C48.006 27.4016 48.2329 27.599 48.7515 27.599C49.2642 27.599 49.4941 27.4399 49.4941 26.9865C49.4941 26.548 49.2673 26.3477 48.7515 26.3477ZM49.4144 27.599C49.2436 27.7224 49.0138 27.7578 48.7515 27.7578C48.4835 27.7578 48.2536 27.7194 48.0854 27.599C47.8969 27.4666 47.8203 27.2485 47.8203 26.9749C47.8203 26.704 47.8969 26.4835 48.0854 26.3477C48.2536 26.2271 48.4835 26.189 48.7515 26.189C49.0167 26.189 49.2436 26.2271 49.4144 26.3477C49.6061 26.4835 49.6796 26.704 49.6796 26.9718C49.6796 27.2457 49.603 27.4666 49.4144 27.599Z"
        fill="#937BFF"
      />
      <path
        d="M51.8548 27.6953L51.2801 26.4621H51.2683L50.7025 27.6953H50.5464L49.9336 26.2087H50.1249L50.6348 27.4566H50.6466L51.2006 26.2087H51.3597L51.9283 27.4566H51.9403L52.4383 26.2087H52.6238L52.0108 27.6953H51.8548Z"
        fill="#937BFF"
      />
      <path
        d="M53.7403 26.3447C53.2629 26.3447 53.1008 26.5566 53.0713 26.8629H54.4091C54.3945 26.5246 54.2206 26.3447 53.7403 26.3447ZM53.7345 27.7578C53.4485 27.7578 53.2629 27.7166 53.1155 27.5927C52.9416 27.4426 52.8828 27.225 52.8828 26.9749C52.8828 26.7363 52.9624 26.4833 53.1599 26.3389C53.3248 26.2242 53.5282 26.1889 53.7403 26.1889C53.9319 26.1889 54.1529 26.2093 54.3354 26.3331C54.5505 26.4772 54.5919 26.7305 54.5919 27.0188H53.0655C53.0713 27.325 53.1716 27.596 53.749 27.596C54.0232 27.596 54.2795 27.5516 54.5183 27.5105V27.6665C54.2706 27.7108 53.9966 27.7578 53.7345 27.7578Z"
        fill="#937BFF"
      />
      <path
        d="M55.0938 27.6953V26.2087H55.2352L55.25 26.2972C55.5652 26.2176 55.7127 26.1676 55.9895 26.1676H56.0103V26.3325H55.969C55.7361 26.3325 55.5948 26.3645 55.2677 26.4501V27.6953H55.0938Z"
        fill="#937BFF"
      />
      <path
        d="M57.0215 26.3447C56.5442 26.3447 56.3821 26.5566 56.3526 26.8629H57.6903C57.6758 26.5246 57.5018 26.3447 57.0215 26.3447ZM57.0157 27.7578C56.7297 27.7578 56.5442 27.7166 56.3968 27.5927C56.2229 27.4426 56.1641 27.225 56.1641 26.9749C56.1641 26.7363 56.2436 26.4833 56.4412 26.3389C56.6061 26.2242 56.8094 26.1889 57.0215 26.1889C57.2131 26.1889 57.4341 26.2093 57.6167 26.3331C57.8318 26.4772 57.8732 26.7305 57.8732 27.0188H56.3468C56.3526 27.325 56.4529 27.596 57.0303 27.596C57.3044 27.596 57.5607 27.5516 57.7995 27.5105V27.6665C57.5518 27.7108 57.2778 27.7578 57.0157 27.7578Z"
        fill="#937BFF"
      />
      <path
        d="M59.7736 26.448C59.588 26.3802 59.3758 26.3477 59.1726 26.3477C58.6568 26.3477 58.4122 26.5216 58.4122 26.9749C58.4122 27.4045 58.6126 27.5928 59.0015 27.5928C59.2344 27.5928 59.5055 27.5369 59.7736 27.478V26.448ZM59.8061 27.7167L59.7912 27.6282C59.5527 27.6841 59.2226 27.7578 58.9869 27.7578C58.7954 27.7578 58.6126 27.7314 58.4594 27.6047C58.2827 27.4603 58.2266 27.228 58.2266 26.9749C58.2266 26.71 58.315 26.4595 58.5566 26.3241C58.7247 26.2271 58.9515 26.1889 59.1578 26.1889C59.3641 26.1889 59.5643 26.2243 59.7736 26.283V25.603H59.9475V27.7167H59.8061Z"
        fill="#937BFF"
      />
      <path
        d="M62.4929 26.3539C62.26 26.3539 61.9889 26.4098 61.7208 26.4715V27.4958C61.9095 27.5665 62.1186 27.599 62.3221 27.599C62.8376 27.599 63.0822 27.4253 63.0822 26.9718C63.0822 26.545 62.8818 26.3539 62.4929 26.3539ZM62.9377 27.6254C62.7697 27.7196 62.5429 27.7578 62.3396 27.7578C62.1186 27.7578 61.8977 27.7196 61.6736 27.6459L61.6649 27.7167H61.5469V25.6032H61.7208V26.3124C61.9595 26.2597 62.2807 26.189 62.5075 26.189C62.6992 26.189 62.8818 26.2181 63.035 26.3419C63.2119 26.4863 63.2678 26.7187 63.2678 26.9718C63.2678 27.2369 63.1793 27.4872 62.9377 27.6254Z"
        fill="#937BFF"
      />
      <path
        d="M63.504 28.2586V28.097C63.5894 28.1057 63.6691 28.1118 63.7251 28.1118C63.9402 28.1118 64.0697 28.0497 64.1906 27.8053L64.2465 27.6877L63.4688 26.2011H63.6691L64.3351 27.4845H64.3468L64.9803 26.2011H65.1777L64.3409 27.8703C64.1877 28.1735 64.0225 28.2734 63.719 28.2734C63.6513 28.2734 63.5777 28.2676 63.504 28.2586Z"
        fill="#937BFF"
      />
      <path
        d="M67.5753 26.8475H66.9978V27.3686H67.5781C67.9759 27.3686 68.1261 27.3242 68.1261 27.1066C68.1261 26.8737 67.9201 26.8475 67.5753 26.8475ZM67.4692 26.0024H66.9978V26.5324H67.4721C67.8641 26.5324 68.0201 26.4854 68.0201 26.2646C68.0201 26.0292 67.8227 26.0024 67.4692 26.0024ZM68.3651 27.5541C68.1528 27.6895 67.8965 27.6953 67.4278 27.6953H66.5469V25.6789H67.4073C67.811 25.6789 68.0584 25.6847 68.2647 25.8085C68.4121 25.8968 68.471 26.0322 68.471 26.2087C68.471 26.4206 68.3827 26.5619 68.1528 26.6562V26.6676C68.4121 26.7269 68.5801 26.8594 68.5801 27.1449C68.5801 27.339 68.5093 27.4658 68.3651 27.5541Z"
        fill="#937BFF"
      />
      <path
        d="M70.3388 27.0926C70.1649 27.0778 69.9882 27.069 69.8026 27.069C69.4991 27.069 69.3929 27.1309 69.3929 27.2691C69.3929 27.3987 69.4814 27.4694 69.714 27.4694C69.9086 27.4694 70.1414 27.4253 70.3388 27.3809V27.0926ZM70.4272 27.7167L70.4154 27.6282C70.1621 27.6903 69.8674 27.7578 69.608 27.7578C69.4489 27.7578 69.2779 27.7372 69.1571 27.6487C69.0453 27.5694 68.9922 27.4398 68.9922 27.2896C68.9922 27.1219 69.0659 26.966 69.2426 26.8865C69.3989 26.8128 69.608 26.807 69.7996 26.807C69.9558 26.807 70.1649 26.8158 70.3388 26.8277V26.801C70.3388 26.5686 70.1855 26.4921 69.7671 26.4921C69.6052 26.4921 69.4076 26.5006 69.2191 26.5184V26.2181C69.4283 26.2007 69.664 26.1889 69.8585 26.1889C70.1178 26.1889 70.386 26.2095 70.5509 26.3271C70.7218 26.448 70.7543 26.6159 70.7543 26.8364V27.7167H70.4272Z"
        fill="#937BFF"
      />
      <path
        d="M72.7014 27.6953V26.8737C72.7014 26.6031 72.563 26.5061 72.3153 26.5061C72.1328 26.5061 71.903 26.553 71.7084 26.6003V27.6953H71.293V26.2087H71.6318L71.6464 26.3027C71.9088 26.2353 72.2004 26.1676 72.4422 26.1676C72.6248 26.1676 72.8105 26.1939 72.9519 26.3205C73.0696 26.4266 73.114 26.5735 73.114 26.7856V27.6953H72.7014Z"
        fill="#937BFF"
      />
      <path
        d="M74.2859 27.7578C74.0944 27.7578 73.885 27.7314 73.7318 27.6018C73.5492 27.4546 73.4961 27.2222 73.4961 26.9717C73.4961 26.7363 73.5727 26.4801 73.7996 26.3361C73.9852 26.2151 74.2151 26.1889 74.4539 26.1889C74.6247 26.1889 74.7927 26.2007 74.9783 26.2181V26.5361C74.8279 26.5216 74.6482 26.5097 74.5039 26.5097C74.1089 26.5097 73.9233 26.6334 73.9233 26.9749C73.9233 27.2958 74.0619 27.434 74.3859 27.434C74.5744 27.434 74.7955 27.3987 75.0108 27.3576V27.6636C74.7779 27.7109 74.5245 27.7578 74.2859 27.7578Z"
        fill="#937BFF"
      />
      <path
        d="M76.2343 26.5006C75.8394 26.5006 75.6655 26.6245 75.6655 26.963C75.6655 27.3016 75.8364 27.446 76.2343 27.446C76.6262 27.446 76.797 27.325 76.797 26.9863C76.797 26.648 76.6293 26.5006 76.2343 26.5006ZM76.9474 27.6018C76.7647 27.7252 76.5261 27.7578 76.2343 27.7578C75.9366 27.7578 75.6981 27.7223 75.5183 27.6018C75.312 27.4664 75.2383 27.2427 75.2383 26.9749C75.2383 26.7068 75.312 26.4801 75.5183 26.3447C75.6981 26.2242 75.9366 26.1889 76.2343 26.1889C76.529 26.1889 76.7647 26.2242 76.9474 26.3447C77.1536 26.4801 77.2245 26.7068 77.2245 26.9717C77.2245 27.2397 77.1507 27.4664 76.9474 27.6018Z"
        fill="#937BFF"
      />
      <path
        d="M79.6221 27.7578C79.3718 27.7578 79.1006 27.7167 78.8972 27.5487C78.6557 27.3484 78.582 27.0395 78.582 26.7068C78.582 26.4097 78.6763 26.0564 78.9914 25.8502C79.236 25.6915 79.5395 25.6591 79.846 25.6591C80.0701 25.6591 80.2998 25.6737 80.5504 25.6944V26.0564C80.3352 26.0387 80.0701 26.0239 79.8638 26.0239C79.2891 26.0239 79.0445 26.2418 79.0445 26.7068C79.0445 27.181 79.2715 27.3929 79.6958 27.3929C79.9726 27.3929 80.2821 27.3369 80.5945 27.2721V27.6312C80.2821 27.6931 79.955 27.7578 79.6221 27.7578Z"
        fill="#937BFF"
      />
      <path
        d="M81.814 26.4537C81.4692 26.4537 81.3366 26.5776 81.31 26.8042H82.312C82.3001 26.5628 82.1587 26.4537 81.814 26.4537ZM81.7521 27.7578C81.5075 27.7578 81.2865 27.7284 81.1215 27.5928C80.9448 27.446 80.8828 27.225 80.8828 26.9717C80.8828 26.7451 80.9566 26.4921 81.1629 26.3447C81.3454 26.2151 81.5783 26.1889 81.814 26.1889C82.0261 26.1889 82.2767 26.2123 82.4592 26.3389C82.698 26.5068 82.7187 26.766 82.7216 27.072H81.3072C81.3161 27.2987 81.4369 27.446 81.8551 27.446C82.1146 27.446 82.4033 27.4077 82.6479 27.3693V27.6607C82.362 27.708 82.0497 27.7578 81.7521 27.7578Z"
        fill="#937BFF"
      />
      <path
        d="M84.5725 27.6953V26.8737C84.5725 26.6031 84.4341 26.5061 84.1864 26.5061C84.0038 26.5061 83.7741 26.553 83.5795 26.6003V27.6953H83.1641V26.2087H83.5029L83.5176 26.3027C83.7799 26.2353 84.0715 26.1676 84.3133 26.1676C84.4959 26.1676 84.6816 26.1939 84.823 26.3205C84.9409 26.4266 84.9851 26.5735 84.9851 26.7856V27.6953H84.5725Z"
        fill="#937BFF"
      />
      <path
        d="M86.1663 27.7578C85.966 27.7578 85.7831 27.7018 85.6831 27.5458C85.6095 27.4398 85.574 27.2958 85.574 27.0955V26.539H85.2734V26.2301H85.574L85.6184 25.7796H85.9866V26.2301H86.5729V26.539H85.9866V27.0161C85.9866 27.1309 85.9954 27.228 86.0277 27.2987C86.0721 27.3987 86.1692 27.4369 86.2989 27.4369C86.3961 27.4369 86.5141 27.4221 86.5964 27.4077V27.7047C86.461 27.7314 86.3047 27.7578 86.1663 27.7578Z"
        fill="#937BFF"
      />
      <path
        d="M86.9805 27.6953V26.2087H87.3193L87.3342 26.3027C87.6083 26.2263 87.8114 26.1676 88.0708 26.1676C88.0825 26.1676 88.1003 26.1676 88.1238 26.1703V26.5236C88.0766 26.5208 88.0206 26.5208 87.9795 26.5208C87.776 26.5208 87.6228 26.5442 87.3959 26.5974V27.6953H86.9805Z"
        fill="#937BFF"
      />
      <path
        d="M89.6318 27.0926C89.4579 27.0778 89.2812 27.069 89.0954 27.069C88.792 27.069 88.6858 27.1309 88.6858 27.2691C88.6858 27.3987 88.7744 27.4694 89.007 27.4694C89.2015 27.4694 89.4344 27.4253 89.6318 27.3809V27.0926ZM89.7201 27.7167L89.7084 27.6282C89.4549 27.6903 89.1603 27.7578 88.901 27.7578C88.7419 27.7578 88.5709 27.7372 88.4501 27.6487C88.3383 27.5694 88.2852 27.4398 88.2852 27.2896C88.2852 27.1219 88.3588 26.966 88.5356 26.8865C88.6917 26.8128 88.901 26.807 89.0926 26.807C89.2486 26.807 89.4579 26.8158 89.6318 26.8277V26.801C89.6318 26.5686 89.4785 26.4921 89.0601 26.4921C88.898 26.4921 88.7006 26.5006 88.512 26.5184V26.2181C88.7212 26.2007 88.9569 26.1889 89.1515 26.1889C89.4107 26.1889 89.679 26.2095 89.8439 26.3271C90.0148 26.448 90.0472 26.6159 90.0472 26.8364V27.7167H89.7201Z"
        fill="#937BFF"
      />
      <path d="M90.5859 25.5817H91.0014V27.6953H90.5859V25.5817Z" fill="#937BFF" />
    </svg>
  );
};

export default PixLogo;
