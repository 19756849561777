import React, { useEffect, useMemo } from 'react';

import { useAccount } from 'wagmi';
import { useSelector } from 'react-redux';
import { useAnimationControls } from 'framer-motion';
import {
  BarAvailableLimit,
  BarBalance,
  BarHealthBig,
  DefaultButton,
  Header,
  Icon,
  SectionWrapper,
  theme,
} from '@munchiesmoney/bubble-gum';

import LimitValues from 'components/LimitValues';

import { RootState } from 'store';

import * as S from './Credit.styles';

import mouthImage from 'assets/imgs/mouths/02.png';
import cardImage from 'assets/imgs/cards/01.png';
import shadowImage from 'assets/imgs/shadow.png';

const Credit = () => {
  const { terms, wallet } = useSelector((state: RootState) => state.navigation);

  const { credit: content } = useSelector((state: RootState) => state.locale.content);

  const { isConnected } = useAccount();

  const contentControls = useAnimationControls();
  const headerControls = useAnimationControls();
  const containerControls = useAnimationControls();
  const animationControls = useAnimationControls();

  const resizeState = useMemo(() => {
    if (!terms.closed) {
      return 0;
    }

    if (!isConnected || (isConnected && wallet.pulse)) {
      return 1;
    }

    return 2;
  }, [terms, wallet, isConnected]);

  useEffect(() => {
    switch (resizeState) {
      case 1:
        setTimeout(() => {
          contentControls.start({
            height: 348,
          });

          headerControls.start({
            height: 86,
          });

          containerControls.start({
            height: 268,
            paddingTop: '58px',
          });

          animationControls.start({
            y: -91,
            scale: 1,
          });
        }, 500);
        break;
      case 2:
        setTimeout(() => {
          contentControls.start({
            height: 226,
          });

          headerControls.start({
            height: 57,
          });

          containerControls.start({
            height: 173,
            paddingTop: '34px',
          });

          animationControls.start({
            y: -144,
            scale: 0.6,
          });
        }, 500);
        break;
      default:
        setTimeout(() => {
          contentControls.start({
            height: 'auto',
          });

          headerControls.start({
            height: 'auto',
          });

          containerControls.start({
            height: 352,
            paddingTop: '93px',
          });

          animationControls.start({
            y: 0,
            scale: 1,
          });
        }, 500);
        break;
    }
  }, [resizeState, headerControls, containerControls, contentControls, animationControls]);

  const sectionHeight = useMemo(() => {
    if (terms.pulse && !terms.closed) {
      return 868;
    }
    if (!isConnected || (isConnected && wallet.pulse)) {
      return 484;
    }
    return 366;
  }, [terms, wallet, isConnected]);

  return (
    <SectionWrapper
      height={sectionHeight}
      headerComponent={
        <Header
          title={content.title}
          icon={<Icon name="money-bag" />}
          bgColor={['#56459A', theme.colors.purple[600]]}
          rightContent={
            <S.Custom>
              <DefaultButton
                onClick={() => {
                  window.open('https://docs.munchies.money', '_blank');
                }}
                bgColor={theme.colors.purple[400]}
                shadowColor={theme.colors.purple[600]}
                padding="7px 12px"
              >
                <Icon name="interrogation" />
              </DefaultButton>
            </S.Custom>
          }
        >
          <LimitValues labels={[content.total]} values={['R$ 0,00']} />
        </Header>
      }
      withBody
      isDisabled
    >
      <S.Content layout animate={contentControls}>
        <S.LimitHeader resizeState={resizeState} layout>
          <S.LimitCard animate={headerControls}>
            <S.LimitLabel>
              <span>{content.limitLabel}</span>
              <span data-percentage>0%</span>
            </S.LimitLabel>
            <S.LimitValue>R$ 0,00</S.LimitValue>
            <S.LimitButton data-left>
              <Icon name="arrow-up" />
              <span>{content.emitLabel}</span>
            </S.LimitButton>
          </S.LimitCard>
          <S.LimitProgress>
            <BarAvailableLimit spaceColor="#473773" percent={48.5} />
          </S.LimitProgress>
          <S.LimitCard animate={headerControls}>
            <S.LimitLabel>
              <span data-percentage>0%</span>
              <span>{content.limitUsedLabel}</span>
            </S.LimitLabel>
            <S.LimitValue>R$ 0,00</S.LimitValue>
            <S.LimitButton data-left>
              <Icon name="arrow-down" />
              <span>{content.return}</span>
            </S.LimitButton>
          </S.LimitCard>
        </S.LimitHeader>
        <S.HealthContainer layout animate={containerControls}>
          <S.HealthValue>0% {content.health}</S.HealthValue>
          <S.HealthLabel>{content.waitingForIssue}</S.HealthLabel>
          {resizeState !== 2 && <BarHealthBig percent={100} />}
          {resizeState === 0 && <S.HealthDescription>{content.attention}</S.HealthDescription>}
          <S.MouthAnimation animate={animationControls}>
            <img src={mouthImage} alt="Munchies Money - Mouth" />
            <img src={cardImage} alt="Munchies Money - Card" />
            <img src={shadowImage} alt="Munchies Money - Shadow" />
          </S.MouthAnimation>
        </S.HealthContainer>
        <S.HealthFooter>
          <S.HealthFooterDistribution>
            <div>
              <Icon name="mini-pizza" />
              <span>0%</span>
              <Icon name="usdc" width={19} height={19} />
              <span>USDC</span>
            </div>
            <div>
              <Icon name="mini-burger" />
              <span>0%</span>
              <Icon name="eth" />
              <span>ETH</span>
            </div>
            <div>
              <Icon name="mini-brigadeiro" />
              <span>0%</span>
              <Icon name="wbtc" />
              <span>WBTC</span>
            </div>
          </S.HealthFooterDistribution>
          <BarBalance eth={33.3} usdc={33.3} wbtc={33.3} />
        </S.HealthFooter>
      </S.Content>
    </SectionWrapper>
  );
};

export default Credit;
