import { createSlice } from '@reduxjs/toolkit';

import { FocusInitialState } from './focus.types';

const initialState = {
  nodeId: null,

  whitelist: [],
  history: [],

  isCloseable: true,
} as FocusInitialState;

const slice = createSlice({
  name: 'focus',
  initialState,
  reducers: {},
});

export const focusReducer = slice.reducer;
