import React from 'react';

import { Outlet } from 'react-router-dom';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { ThemeProvider } from 'styled-components';
import { theme } from '@munchiesmoney/bubble-gum';

import { Scrollbars } from 'react-custom-scrollbars-2';

import { persistor, store } from 'store';

const Root = () => {
  return (
    <Scrollbars style={{ height: '100vh' }} autoHide autoHideTimeout={1000} universal>
      <ThemeProvider theme={theme}>
        <Provider store={store}>
          <PersistGate loading={null} persistor={persistor}>
            <Outlet />
          </PersistGate>
        </Provider>
      </ThemeProvider>
    </Scrollbars>
  );
};

export default Root;
