export enum TransactionType {
  WITHDRAW = 'WITHDRAW',
  DEPOSIT = 'DEPOSIT',
}

export enum TransactionStatus {
  IDLE = 'IDLE',
  WAIT_ACCEPT = 'WAIT_ACCEPT',
  WAIT_APPROVAL_ACCEPT = 'WAIT_APPROVAL_ACCEPT',
  WAIT_APPROVAL = 'WAIT_APPROVAL',
  REVIEW_TRANSACTION = 'REVIEW_TRANSACTION',
  WAIT_TRANSACTION = 'WAIT_TRANSACTION',
  ERROR = 'ERROR',
  COMPLETE = 'COMPLETE',
}

type Amount = {
  id: string | number;
  value: string;
};

export interface TransactionInitialState {
  isTransacting: boolean;

  vaultId: string | null;
  hash: string | null;

  type: TransactionType | null;

  amounts: Amount[];

  status: TransactionStatus;

  // TODO: ALTERAR QUANDO TIVER
  result: any;
}
