import styled, { css } from 'styled-components';
import { motion } from 'framer-motion';

import { WalletTypes } from 'components/connectors/connectors.types';
import { WalletConnectorsProps } from './WalletConnectors.types';

export const Wrapper = styled.div<WalletConnectorsProps>`
  ${({ type }) => css`
    position: relative;

    width: 100%;
    max-height: 350px;

    overflow: hidden;

    transition: 0.3s;

    gap: 9px;

    > div {
      display: flex;
      position: relative;
      gap: ${type !== WalletTypes.INITIAL && type !== WalletTypes.CHANGE_NETWORK ? '0' : '8px'};
      transition: 0.3s;
      overflow: hidden;
      max-height: 350px;

      > div {
        width: ${type !== WalletTypes.INITIAL && type !== WalletTypes.CHANGE_NETWORK ? '100%' : '50%'};
        max-width: ${type !== WalletTypes.INITIAL && type !== WalletTypes.CHANGE_NETWORK ? '100%' : '267px'};
      }

      section {
        > div:last-child > div {
          position: relative;
          min-height: 240px;
        }
      }
    }
  `}
`;

export const Card = styled(motion.div)`
  overflow: hidden;

  > section {
    overflow: hidden;
    height: 320px;
    width: 100%;
  }
`;

export const Content = styled(motion.div)`
  display: flex;
  overflow: hidden;
  width: 100%;

  ${Card} + ${Card} {
    margin-left: 7px;
  }
`;
