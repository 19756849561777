import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { NavigationInitialState, SectionName, SetSectionStatePayload } from './navigation.types';

const initialState = {
  terms: {
    collapsed: false,
    disabled: false,
    closed: false,
    pulse: true,
    accepts: [false, false, false],
  },
  wallet: {
    collapsed: true,
    disabled: true,
    pulse: false,
    closed: true,
  },
  meals: {
    collapsed: true,
    disabled: true,
    pulse: false,
    closed: false,
  },
  card: {
    collapsed: false,
    disabled: true,
    pulse: false,
    closed: false,
  },
} as NavigationInitialState;

const navigationSlice = createSlice({
  name: 'navigation',
  initialState,
  reducers: {
    setSectionState(state, action: PayloadAction<SetSectionStatePayload>) {
      const { section, config } = action.payload;

      state[section] = Object.assign(state[section], config) as never;
    },
    closeAllSectionsAndConfig(state, action: PayloadAction<SetSectionStatePayload>) {
      const { section, config } = action.payload;

      const sections = Object.keys(initialState) as SectionName[];
      const sectionIndex = sections.indexOf(section);

      sections.splice(sectionIndex, 1);

      for (const s of sections) {
        state[s].closed = true;
        state[s].disabled = true;
        state[s].pulse = false;
      }

      state[section] = Object.assign(state[section], config) as never;
    },
    toggleAcceptTerms(state, { payload: index }: PayloadAction<number>) {
      state.terms.accepts[index] = !state.terms.accepts[index];
    },
  },
});

export const { toggleAcceptTerms, setSectionState, closeAllSectionsAndConfig } = navigationSlice.actions;

export const navigationReducer = navigationSlice.reducer;
