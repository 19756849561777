import React from 'react';

import { AnimatePresence, motion } from 'framer-motion';
import { useSelector } from 'react-redux';
import { ControllerField, CustomButton, Icon, theme } from '@munchiesmoney/bubble-gum';

import { RootState } from 'store';

import useWalletconnect from 'hooks/useWalletconnect';

import { handlePurify } from 'utils/dom';
import { opacityVariants } from 'utils/variants';

import * as S from '../connectors.styles';

import CustomQRCode from 'components/CustomQRCode';

import confirmImg from 'assets/imgs/walletconnect.png';
import errorImg from 'assets/imgs/walletconnecterror.png';

import { useWallet } from 'handlers/Wallet';
import { WalletTypes } from 'components/connectors/connectors.types';

const WalletConnectConnector = () => {
  const { wallet } = useSelector((state: RootState) => state.locale.content);

  const { isConnected } = useSelector((state: RootState) => state.wallet);

  const { type, setType } = useWallet();

  const { uri, qrCode, fetchQrcode, health, firstScreen, errorScreen, connectedScreen, setSendOk } = useWalletconnect({
    type,
    setType,
  });

  return (
    <AnimatePresence>
      {!isConnected && (
        <S.ScreensWrapper>
          <motion.div
            initial={{ display: 'none' }}
            animate={errorScreen && type !== WalletTypes.INITIAL ? 'open' : 'closed'}
            variants={opacityVariants}
            key={'walletconnect_error'}
          >
            <S.Error type="walletconnect">
              <div>
                <S.Icon>
                  <Icon name="close" color={theme.colors.red[500]} />
                </S.Icon>
                <p dangerouslySetInnerHTML={{ __html: handlePurify(wallet.walletconnect.error.message) }} />
                <img src={errorImg} alt="" />
              </div>
              <ControllerField
                bgColor={'rgba(0,0,0,0.07)'}
                buttons={[
                  {
                    label: wallet.walletconnect.error.alertButtonBack,
                    onClick: () => {
                      setType(WalletTypes.INITIAL);
                    },
                  },
                ]}
                type="tertiary"
              >
                {wallet.walletconnect.error.alert}
              </ControllerField>
            </S.Error>
          </motion.div>
          <motion.div
            initial={{ display: 'none' }}
            animate={firstScreen || type === WalletTypes.INITIAL ? 'open' : 'closed'}
            variants={opacityVariants}
            key={'walletconnect_initial'}
          >
            <S.Connector style={{ overflow: 'hidden', width: '220px' }}>
              <S.Qrcode qrCode={qrCode}>
                <CustomQRCode value={uri || 'https://munchies.money'} percent={health} />
              </S.Qrcode>
              <motion.div initial={{ display: 'none' }} animate={qrCode ? 'open' : 'closed'} variants={opacityVariants}>
                <S.UpdateQrcode>
                  <p>
                    <Icon name="alert" color={theme.colors.walletConnect[300]} />{' '}
                    {wallet.walletconnect.qrcode.description}
                  </p>
                  <CustomButton
                    colorScheme={'walletconnect'}
                    onClick={() => {
                      fetchQrcode();
                      setType(WalletTypes.INITIAL);
                    }}
                  >
                    {wallet.walletconnect.qrcode.buttonText}
                  </CustomButton>
                </S.UpdateQrcode>
              </motion.div>
            </S.Connector>
          </motion.div>
        </S.ScreensWrapper>
      )}
      <motion.div
        initial={{ display: 'none' }}
        animate={connectedScreen ? 'open' : 'closed'}
        variants={opacityVariants}
        key={'walletconnect_connected'}
      >
        <S.Connected style={{ overflow: 'hidden', width: '485px' }}>
          <div>
            <S.Icon>
              <Icon name="check" color={theme.colors.purple[100]} />
            </S.Icon>
            <p
              dangerouslySetInnerHTML={{
                __html: handlePurify(wallet.walletconnect.connected.message),
              }}
            />
            <img src={confirmImg} alt="" />
          </div>
          <ControllerField
            bgColor={'rgba(0,0,0,0.07)'}
            buttons={[
              {
                label: wallet.walletconnect.connected.alertButton,
                onClick: () => {
                  setSendOk(true);
                  fetchQrcode();
                },
              },
            ]}
            type="secondary"
          >
            {wallet.walletconnect.connected.alert}
          </ControllerField>
        </S.Connected>
      </motion.div>
    </AnimatePresence>
  );
};

export default WalletConnectConnector;
