import { ApolloClient, DefaultOptions, InMemoryCache } from '@apollo/client';

const defaultOptions: DefaultOptions = {
  watchQuery: {
    fetchPolicy: 'no-cache',
    // fetchPolicy: 'cache-first',
    errorPolicy: 'ignore',
  },
  query: {
    fetchPolicy: 'no-cache',
    // fetchPolicy: 'cache-first',
    errorPolicy: 'all',
  },
};

export const apolloClient = new ApolloClient({
  uri: process.env.API_URL,
  cache: new InMemoryCache(),
  defaultOptions: defaultOptions,
});
