import React from 'react';
import { QrCodeGenerator } from './QrCode';
import * as S from './styles';
import purify from 'dompurify';
import { BarHealthSmall } from '@munchiesmoney/bubble-gum';
import loaderImg from 'assets/imgs/walletconnectloader.png';
import { QrCodeGeneratorProps } from './types';
import { opacityVariants } from 'utils/variants';

function CustomQRCode({ value, size = 218, themeMode = 'dark', percent }: QrCodeGeneratorProps) {
  const qrcode = QrCodeGenerator.generate(value, size, size / 4, themeMode);

  return (
    <>
      <S.Svg
        initial={{ display: 'none' }}
        animate={value !== 'https://munchies.money' ? 'open' : 'closed'}
        variants={opacityVariants}
        width={220}
        height={220}
        dangerouslySetInnerHTML={{ __html: purify.sanitize(qrcode) }}
      />
      <S.QrcodeLoader>
        <img src={loaderImg} alt="Loading" />
        <BarHealthSmall percent={percent} percentColor="rgba(255,166,217,0.2)" />
      </S.QrcodeLoader>
    </>
  );
}

export default CustomQRCode;
