import React, { useEffect } from 'react';
import { useVaultBlock } from 'components/Vault';
import { useSelector } from 'react-redux';
import { RootState } from 'store';
import purify from 'dompurify';
import * as S from './DepositDisplay.styles';
import { TransactionStatus, TransactionType } from 'modules/transaction/transaction.types';
import { DotLottiePlayer } from '@dotlottie/react-player';
import { Icon } from '@munchiesmoney/bubble-gum';
import { AnimatePresence, useAnimationControls } from 'framer-motion';
import { useTransactionGas } from 'hooks/use-transaction-gas';

const slideTransitionConfig = {
  type: 'spring',
  stiffness: 256,
  damping: 24,
  mass: 1,
  duration: 0.5,
};

type Props = {
  noBalance: boolean;
  symbol: string;
  value: string;
  fee: string;
  gas: string;
  ltMin: boolean;
  enterInWallet: string;
  realValue: string;
  minimumDeposit: string;
  zeroBalance: boolean;
  transactionHash?: `0x${string}`;
  showReceipt: boolean;
};

const DepositDisplay = ({
  noBalance = false,
  ltMin,
  symbol,
  value,
  fee,
  gas,
  enterInWallet,
  realValue,
  zeroBalance,
  showReceipt,
  transactionHash,
  minimumDeposit,
}: Props) => {
  const { animations, assetAddress, contractAddress, additionalContractAddresses, hasNoWithdrawBalance } =
    useVaultBlock();

  const { type, status } = useSelector((state: RootState) => state.transaction);
  const { deposit } = useSelector((state: RootState) => state.locale.content);

  const receiptControls = useAnimationControls();

  const { cost, deposited } = useTransactionGas({
    hash: transactionHash,
    tokenAddress: assetAddress,
    contractAddress: contractAddress,
    address: additionalContractAddresses.priceFeed,
    enabled: showReceipt,
  });

  useEffect(() => {
    receiptControls.start({
      height: showReceipt ? 169 : 91,
      transition: slideTransitionConfig,
    });
  }, [showReceipt]);

  if (zeroBalance && status !== TransactionStatus.COMPLETE) {
    return (
      <S.Wrapper>
        <S.IdleDescription
          aria-disabled="true"
          dangerouslySetInnerHTML={{
            __html: purify.sanitize(deposit.steps.content.getTokens(symbol)),
          }}
        />
      </S.Wrapper>
    );
  }

  if (type === TransactionType.WITHDRAW) {
    return (
      <S.Wrapper>
        <S.IdleDescription
          dangerouslySetInnerHTML={{
            __html: purify.sanitize(deposit.steps.resumeTransferTitle),
          }}
        />
      </S.Wrapper>
    );
  }

  if (noBalance && status !== TransactionStatus.COMPLETE) {
    return (
      <S.Wrapper>
        <S.BigText
          isRed
          dangerouslySetInnerHTML={{
            __html: purify.sanitize(deposit.steps.content.noBalance),
          }}
        />
        <DotLottiePlayer src={animations.supply.error} autoplay loop />
      </S.Wrapper>
    );
  }

  if (ltMin && status !== TransactionStatus.COMPLETE) {
    return (
      <S.Wrapper style={{ alignItems: 'flex-end', paddingBottom: 20 }}>
        <S.BigText
          isRed
          dangerouslySetInnerHTML={{
            __html: purify.sanitize(deposit.steps.content.ltMin),
          }}
        />
        <S.IdleDescriptionMinimum style={{ color: 'rgba(255, 114, 114, 1)', opacity: 1, paddingBottom: 2 }}>
          <span style={{ opacity: 0.7 }}>{deposit.steps.content.min}</span>{' '}
          <b>
            {symbol} {minimumDeposit}
          </b>
        </S.IdleDescriptionMinimum>
      </S.Wrapper>
    );
  }

  if (status === TransactionStatus.COMPLETE) {
    return (
      <S.ReceiptWrapper animate={receiptControls}>
        <S.Wrapper>
          <S.BigText
            isLargeWidth
            isGreen
            dangerouslySetInnerHTML={{
              __html: purify.sanitize(deposit.steps.content.done),
            }}
          />
          <DotLottiePlayer
            src={animations.supply.done}
            style={{ maxWidth: '86px', maxHeight: '91px', marginRight: '16px' }}
            autoplay
            loop
          />
        </S.Wrapper>
        <AnimatePresence>
          {showReceipt && (
            <S.Receipt
              initial={{ opacity: 0 }}
              animate={{ opacity: 1, transition: slideTransitionConfig }}
              exit={{ opacity: 0, transition: slideTransitionConfig }}
            >
              <div>
                <h5>Custo transação</h5>
                <p>ETH {cost.formatted}</p>
                <span>{cost.converted}</span>
              </div>
              <div>
                <h5>Total debitado</h5>
                <p>
                  {symbol} {deposited.formatted}
                </p>
                <span>{deposited.converted}</span>
              </div>
            </S.Receipt>
          )}
        </AnimatePresence>
      </S.ReceiptWrapper>
    );
  }

  return (
    <S.Wrapper>
      {status === TransactionStatus.IDLE && (
        <>
          <S.IdleDescription
            dangerouslySetInnerHTML={{
              __html: purify.sanitize(
                deposit.steps[type === null && !hasNoWithdrawBalance ? 'resumeTransferTitle' : 'transferTitle'],
              ),
            }}
          />
          {((!type && hasNoWithdrawBalance) || type === TransactionType.DEPOSIT) && (
            <DotLottiePlayer
              src={animations.supply.initial}
              style={{ flexShrink: 0, width: '167px', height: '60px' }}
              autoplay
              loop
            />
          )}
        </>
      )}
      {status === TransactionStatus.WAIT_APPROVAL_ACCEPT && (
        <>
          <S.BigText
            isLargeWidth
            dangerouslySetInnerHTML={{
              __html: purify.sanitize(deposit.steps.content.approve),
            }}
          />
          <DotLottiePlayer
            src={animations.supply.approve}
            style={{ maxWidth: '86px', maxHeight: '91px', marginRight: '16px' }}
            autoplay
            loop
          />
        </>
      )}
      {status === TransactionStatus.WAIT_APPROVAL && (
        <>
          <S.BigText
            isLargeWidth
            dangerouslySetInnerHTML={{
              __html: purify.sanitize(deposit.steps.content.waiting),
            }}
          />
          <DotLottiePlayer
            src={animations.supply.approving}
            style={{ maxWidth: '86px', maxHeight: '91px', marginRight: '16px' }}
            autoplay
            loop
          />
        </>
      )}
      {status === TransactionStatus.WAIT_ACCEPT && (
        <>
          <S.ConfirmDeposit>
            <h3
              dangerouslySetInnerHTML={{
                __html: purify.sanitize(deposit.steps.confirmTitle),
              }}
            />
            <p
              dangerouslySetInnerHTML={{
                __html: purify.sanitize(deposit.steps.confirmContent),
              }}
            />
          </S.ConfirmDeposit>
          <DotLottiePlayer
            src={animations.supply.sign}
            style={{ maxWidth: '86px', maxHeight: '91px', marginRight: '16px' }}
            autoplay
            loop
          />
        </>
      )}
      {status === TransactionStatus.WAIT_TRANSACTION && (
        <>
          <S.BigText
            isLargeWidth
            dangerouslySetInnerHTML={{
              __html: purify.sanitize(deposit.steps.depositTitle),
            }}
          />
          <DotLottiePlayer
            src={animations.supply.processing}
            style={{ maxWidth: '86px', maxHeight: '91px', marginRight: '16px' }}
            autoplay
            loop
          />
        </>
      )}
      {status === TransactionStatus.ERROR && (
        <>
          <S.BigText
            isLargeWidth
            isRed
            dangerouslySetInnerHTML={{
              __html: purify.sanitize(deposit.steps.content.deny),
            }}
          />
          <DotLottiePlayer
            src={animations.supply.fail}
            style={{ maxWidth: '86px', maxHeight: '91px', marginRight: '16px' }}
            autoplay
            loop
          />
        </>
      )}
      {status === TransactionStatus.REVIEW_TRANSACTION && (
        <S.ReviewDeposit>
          <p>
            <span>
              {deposit.steps.content.valueEntered}{' '}
              <strong>
                {symbol} {value.substring(0, 6)}
              </strong>
            </span>
          </p>
          <p>
            <span>
              {deposit.steps.content.costs} <Icon name="value" /> {deposit.steps.content.gas}: {gas}{' '}
              <strong>
                <Icon name="value" /> {deposit.steps.content.fee}: {symbol} {fee.substring(0, 6)}
              </strong>
            </span>
          </p>
          <p>
            {deposit.steps.content.enterInWallet}{' '}
            <strong>
              {symbol} {enterInWallet.substring(0, 6)}{' '}
            </strong>
            <span>
              <Icon name="value" /> {realValue}
            </span>
          </p>
        </S.ReviewDeposit>
      )}
    </S.Wrapper>
  );
};

export default DepositDisplay;
