import React, { useMemo } from 'react';

import { disconnect } from '@wagmi/core';
import { arbitrum } from 'wagmi/chains';
import { useSwitchNetwork } from 'wagmi';
import { useSelector } from 'react-redux';
import { ControllerField, Icon, SmallSections } from '@munchiesmoney/bubble-gum';

import { RootState } from 'store';

import { topVariants } from 'utils/variants';
import { handlePurify } from 'utils/deposit';
import { useWallet } from 'handlers/Wallet';

import * as S from './ChangeNetwork.styles';

import changeNetworkImg from 'assets/imgs/metamask_changenetwork.png';
import { WalletTypes } from 'components/connectors/connectors.types';

const ChangeNetwork = () => {
  const { switchNetwork } = useSwitchNetwork();

  const { type, setType, isChangeNetwork } = useWallet();

  const { wallet } = useSelector((state: RootState) => state.locale.content);

  const buttons = useMemo(
    () => [
      {
        label: wallet.metaMask.changeNetwork.changeButton,
        onClick: () => {
          switchNetwork?.(arbitrum?.id);
        },
      },
      {
        label: wallet.metaMask.changeNetwork.disconnectButton,
        onClick: async () => {
          await disconnect();

          setType(WalletTypes.INITIAL);
        },
      },
    ],
    [wallet, arbitrum, switchNetwork],
  );

  return (
    <S.Wrapper initial={{ height: 0 }} animate={isChangeNetwork ? 'open' : 'closed'} variants={topVariants}>
      <SmallSections
        right
        title={wallet.chainNotSupported}
        titleIcon={<Icon name="stop" height={16} width={16} />}
        walletIcon={null}
      >
        <S.Content type={type}>
          <div>
            <S.Icon type={type}>
              <Icon name="stop" />
            </S.Icon>
            <p dangerouslySetInnerHTML={{ __html: handlePurify(wallet.metaMask.changeNetwork.message) }} />
            <img src={changeNetworkImg} alt="" />
          </div>
          <ControllerField bgColor="rgba(0,0,0,0.07)" buttons={buttons} type="secondary" isChangeNetwork={true}>
            {wallet.metaMask.changeNetwork.footer}
          </ControllerField>
        </S.Content>
      </SmallSections>
    </S.Wrapper>
  );
};

export default ChangeNetwork;
