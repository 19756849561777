import React, { useState } from 'react';

import { motion } from 'framer-motion';
import { useAccount, useDisconnect, useNetwork } from 'wagmi';
import { useSelector } from 'react-redux';
import { Header, Icon, theme } from '@munchiesmoney/bubble-gum';

import { RootState } from 'store';

import * as S from './WalletHeader.styles';

import { truncateWallet } from 'utils/strings';
import ButtonCopy from 'components/ButtonCopy';

export const slideVariants = {
  open: {
    width: '211px',
    transition: { delay: 0.1 },
  },
  closed: {
    width: '311px',
    transition: { delay: 0.1 },
  },
};

const WalletHeader = () => {
  const { isConnected, address } = useAccount();

  const { chain } = useNetwork();
  const { disconnect } = useDisconnect();

  const { pulse } = useSelector((state: RootState) => state.navigation.wallet);
  const { wallet } = useSelector((state: RootState) => state.locale.content);

  const [hover, setHover] = useState(false);

  return (
    <Header
      title="Wallet"
      icon={<Icon name="wallet" />}
      focusAnimation={pulse}
      bgColor={['#56459A', theme.colors.purple[600]]}
    >
      {!isConnected ? (
        <S.NotConnectedLabel>{wallet.connectText}</S.NotConnectedLabel>
      ) : (
        <S.Content>
          <motion.div initial={{ width: '100%' }} animate={hover ? 'open' : 'closed'} variants={slideVariants}>
            {hover ? (
              <S.ConnectedTitle hover={hover}>{wallet.copyAddress}</S.ConnectedTitle>
            ) : (
              <S.ConnectedTitle hover={hover}>
                {wallet.connectedText} {chain?.name}
              </S.ConnectedTitle>
            )}
            <S.ConnectedLabel hover={hover}>{truncateWallet(address || '', hover)}</S.ConnectedLabel>
          </motion.div>
          <S.ButtonsWrapper>
            <div>
              <ButtonCopy value={address} hover={hover} setHover={setHover} />
            </div>
            <S.Custom>
              <S.CustomButton
                initial={{ backgroundColor: '#5a49a9' }}
                whileHover={{ backgroundColor: '#8a6dff' }}
                transition={{ duration: 0.5 }}
                onClick={() => {
                  disconnect();
                }}
                padding="7px 11px"
              >
                <Icon name="logout" color="white" />
              </S.CustomButton>
            </S.Custom>
          </S.ButtonsWrapper>
        </S.Content>
      )}
    </Header>
  );
};

export default WalletHeader;
