import styled, { css } from 'styled-components';
import { motion } from 'framer-motion';

import { WalletConnectorsProps } from 'components/WalletConnectors/WalletConnectors.types';
import { WalletTypes } from 'components/connectors/connectors.types';

export const Wrapper = styled(motion.div)`
  overflow: hidden;
  > section {
    overflow: hidden;
    width: 100%;
    > div:first-child {
      svg {
        width: 16px;
        height: 16px;
        path {
          fill: #e168ad;
        }
      }
    }
  }
`;

export const Content = styled.div<WalletConnectorsProps>`
  ${({ theme, type }) => css`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 220px;
    background: ${theme.colors.purple[200]}40;
    border-radius: 8px;
    position: absolute;
    top: 10px;
    left: 10px;
    width: calc(100% - 20px);
    > div:first-child {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: ${type !== WalletTypes.WALLET_CONNECT ? '0px 65px 0px 24px' : '0 75px 0 24px'};
      min-height: 173px;
      p {
        color: ${theme.colors.purple[100]};
        font-family: ${theme.fonts.nexa};
        font-weight: 400;
        font-size: 17px;
        line-height: 24px;
      }
    }
    > button {
      display: block;
      height: 40px;
    }
  `}
`;

export const Icon = styled.div<WalletConnectorsProps>`
  ${({ theme, type }) => css`
    position: absolute;
    top: 51px;
    right: 34px;
    svg {
      width: 36px;
      height: 36px;
    }
    color: ${type !== WalletTypes.WALLET_CONNECT ? theme.colors.metamask[700] : theme.colors.purpleMeal[500]}!important;
  `}
`;
