import React, { useEffect } from 'react';
import { NotFoundWrapper } from './NotFound.styles';
import { CustomButton } from '@munchiesmoney/bubble-gum';

const ServiceUnavailable: React.FC = () => {
  useEffect(() => {
    setTimeout(() => {
      window.location.href = '/';
    }, 5000);
  }, []);
  return (
    <NotFoundWrapper>
      <p>
        <b>503</b>
        <br />
        Servidor indisponível.
        <br />
        <br />
      </p>
      <CustomButton
        snakeSize="small"
        colorScheme={'lightPurple'}
        onClick={() => {
          window.location.href = '/';
        }}
      >
        Retentar
      </CustomButton>
    </NotFoundWrapper>
  );
};

export default ServiceUnavailable;
