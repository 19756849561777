import { useCallback, useEffect, useMemo } from 'react';
import { EventEmitter } from 'events';
import {
  erc20ABI,
  useAccount,
  useContractRead,
  useContractWrite,
  usePrepareContractWrite,
  useWaitForTransaction,
} from 'wagmi';
import { TransactionStatus, TransactionType } from 'modules/transaction/transaction.types';
import { trim_decimal_overflow } from 'utils/deposit';
import { utils } from 'ethers';
import { useVaultBlock } from 'components/Vault';
import { useSelector } from 'react-redux';
import vaultABI from 'abi/IVault.json';
import { RootState, useDispatch } from 'store';
import { setStatus } from 'modules/transaction/transaction.slice';

type UseApprove = {
  value: string;
};

export const useApprove = ({ value }: UseApprove) => {
  const dispatch = useDispatch();

  const { id, contractAddress, assetAddress, allowance } = useVaultBlock();

  const { vaultId, type } = useSelector((state: RootState) => state.transaction);

  const instance = useMemo(() => {
    return new EventEmitter();
  }, []);

  const { address, isConnected } = useAccount();

  const tokenDecimals = useContractRead({
    address: assetAddress,
    abi: erc20ABI,
    enabled: isConnected,
    functionName: 'decimals',
  });

  const parsedValue = useMemo(() => trim_decimal_overflow(value, tokenDecimals.data as number), [value, tokenDecimals]);

  const { config } = usePrepareContractWrite({
    address: assetAddress,
    abi: erc20ABI,
    enabled: isConnected && value !== '0.0000' && id === vaultId && type === TransactionType.DEPOSIT,
    functionName: 'approve',
    args: [contractAddress, utils.parseUnits(parsedValue, tokenDecimals.data)],
  });

  const { data: isApproved, refetch } = useContractRead({
    address: contractAddress,
    abi: vaultABI,
    functionName: 'checkApproval',
    args: [address, utils.parseUnits(parsedValue, tokenDecimals.data)],
    enabled: isConnected && value !== '0.0000' && id === vaultId && type === TransactionType.DEPOSIT,
  });

  const { data, isError, reset, ...transaction } = useContractWrite(config);

  const {
    isLoading,
    isSuccess,
    isError: isWaitError,
  } = useWaitForTransaction({
    hash: data?.hash,
    enabled: isConnected && id === vaultId && type === TransactionType.DEPOSIT,
  });

  useEffect(() => {
    if (isError) {
      dispatch(setStatus(TransactionStatus.ERROR));
      reset();
    }
  }, [isError]);

  useEffect(() => {
    dispatch(setStatus(TransactionStatus.WAIT_APPROVAL));
  }, [isLoading]);

  useEffect(() => {
    if (!isLoading && isWaitError) {
      dispatch(setStatus(TransactionStatus.ERROR));
      reset();
    }
  }, [isLoading, isWaitError]);

  useEffect(() => {
    if (!isLoading && isSuccess && !isApproved) {
      dispatch(setStatus(TransactionStatus.REVIEW_TRANSACTION));
      refetch();
      reset();

      instance.emit('user_approved', true);
    }
  }, [isLoading, isSuccess, isApproved]);

  const request = useCallback(() => {
    allowance.refetch();
    transaction.write?.();

    dispatch(setStatus(TransactionStatus.WAIT_APPROVAL_ACCEPT));
  }, [allowance, dispatch, transaction]);

  return {
    instance,

    request,

    isApproved,
  };
};

export const useSupply = () => {
  return {};
};

export const useWithdraw = () => {
  return {};
};
