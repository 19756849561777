import styled from 'styled-components';

export const Wrapper = styled.div`
  padding: 20px;
  width: 100%;
  height: 100svh;
  display: flex;
  flex-direction: column;
  align-items: center;
  header {
    /* background: ${(props) => props.theme.colors.purple[700]}!important; */
    > div:nth-child(1) {
      margin-right: 16px;
      > span > div > div {
        height: 33px;
      }
    }
  }
  /* > section {
    background: ${(props) => props.theme.colors.purple[700]}!important;
  } */
  > section {
    width: 100% !important;
    > div {
      position: relative;
      padding: 0 18px !important;
      width: 100%;
      > div {
        > div {
          width: 100%;
        }
        height: calc(100svh - 130px);
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
      }
    }
  }
`;

export const HeaderBody = styled.div`
  display: flex;
  justify-content: end;
  align-items: baseline;
  p {
    font-family: ${(props) => props.theme.fonts.nexaText};
    font-weight: 950;
    font-size: 18px;
    line-height: 24px;
    color: ${(props) => props.theme.colors.purple[200]};
    margin-right: 11px;
  }
  span {
    font-family: ${(props) => props.theme.fonts.nexaText};
    font-style: normal;
    font-weight: 800;
    font-size: 10px;
    line-height: 16px;
    color: ${(props) => props.theme.colors.purple[200]};
    margin-right: 5px;
  }
`;

export const Image = styled.div`
  position: absolute;
  bottom: 15%;
  left: 0;
  img {
    max-width: 90%;
    margin: 0 auto;
    display: block;
  }
`;

export const SectionBody = styled.div`
  > div > h1 {
    font-style: normal;
    font-weight: 400;
    font-size: 58px;
    line-height: 41px;
    margin-bottom: 30px;
    font-family: ${(props) => props.theme.fonts.mindset};
  }
  > div > p {
    font-family: ${(props) => props.theme.fonts.mindset};
    text-transform: uppercase;
    margin-bottom: 20px;
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
  }
  > div > span {
    font-family: ${(props) => props.theme.fonts.nexaText};
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
  }
`;

export const Buttons = styled.div`
  display: flex;
  margin-top: 28px;
  > button {
    span {
      font-weight: 800;
    }
    width: 100%;
    &:first-child {
      margin-right: 16px;
    }
  }
`;
