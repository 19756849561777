export const slideVariants = {
  open: {
    width: 92,
    transition: { duration: 0.1, type: 'tween', stiffness: 50 },
  },
  closed: {
    width: 34,
    transition: { duration: 0.1, type: 'tween', stiffness: 50 },
  },
};
export const opacityVariants = {
  open: {
    width: 'auto',
    opacity: 100,
    transition: { duration: 0.8 },
  },
  closed: {
    width: 0,
    opacity: 0,
    transition: { duration: 0.8 },
  },
};
