import { BigNumber, ethers, utils } from 'ethers';
import bigDecimal from 'js-big-decimal';
import purify from 'dompurify';

export function sumAmount(list: any) {
  let sum = 0;
  for (const item of list) {
    sum = sum + Number(item);
  }
  return sum;
}

export function convertFromOracle(amount: BigNumber) {
  amount = amount || BigNumber.from('0');
  return utils.formatUnits(amount, 8);
}

export function toFixed(num: number, fixed: number) {
  const parts = num.toString().split('.');
  if (parts[1]) {
    return [parts[0], parts[1].substr(0, fixed)].join('.');
  }
  return parts[0];
}

export function toFixedTitle(num: number, fixed: number) {
  const calc = Math.floor(num * Math.pow(1000, fixed)) / Math.pow(1000, fixed);
  return String(calc).substring(0, 7);
}

export function toFixedApy(num: number, fixed: number) {
  const calc = Math.floor(num * Math.pow(1000, fixed)) / Math.pow(1000, fixed);
  return String(calc).substring(0, fixed);
}

export function toFixedMax(num: string, fixed: number) {
  const parts = num.split('.');
  if (parts[1]) {
    return [parts[0], parts[1].substr(0, fixed)].join('.');
  }
  return parts[0];
}

export function trim_decimal_overflow(n: string, decimals: number) {
  n += '';

  if (n.indexOf('.') === -1) return n;

  const arr = n.split('.');
  const fraction = arr[1].substr(0, decimals);
  return arr[0] + '.' + fraction;
}

export function inputTokenFormat(number: number) {
  let num = number.toString();
  if (num.length > 4) {
    num = num.slice(0, num.length - 4) + '.' + num.slice(num.length - 4);
  } else {
    num = '0.' + '0'.repeat(4 - num.length) + num;
  }
  return num;
}

export function convertToBrl(amount: string) {
  amount = amount || '0';

  const brl = Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL',
  });
  return brl.format(parseFloat(amount));
}

export function convertToPercentage(amount: any) {
  amount = amount ? BigNumber.from(`${amount}`) : BigNumber.from('0');

  const result = amount.div(100);
  return result.toString();
}

export function formatAsPercentage(num: string, res: string | undefined) {
  const parsedNum = parseFloat(num);
  const parsedRes = parseFloat(res || '0.0');

  if (Number.isNaN(parsedNum)) return 1000;
  if (parsedNum === 0) return 0.1;
  if (parsedRes === 0) return 1000;

  const big = parseFloat(bigDecimal.divide(parsedNum, parsedRes, 100));

  return new Intl.NumberFormat('default', {
    style: 'percent',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  }).format(big);
}

export function formatBigNumber(amount: BigNumber) {
  amount = amount || BigNumber.from('0');
  return amount.toString();
}

export function sumBigNumbers(list: Array<BigNumber>) {
  let sum = BigNumber.from('0');
  for (const item of list) {
    sum = sum.add(item || BigNumber.from('0'));
  }
  return sum;
}

export const inputWeb3 = (input: any) => {
  const inputAsWei = ethers.utils.parseUnits(input.toString(), 18);
  const inputAsBigInt = BigInt(inputAsWei.toString());
  const inputAsString = ethers.utils.formatUnits(inputAsWei, 18);
  const inputAsNumber = Number(parseFloat(input).toFixed(6));
  return {
    dsp: inputAsNumber,
    hStr: inputAsString,
    big: inputAsBigInt,
  };
};

export const handlePurify = (text: string) => {
  return purify.sanitize(text);
};
