import React, { useCallback, useEffect, useMemo } from 'react';

import { useAnimationControls } from 'framer-motion';
import { Checkbox, CustomButton, Icon, OutlineButton, theme } from '@munchiesmoney/bubble-gum';
import { useSelector } from 'react-redux';
import purify from 'dompurify';

import { RootState, useDispatch } from 'store';

import type { InitialTermsStepProps } from 'components/InitialTerms/InitialTerms.types';

import { setSectionState, toggleAcceptTerms } from 'modules/navigation/navigation.slice';

import * as S from './Agreements.styles';

import contractImage from 'assets/imgs/vectors/contract.png';
import { useAccount } from 'wagmi';

const AgreementsStep = ({ step, setStep, overflowControls, terms, setTerms }: InitialTermsStepProps) => {
  const dispatch = useDispatch();

  const { isConnected } = useAccount();

  const {
    terms: { accepts },
  } = useSelector((state: RootState) => state.navigation);

  const {
    content: { navbar },
  } = useSelector((state: RootState) => state.locale);

  const controls = useAnimationControls();

  const messages = useMemo(() => navbar.terms.checkLabels, [navbar]);
  const isAllCheckAccepted = useMemo(() => accepts.every((check) => !!check), [accepts]);

  const onAccept = useCallback(
    (idx: number) => () => {
      dispatch(toggleAcceptTerms(idx));
    },
    [dispatch],
  );

  const onSubmit = useCallback(() => {
    dispatch(
      setSectionState({
        section: 'terms',
        config: {
          closed: true,
          pulse: false,
        },
      }),
    );

    dispatch(
      setSectionState({
        section: 'wallet',
        config: {
          closed: false,
          pulse: true,
          disabled: false,
        },
      }),
    );

    if (!isConnected) {
      dispatch(
        setSectionState({
          section: 'meals',
          config: {
            closed: false,
            collapsed: false,
            disabled: true,
          },
        }),
      );
    }
  }, [dispatch, isConnected]);

  const onReadClick = (props: number) => {
    setTerms(undefined);
    setStep(2);
    setTerms(props);
    overflowControls.start({
      background: `${theme.colors.purple[800]}DB`,
      transition: {
        duration: 0.1,
      },
    });
  };

  useEffect(() => {
    switch (step) {
      case 1:
        controls.start({
          opacity: 1,
          x: 0,
          transition: {
            type: 'spring',
            stiffness: 100,
            mass: 0.2,
            duration: 0.15,
          },
        });

        break;
      case 2:
        controls.start({
          opacity: 0,
          x: -521,
          transition: {
            type: 'spring',
            stiffness: 100,
            mass: 0.2,
            duration: 0.15,
          },
        });

        break;
    }
  }, [step, controls]);

  return (
    <S.Wrapper layout animate={controls}>
      <S.Intro>
        <img src={contractImage} alt="Munchies Money - Contract" />
        <ul>
          {navbar.terms.options.map((option, idx: number) => (
            <li key={`option-${idx}`}>
              <h5>{option.title}</h5>{' '}
              <OutlineButton onClick={() => onReadClick(idx)}>{option.buttonText}</OutlineButton>
            </li>
          ))}
        </ul>
        <p dangerouslySetInnerHTML={{ __html: purify.sanitize(navbar.terms.notice) }} />
        <h6>
          <Icon name="arrow-down" width={14} height={18} />
          {navbar.terms.declareText}
        </h6>
      </S.Intro>
      <S.Agreements>
        <ul>
          {messages.map((message, idx) => (
            <li key={`message-${idx}`} role="checkbox" aria-checked={accepts[idx]} onClick={onAccept(idx)}>
              <Checkbox checked={accepts[idx]} handleChange={onAccept(idx)} />
              <p dangerouslySetInnerHTML={{ __html: purify.sanitize(message) }} />
            </li>
          ))}
        </ul>
        <footer aria-disabled={!isAllCheckAccepted}>
          <CustomButton colorScheme="lightPurple" snakeSize="small" onClick={onSubmit} animateOnlyHover>
            {isAllCheckAccepted ? navbar.terms.confirmText : navbar.terms.confirmAllText}
          </CustomButton>
        </footer>
      </S.Agreements>
    </S.Wrapper>
  );
};

export default AgreementsStep;
