import React, { createContext, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';

import { MealCard } from '@munchiesmoney/bubble-gum';
import { erc20ABI, erc4626ABI, useAccount, useBalance, useContractRead, useNetwork } from 'wagmi';
import { useSelector } from 'react-redux';

import { convertFromOracle, convertToBrl, toFixed, toFixedApy } from 'utils/deposit';

import { RootState, useDispatch } from 'store';

import { setType, setVaultId } from 'modules/transaction/transaction.slice';

import Deposit from './Flows/Deposit';
import Withdraw from './Flows/Withdraw';

import * as S from './Vault.styles';
import { IVaultContext } from './Vault.types';
import { constants, utils } from 'ethers';
import BigNumber from 'bignumber.js';
import { TransactionStatus, TransactionType } from 'modules/transaction/transaction.types';
import { updateApy, updateVaults } from 'modules/vaults/vaults.slice';
import { getGmxAPY, getLidoAPY, getOvernightAPY } from 'services/apr';

type InputHandle = {
  focus(): void;
  blur(): void;
};

const VaultContext = createContext({} as IVaultContext);

const Vault = ({ vault }: any) => {
  const dispatch = useDispatch();

  const { address, isConnected } = useAccount();
  const { chain } = useNetwork();

  const { vaultId, isTransacting, status, type } = useSelector((state: RootState) => state.transaction);
  const { meals, terms } = useSelector((state: RootState) => state.navigation);
  const { safes } = useSelector((state: RootState) => state.vaults);
  const { deposit: content } = useSelector((state: RootState) => state.locale.content);

  const { isConfirm: sendOk } = useSelector((state: RootState) => state.wallet);

  const supplyInputRef = useRef<InputHandle>(null);

  const safe = useMemo(() => safes.find((safe) => safe.id === vault?.id), [vault, safes]);

  const [apr, setApr] = useState<number>();

  const [supplySelected, setSupplySelected] = useState(false);

  const state = useMemo(() => {
    if (!terms.closed) {
      return 'collapsed';
    }

    if (!!vaultId && vaultId !== vault?.id) {
      return 'contracted';
    }

    if (vaultId === vault?.id) {
      return 'expanded';
    }

    return 'default';
  }, [meals, vault, vaultId, terms]);

  const allowanceConfig = useMemo(() => {
    return {
      address: vault?.underlyingAssetAddress,
      abi: erc20ABI,
      enabled: isConnected && address,
      functionName: 'allowance',
      args: [address as never, vault?.contractAddress],
    };
  }, [vault, isConnected, address]);

  const tokenDecimals = useContractRead({
    address: vault?.underlyingAssetAddress,
    abi: erc20ABI,
    functionName: 'decimals',
  });

  const vaultBalance = useContractRead({
    address: vault.contractAddress,
    abi: erc4626ABI,
    functionName: 'balanceOf',
    args: [address as never],
    watch: false,
    enabled: isConnected,
  });

  const balanceMax = useContractRead({
    address: vault.contractAddress,
    abi: erc4626ABI,
    functionName: 'convertToAssets',
    args: [vaultBalance?.data as never],
    watch: false,
    enabled: isConnected,
  });

  const tokenConfig = { address, token: vault?.underlyingAssetAddress, watch: false, enabled: isConnected };

  const token = useBalance({
    ...tokenConfig,
    onSuccess(data) {
      dispatch(
        updateVaults({
          id: vault.id,
          symbol: vault.underlyingAssetSymbol,
          value: data.formatted,
        }),
      );
    },
  });

  const maxDeposit = useContractRead({
    address: vault.contractAddress,
    abi: erc4626ABI,
    functionName: 'maxDeposit',
    args: [address as never],
    enabled: isConnected && !!token?.data,
  });

  const maxWithdraw = useContractRead({
    address: vault.contractAddress,
    abi: erc4626ABI,
    functionName: 'maxWithdraw',
    args: [address as never],
    enabled: isConnected && !!token?.data,
  });

  const hasNoWithdrawBalance = useMemo(() => {
    return safes.every((safe) => BigNumber(utils.formatUnits(safe.value, tokenDecimals.data)).isEqualTo(BigNumber(0)));
  }, [safes]);

  //ALLOWANCE
  const allowance = useContractRead(allowanceConfig as never);
  const allowanceData = allowance.data ? utils.formatUnits(allowance?.data as never, tokenDecimals.data) : '0.0';

  const disabledVault = useMemo(() => {
    if (!sendOk) {
      return true;
    } else if (isConnected && vault?.disabled) {
      return true;
    } else if (chain?.unsupported) {
      return true;
    }
    return false;
  }, [sendOk, isConnected, vault, chain?.unsupported]);

  const handleSelectVault = useCallback(() => {
    if (!isConnected || vault?.disabled) return;
    if (chain?.unsupported) return;
    if (!sendOk) return;
    if (vaultId || isTransacting || !!type) return;

    dispatch(setVaultId(vault?.id));

    if (hasNoWithdrawBalance && token.data?.formatted !== '0.0') {
      setTimeout(() => {
        setSupplySelected(true);

        dispatch(setType(TransactionType.DEPOSIT));
        supplyInputRef.current?.focus();
      }, 400);
    }
  }, [
    dispatch,
    token,
    disabledVault,
    supplyInputRef,
    vaultId,
    isConnected,
    sendOk,
    hasNoWithdrawBalance,
    isTransacting,
  ]);

  const handleSelectVaultByHover = useCallback(() => {
    if (!isConnected || vault?.disabled || chain?.unsupported) return;
    if (!vaultId || isTransacting || (!!type && isTransacting)) return;
    if (disabledVault) return;

    if (type && vault?.id !== vaultId) {
      dispatch(setType(null));

      setTimeout(() => {
        dispatch(setVaultId(vault?.id));
      }, 300);

      if (hasNoWithdrawBalance) {
        setTimeout(() => {
          dispatch(setType(TransactionType.DEPOSIT));
          setSupplySelected(true);
          supplyInputRef.current?.focus();
        }, 600);
      }

      return;
    }

    dispatch(setVaultId(vault?.id));
  }, [dispatch, vaultId, disabledVault, hasNoWithdrawBalance, supplyInputRef, type, isTransacting, isConnected]);

  const headerValueDisabled = useMemo(() => {
    if (safe && convertToBrl(convertFromOracle(safe?.value)) === 'R$ 0,00') {
      return true;
    }
    return false;
  }, [safe]);

  useEffect(() => {
    switch (vault.name) {
      case 'Pizza':
        getLidoAPY().then(setApr);
        break;
      case 'Burger':
        getOvernightAPY().then(setApr);
        break;
      case 'Truffle':
        getGmxAPY().then(setApr);
        break;
    }
  }, [vault]);

  useEffect(() => {
    if (apr) {
      dispatch(updateApy({ index: vault.id - 1, apr }));
    }
  }, [vault, apr]);

  return (
    <VaultContext.Provider
      value={{
        id: vault?.id,
        state,
        vaultBalance,
        balanceMax,
        supplyInputRef,
        token,
        allowance,
        maxWithdraw,
        maxDeposit,
        disabledVault,
        supplySelected,
        setSupplySelected,
        minimumDeposit: vault?.minimumDeposit,
        maximumDeposit: vault?.maximumDeposit,
        additionalColors: vault?.additionalColors,
        hasNoWithdrawBalance: isConnected ? hasNoWithdrawBalance : true,
        symbol: vault?.underlyingAssetSymbol,
        contractAddress: vault?.contractAddress,
        assetAddress: vault?.underlyingAssetAddress,
        additionalContractAddresses: vault?.additionalContractAddresses,
        underlyingAssetSymbol: vault?.underlyingAssetSymbol,
        inputIcon: vault?.tokenLogos.inputField,
        colors: {
          primary: vault?.primaryColor,
        },
        animations: {
          supply: {
            initial: vault?.animations.supplyInitial,
            approve: vault?.animations.supplyApprove,
            approving: vault?.animations.supplyApproving,
            sign: vault?.animations.supplySign,
            processing: vault?.animations.supplyProcessing,
            done: vault?.animations.supplyDone,
            fail: vault?.animations.supplyFail,
            error: vault?.animations.supplyError,
            cancel: vault?.animations.supplyCancel,
          },
          withdraw: {
            initial: vault?.animations.withdrawInitial,
            sign: vault?.animations.withdrawSign,
            processing: vault?.animations.withdrawProcessing,
            done: vault?.animations.withdrawDone,
            fail: vault?.animations.withdrawFail,
            cancel: vault?.animations.supplyCancel,
            error: vault?.animations.withdrawError,
          },
        },
      }}
    >
      <S.Wrapper>
        <MealCard.Root
          assets={
            {
              token: {
                header: vault?.tokenLogos.header,
                input: vault?.tokenLogos.inputField,
              },
              animations: {
                aside: vault?.animations.aside,
              },
              provider: vault?.providerLogo,
            } as never
          }
          colors={{
            additionals: {
              100: vault?.additionalColors.color100,
              200: vault?.additionalColors.color200,
            },
            primary: vault?.primaryColor,
            secondary: vault?.secondaryColor,
          }}
          headerSymbol={vault?.holdingAssetSymbol}
          symbol={vault?.underlyingAssetSymbol}
          providerPosition={vault?.delegatorType.toUpperCase() || ''}
          translations={{
            availableSupply: content.depositAvailable,
            availableWithdraw: content.withdrawAvailable,
            inVault: '',
            munchiesFee: content.munchiesFee,
            year: content.percentYear,
          }}
          state={state}
          isDisabled={!!vaultId && vault.id !== vaultId}
          disabled={!isConnected || (isConnected && vault?.disabled)}
          isDisabledAround={!!type && status !== TransactionStatus.IDLE && vaultId === vault?.id}
          isTransacting={!!type && status !== TransactionStatus.IDLE && vaultId === vault?.id}
        >
          <MealCard.Aside
            apr={vault?.apr || 0}
            apy={vault?.apy > 0 ? Number(toFixedApy(vault?.apy || 0, 4)) : Number(toFixedApy(vault?.apr || 0, 4))}
            munchiesFee={vault?.munchiesFee || 0}
            protocolFee={vault?.protocolFee || 0}
            protocolTvl={vault?.protocolTvl || 0}
            // @ts-ignore
            allowance={
              // @ts-ignore
              allowance?.data && allowance?.data?.eq(constants.MaxUint256)
                ? ('∞' as never)
                : allowanceData !== '0.0'
                ? toFixed(Number(allowanceData), 4)
                : '-'
            }
            allowanceSymbol={''}
            disabled={isConnected && vault?.disabled}
          />
          <MealCard.Content>
            <MealCard.Header
              headerValueDisabled={headerValueDisabled || !isConnected}
              disabled={isConnected && vault?.disabled}
              disabledText={content.disabled}
            >
              {safe && isConnected ? convertToBrl(convertFromOracle(safe.value)) : 'R$ 0,00'}
            </MealCard.Header>
            <MealCard.Grid disabled={isConnected && vault?.disabled}>
              <Withdraw disabled={vault?.disabled} hasNoWithdrawBalance={isConnected ? hasNoWithdrawBalance : false} />
              <Deposit />
            </MealCard.Grid>
          </MealCard.Content>
          <div onMouseEnter={handleSelectVaultByHover}>
            <MealCard.Overlay
              index={!vaultId || vaultId !== vault?.id ? 3 : !!vaultId && vaultId === vault?.id && !!type ? 2 : -1}
              onClick={handleSelectVault}
            />
          </div>
        </MealCard.Root>
      </S.Wrapper>
    </VaultContext.Provider>
  );
};

export const useVaultBlock = () => useContext(VaultContext);

export default Vault;
