import React from 'react';

import { DotLottiePlayer } from '@dotlottie/react-player';
import { useSelector } from 'react-redux';
import purify from 'dompurify';

import { RootState } from 'store';

import { TransactionStatus, TransactionType } from 'modules/transaction/transaction.types';

import { useVaultBlock } from 'components/Vault';

import * as S from './WithdrawDisplay.styles';
import { Icon } from '@munchiesmoney/bubble-gum';

const WithdrawDisplay = ({
  noBalance = false,
  noVaultBalance,
  symbol,
  value,
  gas,
  fee,
  realValue,
  enterInWallet,
}: any) => {
  const {
    animations,
    colors: { primary },
  } = useVaultBlock();

  const { type, status } = useSelector((state: RootState) => state.transaction);
  const { withdraw } = useSelector((state: RootState) => state.locale.content);

  if (noVaultBalance && status !== TransactionStatus.COMPLETE) {
    return (
      <S.Wrapper>
        <S.NoAmountDescription
          primary={primary}
          aria-disabled={noVaultBalance}
          dangerouslySetInnerHTML={{
            __html: purify.sanitize(withdraw.steps.header.noFeeDescription),
          }}
        />
      </S.Wrapper>
    );
  }

  if (type === TransactionType.DEPOSIT) {
    return (
      <S.Wrapper>
        <S.IdleDescription
          primary={primary}
          dangerouslySetInnerHTML={{
            __html: purify.sanitize(withdraw.steps.content.idleResume),
          }}
        />
      </S.Wrapper>
    );
  }

  if (noBalance && status !== TransactionStatus.COMPLETE) {
    return (
      <S.Wrapper>
        <S.BigText
          isRed
          primary={primary}
          style={{ width: 140 }}
          dangerouslySetInnerHTML={{
            __html: purify.sanitize(withdraw.steps.content.maxBalance),
          }}
        />
        <DotLottiePlayer
          src={animations.withdraw.error}
          style={{ flexShrink: 0, width: '164px', height: '60px', marginRight: '16px' }}
          autoplay
          loop
        />
      </S.Wrapper>
    );
  }

  return (
    <S.Wrapper>
      {status === TransactionStatus.IDLE && (
        <>
          <S.IdleDescription
            primary={primary}
            dangerouslySetInnerHTML={{
              __html: purify.sanitize(withdraw.steps.content[type === null ? 'idleResume' : 'idle']),
            }}
          />
          {type === TransactionType.WITHDRAW && (
            <DotLottiePlayer
              src={animations.withdraw.initial}
              style={{ flexShrink: 0, width: '167px', height: '60px' }}
              autoplay
              loop
            />
          )}
        </>
      )}
      {status === TransactionStatus.ERROR && (
        <>
          <S.BigText
            isRed
            primary={primary}
            dangerouslySetInnerHTML={{
              __html: purify.sanitize(withdraw.steps.content.deny),
            }}
          />
          <DotLottiePlayer
            src={animations.withdraw.fail}
            style={{ maxWidth: '86px', maxHeight: '91px', marginRight: '16px' }}
            autoplay
            loop
          />
        </>
      )}
      {status === TransactionStatus.WAIT_ACCEPT && (
        <>
          <S.ConfirmWithdraw primary={primary}>
            <h3
              dangerouslySetInnerHTML={{
                __html: purify.sanitize(withdraw.steps.content.interaction),
              }}
            />
            <p
              dangerouslySetInnerHTML={{
                __html: purify.sanitize(withdraw.steps.content.interactionDescription),
              }}
            />
          </S.ConfirmWithdraw>
          <DotLottiePlayer
            src={animations.withdraw.sign}
            style={{ maxWidth: '86px', maxHeight: '91px', marginRight: '16px' }}
            autoplay
            loop
          />
        </>
      )}
      {status === TransactionStatus.WAIT_TRANSACTION && (
        <>
          <S.BigText
            primary={primary}
            dangerouslySetInnerHTML={{
              __html: purify.sanitize(withdraw.steps.content.waiting),
            }}
          />
          <DotLottiePlayer
            src={animations.withdraw.processing}
            style={{ maxWidth: '86px', maxHeight: '91px', marginRight: '16px' }}
            autoplay
            loop
          />
        </>
      )}
      {status === TransactionStatus.COMPLETE && (
        <>
          <S.BigText
            isGreen
            primary={primary}
            dangerouslySetInnerHTML={{
              __html: purify.sanitize(withdraw.steps.content.done),
            }}
          />
          <DotLottiePlayer
            src={animations.withdraw.done}
            style={{ maxWidth: '86px', maxHeight: '91px', marginRight: '16px' }}
            autoplay
            loop
          />
        </>
      )}
      {status === TransactionStatus.REVIEW_TRANSACTION && (
        <S.ReviewDeposit primary={primary}>
          <p>
            <span>
              {withdraw.steps.content.valueEntered}{' '}
              <strong>
                {symbol} {value.substring(0, 6)}
              </strong>
            </span>
          </p>
          <p>
            <span>
              {withdraw.steps.content.costs} <Icon name="value" /> {withdraw.steps.content.gas}: {gas}{' '}
              <strong>
                {withdraw.steps.content.fee}: {symbol} {fee.substring(0, 6)}
              </strong>
            </span>
          </p>
          <p>
            {withdraw.steps.content.enterInWallet}{' '}
            <strong>
              <Icon name="value" /> {symbol} {enterInWallet.substring(0, 6)}{' '}
            </strong>
            <span>
              <Icon name="value" /> {realValue}
            </span>
          </p>
        </S.ReviewDeposit>
      )}
    </S.Wrapper>
  );
};

export default WithdrawDisplay;
