import React, { useEffect, useState } from 'react';

import { useSelector } from 'react-redux';
import { Header, Icon, theme } from '@munchiesmoney/bubble-gum';

import { RootState } from 'store';

import * as S from './VaultHeader.styles';

import LimitValues from 'components/LimitValues';
import { fetchMealsList_getMeals } from 'services/mealsService/__generated__/fetchMealsList';
import { convertFromOracle, sumBigNumbers } from 'utils/deposit';
import { useAccount } from 'wagmi';

const VaultHeader = ({ totalVaultsValue }: any) => {
  const { deposit: content } = useSelector((state: RootState) => state.locale.content);
  const [totalApy, setTotalApy] = useState(0);

  const { isConnected } = useAccount();

  const { vaultId, type } = useSelector((state: RootState) => state.transaction);

  const { pulse } = useSelector((state: RootState) => state.navigation.meals);

  const { items, safes } = useSelector((state: RootState) => state.vaults);

  useEffect(() => {
    if (isConnected && items && items.length === 3 && safes.length === 3) {
      const total = sumBigNumbers(safes.map((safe) => safe.value));
      const totalParsed = parseFloat(convertFromOracle(total));

      let sum = 0;

      for (const item of items as fetchMealsList_getMeals[]) {
        const safe = safes.find((safe) => safe.id === item.id) as any;

        if (!safe) continue;

        const amount = parseFloat(convertFromOracle(safe.value));
        const apy = item.apy || item.apr;

        console.log('SAFE', amount);

        sum += amount * apy;
      }

      setTotalApy(Number((sum / totalParsed || 0.0).toFixed(2)));
    }
  }, [items, safes, isConnected]);

  return (
    <Header
      title={content.title}
      icon={<Icon name="forks" />}
      bgColor={['#56459A', theme.colors.purple[600]]}
      focusAnimation={pulse}
      disabled={!!type && !!vaultId}
      rightContent={
        <S.Wrapper>
          <S.CustomButton
            initial={{ backgroundColor: '#5a49a9' }}
            whileHover={{ backgroundColor: '#8a6dff' }}
            transition={{ duration: 0.5 }}
            onClick={() => {
              window.open('https://docs.munchies.money', '_blank');
            }}
            padding="7px 12px"
          >
            <Icon name="interrogation" />
          </S.CustomButton>
        </S.Wrapper>
      }
    >
      <LimitValues
        labels={[content.yourMoney, content.yourGain]}
        values={isConnected ? [totalVaultsValue && totalVaultsValue, totalApy] : ['R$ 0,00', 0]}
      />
    </Header>
  );
};

export default VaultHeader;
